import React, { useEffect, useState } from "react";
import { _l } from "../../hooks/utilities";
import ChecklistComponent from "./ChecklistComponent";
import Lightbox from "yet-another-react-lightbox";
import FileUploadComponent from "./FileUploadComponent";
import { useSelector } from "react-redux";
function RequestTaskBodyCommonModal({ data, isExternal,showFullscreen=false, iscallModal}) {
    const [selectedTab, setSelectedTab] = useState("checklists");
    const [ShowCommentModal, setShowCommentModal] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [attachmentList, setAttachmentList] = useState([]);
    const [selectedTask, setSelectedTask] = useState("checklist");
    const [openGallery, setOpenGallery] = useState(false);
    const [taskId, setTaskId] = useState(0);
    const projectId = localStorage.getItem('selectedOffice')
    const [convertedTaskId, setConvertedTaskId] = useState(0);
    const [checklistFilter, setChecklistFilter] = useState(["all"]);
    const [actionMenuItems, setActionMenuItems] = useState([]);
    const {floorDetails} = useSelector((state) => state.benchmarkReducer);
    const userImage = localStorage.getItem("contact_image");

    const checkListFilter = (value) => {
        let filterArr = checklistFilter;
        let index = filterArr.findIndex((f) => f == value);
        if (index > -1) {
          filterArr = filterArr.filter((c) => c != value);
        } else {
          filterArr = [value];
        }
        if(!filterArr.length)
        {
          filterArr.push("all");
        }
        setChecklistFilter(filterArr.slice());
      }
    useEffect(() => {
        if (Object.keys(data).length) {
            setTaskId(data.id ? data.id : data.task_id)
            setSelectedTask(data)
            setConvertedTaskId(data.request_task_id ? data.request_task_id : 0)
            setAttachmentList(data.atttachments)
        }
    }, [JSON.stringify(data)])
    useEffect(() => {
        let actionMenu = [
         {
           actionName: _l("l_for_me"),
           actionIcon: "SelectionAll",
           actionHandler: () => {checkListFilter("forme")},
           active : checklistFilter.includes("forme"),
           profileImage: userImage
         }, 
         {
           actionName: _l("l_todo"),
           actionIcon: "CircleFillTodoIcons",
           actionHandler: () => {checkListFilter("todo")},
           active : checklistFilter.includes("todo")
         },
         {
           actionName: _l("l_inprogress"),
           actionIcon: "CircleFillInprogressIcons",
           actionHandler: () => {checkListFilter("inprogress")},
           active : checklistFilter.includes("inprogress")
         },
         {
           actionName: _l("l_done"),
           actionIcon: "CircleFillDoneIcons",
           actionHandler: () => {checkListFilter("done")},
           active : checklistFilter.includes("done")
         },
         {
           actionName: _l("l_priority"),
           actionIcon: "HighPriorityActiveIcon",
           actionHandler: () => {checkListFilter("priority")},
           active : checklistFilter.includes("priority")
         }
       ];
       if(floorDetails.floor.length > 1)
       {
         actionMenu = [{
           actionName: _l("l_floor_filter"),
           actionIcon: "StepIcon",
           actionHandler: () => {checkListFilter("floor")},
           active : checklistFilter.includes("floor")
         }].concat(actionMenu);
       }
       actionMenu = [{
         actionName: _l("l_all"),
         actionIcon: "SelectionAll",
         actionHandler: () => {checkListFilter("all")},
         active : checklistFilter.includes("all")
       },].concat(actionMenu);
       setActionMenuItems(actionMenu);
     }, [checklistFilter]);
    return (
        <>
            {
                attachmentList && attachmentList.length ? 
                (<Lightbox
                    open={openGallery}
                    close={() => setOpenGallery(false)}
                    slides={attachmentList && attachmentList.map((img) => {
                        return { src: img.image_url };
                    })}
                />) : <></>
            }
            <div className={`tast-detail-tab-wrapper res-height-auto d-flex flex-column ${ShowCommentModal ? "" : "h-125"}`}>
                <div className=" h-100 overflow-hiiden-auto d-flex flex-column">
                {selectedTab == "files" ? (
                    <div className="h-100  overflow-hiiden-auto">
                        <div class="color-white custom-accordian-Header-sticky with-top-46px p-2   my-2 radius_3 d-none">{_l('l_attachment_list')}</div>
                        <div
                            className={` d-flex flex-wrap  `}
                        >
                        </div>
                        <FileUploadComponent
                            projectId={projectId}
                            task_id={taskId}
                            attachmentCountUpdate={() => { }}
                            toastClose={() => { }}
                            closeAllTost={() => { }}
                            searchAttchment={''}
                            isExternal={isExternal}
                            readOnly={true}
                            isConvertedTask={selectedTask.request_task_id && parseInt(selectedTask.request_task_id) > 0 ? true : false}
                            isRequestTask={true}
                            taskDetails={selectedTask}
                        />
                    </div>
                ) : ( <>
                    <ChecklistComponent
                        projectId={projectId}
                        task_id={taskId}
                        isChecked={isChecked}
                        setIsChecked={setIsChecked}
                        setRequestChecklist={() => { }}
                        isSystemTask={
                            (selectedTask &&
                                selectedTask.addedfrom == 0 &&
                                selectedTask.schedule_id &&
                                parseInt(selectedTask.schedule_id) > 0) ||
                                convertedTaskId > 0
                                ? true
                                : null
                        }
                        isRequestTask="1"
                        toastClose={() => { }}
                        setCheckListCount={() => { }}
                        updateRequestTitle={() => { }}
                        checklistFilter={checklistFilter}
                        showFullscreen={showFullscreen}
                        setChecklistFilter={setChecklistFilter}
                        actionMenuItems={actionMenuItems}
                        data={data}
                        iscallModal={iscallModal}
                        isExternal = {isExternal}
                    />
                    <FileUploadComponent
                            projectId={projectId}
                            task_id={taskId}
                            attachmentCountUpdate={() => { }}
                            toastClose={() => { }}
                            closeAllTost={() => { }}
                            searchAttchment={''}
                            isExternal={isExternal}
                            readOnly={true}
                            isConvertedTask={selectedTask.request_task_id && parseInt(selectedTask.request_task_id) > 0 ? true : false}
                            isRequestTask={true}
                            taskDetails={selectedTask}
                        />
                    </>
                )}
                </div>
            </div>
        </>
    )
}
export default RequestTaskBodyCommonModal