import React, { useEffect, useState } from "react";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";

import "./assets/js/app";
import Login from "./views/Login";
import EmpolyeeTimeSheets from "./views/EmployeeTimeSheet";
import MyLeads from "./views/MyLeads";
import LiteVersion from "./views/LiteVersion";
import MapPage from "./views/MapPage";
import { fetchAuthToken } from "./actions/chat-auth";
import { connectToSocket, fetchUnreadMsgCount, setCallRecordingRunnigTimer, setCallRunnigTimer } from "./actions/chat";
import { useDispatch, useSelector } from "react-redux";
import jquery from "jquery";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./assets/css/customer_main.css"
// import Needle from "./views/Needle";
import {
  getDefaultTopicList,
  getTeams,
  setLeftPanelTab,
  setListOfIBANAccounts,
  setSelectedLanguage,
} from "./actions/customer";
import Redirect from "./components/Redirect/Redirect";
import { autoLogin } from "./actions/auth";
import Main from "./components/StandAlonePages/Main";
// import LogRocket from "logrocket";
import {
  DefaultListView,
  _l,
  handleIDBUpdateAction,
  isMinimumUpdateVersion,
  pad,
  refreshCacheAndReload,
} from "./hooks/utilities";
import { ErrorBoundary } from "./components/ErrorBoundry/ErrorBoundry";
import ProviderReport from "./views/ProviderReport";
import Client from "./views/Client";
import Staff from "./views/Staff";
import SetPassword from "./views/SetPassword";
import EquipmentTimeSheet from "./views/EquipmentTimeSheet";
import CreditNotesView from "./views/CreditNotesView";
import SpaceOnboarding from "./views/SpaceOnboarding";
import EquipmentView from "./views/EquipmentView";
import ExploreFeatures from "./views/ExploreFeatures";
import ConnectProviderView from "./views/ConnectProviderView";
import SpaceInvitation from "./views/SpaceInvitation";
import Benchmarks from "./views/Benchmarks";
import AiChat from "./views/AiChat";
import Tenants from "./views/Tenants";
import CustomFullCalendar from "./views/CustomFullCalendar";
import EquipmentsView from "./views/EquipmentsView";
import DashboardNew from "./views/DashboardNew";
import { loadDefaultMapParams } from "./constants/constants";
import PremiumEstimate from "./views/PremiumEstimate";
import EditProfile from "./views/EditProfile";
import Layout from "./config/Layout";
import AuthenticatedRoutes from "./config/AuthenticatedRoutes";
import ExternalRoutes from "./config/ExternalRoutes";
import LeadsIntegration from "./views/LeadsIntegration";
import Proposals from "./views/Proposals";
import Alerts from "./components/StandAlonePages/Alerts";
import CreateDPGFBenchmark from "./views/CreateDPGFBenchmark";
import ViewDPGFProposal from "./views/ViewDPGFProposal";
import ExternalPageConnector from "./views/ExternalPageConnector";
import Dashboard from "./views/Dashboard";
import { getTableInfo, initIDB } from "./hooks/indexed_db_helpers";
import userpaymnetServices from "./services/userpaymnet-services";
import AllscreenToastLoader from "./components/Toasts/AllscreenToastLoader";
import AgentHireFlow from "./views/AgentHireFlow";
import ProposalRegistration from "./views/ProposalRegistration";
import EmployeeRegistration from "./views/EmployeeRegistration";
import AgnentJobProposalOnboarding from "./views/AgnentJobProposalOnboarding";
import MyEmail from "./views/MyEmail";
import ExchangeGoogleCode from "./views/ExchangeGoogleCode";
import ProviderTable from "./views/ProviderTable";
import Equipment from "./views/Equipment";
import MyDrivePage from "./components/LeftPanelDrive/MyDrivePage";
import LineSpinFadeLoader from "./components/Loader/LineSpinFadeLoader";
// LogRocket.init("vo9fzt/myrai-app");

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    // Function to load CSS from local storage
    const loadCSSFromLocalStorage = () => {
      const css = localStorage.getItem('styleSheetBody');
      if (css) {
        const styleElement = document.createElement('style');
        styleElement.type = 'text/css';
        styleElement.textContent = css;
        document.head.appendChild(styleElement);
      }
    };

    loadCSSFromLocalStorage();
  }, []); // Empty dependency array means this runs once on component mount

  useEffect(() => {
    if (window.location.pathname === "/myspacenew") {
      setHideClass(true);
    } else {
      setHideClass(false);
    }
    /**Temporary solution to update notification counts from space API */
    //Start
    if (localStorage.getItem("accessToken")) {
      // dispatch(getCustomerSpaces(client_id, staffOrContactId));
    }
    //End
    const accessToken = localStorage.getItem("accessToken");
    const email = localStorage.getItem("email");
    const contact_id = localStorage.getItem("contact_id");
    const queryString = window.location.search;
    const queryParams = new URLSearchParams(queryString);

    // Access individual query parameters
    const appLanguage = queryParams.get("language");

    localStorage.setItem("activeGroupId", "");
    if (accessToken != "" && accessToken != null) {
      dispatch(fetchAuthToken(email, contact_id, "client"));
      dispatch(getTeams());
    }
    setTimeout(() => {
      const chatAccessToken = localStorage.getItem("chatAccessToken");
      var projectId = localStorage.getItem("selectedOffice");
      if (chatAccessToken != "" && chatAccessToken != null && projectId > 0) {
        dispatch(connectToSocket());
        var chatUserId = localStorage.getItem("chatUserId");
        dispatch(
          fetchUnreadMsgCount(
            chatUserId,
            localStorage.getItem("isLiteVersion") == "true" ? 0 : projectId
          )
        );
      }
    }, 3000);
    DefaultListView();
    switch (window.location.hostname) {
      case "staging.myr.ai":
        document.title += ` - Staging`;
        break;
      case "test.myr.ai":
        document.title += ` - Test`;
        break;
      case "app.myr.ai":
        break;
      case "localhost":
        document.title += ` - Local`;
        break;
      default:
        break;
    }
    dispatch(getDefaultTopicList());
    loadDefaultMapParams();

    if (isMinimumUpdateVersion()) {
      refreshCacheAndReload();
    }

    if (appLanguage) {
      let languageValue = "";

      switch (appLanguage) {
        case "en":
          languageValue = "english";
          break;
        case "fr":
          languageValue = "french";
          break;
      }

      dispatch(setSelectedLanguage(languageValue));
      localStorage.setItem("language", languageValue);
    }

    // userpaymnetServices.getIBANlisting().then((response) => {
    //   if (response && response.status) {
    //     dispatch(setListOfIBANAccounts(response.data));
    //   }
    // })
    localStorage.setItem('json_updating', false)
    initIDB();
  }, []);
 

  const [hideClass, setHideClass] = useState(false);
  const language = useSelector((state) => state.customer.get_language);
  const messagesStates = useSelector((state) => state.messages);
  const { isCallRecived } = useSelector((state) => state.chat);

  const userType = localStorage.getItem("user_type");
  const {
    calendarCanvasIsOpen,
    commanoffcanvasIsOpen,
    global_doc_type,
    callRecordingActive,
    globalView,
    showLoadingBox,
    IBANAccounts,
    idbUpdateAction,
    taskFilters
  } = useSelector((state) => state.customer);

  useEffect(() => {
    _l();
  }, [language]);

  useEffect(() => {
    // Find the default style tag and remove it
    const styleTag = document.querySelector('style[data-injected-by-react]');
    if (styleTag) {
      styleTag.remove();
    }
  }, []);
  

  const handleAutoLogin = (key) => {
    dispatch(autoLogin(key)).then(() => {
      const packageId = localStorage.getItem("membership_package_id");
      const accessToken = localStorage.getItem("accessToken");
      const navigateToExploreFeature =
        sessionStorage.getItem("skip_onboarding");

      if (accessToken) {
        if (Number(navigateToExploreFeature)) {
          sessionStorage.removeItem("skip_onboarding");
          window.location.href = "/";
        } else if (packageId == 1 || userType === "operator") {
          window.location.href = "/space-onboarding";
        } else {
          window.location.href = "/";
        }
      }
    });
  };

  useEffect(() => {
    if (
      !(
        commanoffcanvasIsOpen == true &&
        !["equipment_task", "type_of_space"].includes(global_doc_type)
      )
    ) {
      jquery(document).unbind("mouseup");
    }
  });

  // call timer start
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const [hoursForRecording, setHoursForRecording] = useState(0);
  const [minutesForRecording, setMinutesForRecording] = useState(0);
  const [secondsForRecording, setSecondsForRecording] = useState(0);

  useEffect(() => {
    const timerInterval = setInterval(() => {
      if (isCallRecived) {
        // Update seconds
        setSeconds((prevSeconds) => (prevSeconds + 1) % 60);

        // Update minutes if seconds reach 60
        if (seconds === 59) {
          setMinutes((prevMinutes) => (prevMinutes + 1) % 60);

          // Update hours if minutes reach 60
          if (minutes === 59) {
            setHours((prevHours) => prevHours + 1);
          }
        }
        updateDisplay();
      }
    }, 1000);

    // Clear interval on component unmount
    return () => clearInterval(timerInterval);
  }, [seconds, minutes, hours, isCallRecived]);

  useEffect(() => {
    const timerInterval = setInterval(() => {
      if (callRecordingActive) {
        // Update seconds
        setSecondsForRecording((prevSeconds) => (prevSeconds + 1) % 60);

        // Update minutes if seconds reach 60
        if (seconds === 59) {
          setMinutesForRecording((prevMinutes) => (prevMinutes + 1) % 60);

          // Update hours if minutes reach 60
          if (minutes === 59) {
            setHoursForRecording((prevHours) => prevHours + 1);
          }
        }
        updateRecordingTimeDisplay();
      }
    }, 1000);

    // Clear interval on component unmount
    return () => clearInterval(timerInterval);
  }, [
    hoursForRecording,
    minutesForRecording,
    secondsForRecording,
    callRecordingActive,
  ]);

  useEffect(() => {
    if (!isCallRecived) {
      setHours(0);
      setMinutes(0);
      setSeconds(0);
    }
  }, [isCallRecived]);

  useEffect(() => {
    if (!callRecordingActive) {
      setHoursForRecording(0);
      setMinutesForRecording(0);
      setSecondsForRecording(0);
    }
  }, [callRecordingActive]);

  function updateDisplay() {
    const formattedTime =
      (hours > 0 ? pad(hours) + ":" : "") + pad(minutes) + ":" + pad(seconds);
    let el1 = document.getElementById("call-timer-full");
    let el2 = document.getElementById("call-timer");

    dispatch(setCallRunnigTimer(formattedTime))
    if (document.getElementById("call-timer-full")) {
      document.getElementById("call-timer-full").innerText = formattedTime;
    }
    if (document.getElementById("call-timer")) {
      document.getElementById("call-timer").innerText = formattedTime;
    }
  }

  function updateRecordingTimeDisplay() {
    const formattedTime =
      (hoursForRecording > 0 ? pad(hoursForRecording) + ":" : "") +
      pad(minutesForRecording) +
      ":" +
      pad(secondsForRecording);

      dispatch(setCallRecordingRunnigTimer(formattedTime))
    if (document.getElementById("stopwatch")) {
      document.getElementById("stopwatch").textContent = formattedTime;
    }
    if (document.getElementById("recording-minute")) {
      document.getElementById("recording-minute").innerText = formattedTime;
    }
    if (document.getElementById("recording-sceonds")) {
      document.getElementById("recording-sceonds").innerText =
        secondsForRecording;
    }
  }
  // call timer end

  useEffect(() => {
    if (userType) {
      const fetchIBANListing = async () => {
        try {
          const response = await userpaymnetServices.getIBANlisting();
          if (response && response.status) {
            dispatch(setListOfIBANAccounts(response.data));
          }
        } catch (err) {
          console.error(err);
        }
      }
      fetchIBANListing();
    }
  }, [userType])
  
  useEffect(async() => {
    // let idbData = await fetchTableData();
    // console.log("idbData",idbData)
  localStorage.setItem("isAttachPreview", false);
   if (localStorage.getItem('localLeftPanelTab')) {
    dispatch(setLeftPanelTab(localStorage.getItem('localLeftPanelTab')));
   }

  }, []);

  useEffect(() => {
    if (idbUpdateAction) {
      handleIDBUpdateAction(idbUpdateAction, taskFilters ,dispatch)
    }
  }, [idbUpdateAction])
  
  
  return (
    <ErrorBoundary>
      <div>
        {
           messagesStates && messagesStates.idbDataLoader ?
            <div>
              {/* <CommanLoader
            className="main-loader-wrapper full-screen"
            message={"l_fetching_data"}
          /> */}
              <LineSpinFadeLoader />
            </div>
            : <></>
        }
      
        <div
          id="modified_height"
          className={`App comman-body-wrapper ${
            window.location.pathname === "/spaces-dashboard"
              ? "modified-res-default-height"
              : ""
          } ${hideClass ? "" : "modified-height"} ${
            messagesStates && messagesStates.idbDataLoader ? "d-none" : ""
          }`}
        >
          {
            globalView == "spaceView" 
            ? <></>
            : <div
              className={`${calendarCanvasIsOpen ? "" : "modal-overlay"}`}
              id="modal-overlay-toggle"
              ></div> 
          }
          <Router>
            <Alerts />
            {showLoadingBox ? <AllscreenToastLoader /> : <></>}
            <Routes>
              <Route path="/login" element={<Login />}></Route>
              <Route exact path="/redirect" element={<Redirect />} />
              <Route
                exact
                path="/autologin/:param"
                element={<Redirect handler={handleAutoLogin} />}
              />
              <Route exact path="/:page/:id" element={<Main />} />
              <Route
                exact
                path="/set-password/:id/:type"
                element={<SetPassword />}
              />
              <Route
                exact
                path="space-invitation/:id"
                element={<SpaceInvitation />}
              />
              <Route
              exact
              path="/exchange_google_code"
              element={<ExchangeGoogleCode />}
            />
              <Route
                exact
                path="ExchangeGmailCode"
                element={<ExchangeGoogleCode provider={"gmail"} />}
              />

              <Route path="*" element={<Layout />}>
                <Route element={<AuthenticatedRoutes />}>
                  <Route exact path="dashboard" element={<DashboardNew />} />
                  <Route
                    exact
                    path="provider-reporting"
                    element={<ProviderReport />}
                  />
                  <Route
                    exact
                    path="employee-reporting"
                    element={<EmpolyeeTimeSheets />}
                  />
                  <Route
                    exact
                    path="equipment-reporting"
                    element={<EquipmentTimeSheet />}
                  />
                  <Route exact path="liteversion" element={<LiteVersion />} />
                  <Route
                    exact
                    path="calendar"
                    element={<CustomFullCalendar pageName="calendar" />}
                  />
                  <Route exact path="aichat" element={<AiChat />} />
                  <Route
                    path="space-onboarding"
                    element={<SpaceOnboarding />}
                  />
                  <Route
                    exact
                    path="explore-features"
                    element={<ExploreFeatures />}
                  />
                  <Route
                    exact
                    path="connect-provider"
                    element={<ConnectProviderView />}
                  />
                  <Route exact path="tenants" element={<Tenants />} />
                  <Route exact path="employees" element={<Tenants />} />
                  <Route exact path="providers" element={<Tenants />} />
                  <Route exact path="equipments" element={<EquipmentsView />} />
                  <Route
                    exact
                    path="premiumsubscription"
                    element={<PremiumEstimate />}
                  />
                  <Route exact path="myprofile" element={<EditProfile />} />
                  <Route exact path="MyEmail" element={<MyEmail />} />
                  <Route exact path="leads" element={<MyLeads />} />
                  <Route exact path="dashboardpre" element={<MapPage />} />
                  <Route exact path="clients" element={<Client />} />
                  <Route exact path="proposals" element={<Proposals />} />
                  <Route exact path="staff" element={<Staff />} />
                  <Route
                    exact
                    path="credit_notes"
                    element={<CreditNotesView />}
                  />
                  <Route exact path="benchmarks" element={<Benchmarks />} />
                  <Route
                    exact
                    path="equipmentListing"
                    element={<EquipmentView />}
                  />
                  <Route
                    exact
                    path="lead-form"
                    element={<LeadsIntegration />}
                  />
                  <Route
                    exact
                    path="create-dpgf-benchmark"
                    element={<CreateDPGFBenchmark />}
                  />
                  <Route
                    exact
                    path="view-dpgf-proposal"
                    element={<ViewDPGFProposal />}
                  />
                   <Route exact path="providerlist" element={<ProviderTable />} />
                   <Route exact path="equipment-table" element={<Equipment />} />
                   <Route exact path="hire-new-agent" element={<AgentHireFlow />} />
                   <Route exact path="my-drive" element={<MyDrivePage />} />
                    <Route exact path="onboarding-proposal" element={<ProposalRegistration />} />
                    <Route exact path="onboarding-employees" element={<EmployeeRegistration />} />
                    {/* <Route exact path="view-onboarding-proposal" element={<OnboardingProposal />} /> */}
                    <Route exact path="job-proposal-onboarding" element={<AgnentJobProposalOnboarding />} />
                  <Route exact path="*" element={<Dashboard />} />
                </Route>
                <Route element={<ExternalRoutes />}>
                  <Route exact path="*" element={<ExternalPageConnector />} />
                </Route>
              </Route>
            </Routes>
          </Router>
        </div>
      </div>
    </ErrorBoundary>
  );
}

export default App;
