
import React, { useEffect, useState } from 'react'
import EstimateIcon from '../../assets/icons/EstimateIcon';
import FileTextIcon from '../../assets/icons/FileTextIcon';
import ArrowsClockwiseIcon from '../../assets/icons/ArrowsClockwiseIcon';
import FilesIcon from '../../assets/icons/FilesIcon';
import FileCloudIcon from '../../assets/icons/FileCloudIcon';
import { useDispatch, useSelector } from 'react-redux';
import { _l } from '../../hooks/utilities';
import {  setMyDriveTopic } from '../../actions/customer';

const MyDrive = ({
    data,
    guestUserModal = false,
}) => {
  const { defaultTopics, myDriveTopic, clientsTotalCounts,commanoffcanvasIsOpen } = useSelector((state) => state.customer);
    const [sectionList, setSectionList] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState({
        id: data.id,
        title: data.title,
        is_active: data.is_active,
        main_sub_key: data.main_sub_key,
      });
    const dispatch = useDispatch();
    const userType = localStorage.getItem('user_type');

    const getTopicNotificationCounts = (categoryName) => {
        if (!defaultTopics || !defaultTopics.length) {
          return 0;
        }
        const mainCategoryIndex = defaultTopics.findIndex(
          (w) => w.main_key == data.main_key
        );
        if (
          mainCategoryIndex > -1 &&
          defaultTopics[mainCategoryIndex].categories.find(
            (w) =>
              w.filter_key == selectedCategory.id &&
              w.main_sub_key == selectedCategory.main_sub_key
          )
        ) {
          let currentCategory = defaultTopics[mainCategoryIndex].categories.find(
            (w) => w.filter_key == selectedCategory.id
          );
    
          if (
            !currentCategory ||
            !currentCategory.total_notification_count ||
            !Object.keys(currentCategory.total_notification_count).length
          ) {
            return 0;
          }
    
          switch (categoryName) {
            case "schedules":
              if(data.main_key === "myr_support")
              {
                return 0;
              }
              return currentCategory.total_notification_count.schedule_count + currentCategory.total_notification_count.benchmark_count;
            case "proposals":
            case "benchmarks":
              return currentCategory.total_notification_count.benchmark_count;
            default:
              return 0;
          }
        }
      };

    useEffect(() => {
        let topicList = [];
        if (!myDriveTopic || ( myDriveTopic && Object.keys(myDriveTopic).length == 0 )) {          
          dispatch(setMyDriveTopic({
              key: "invoice",
              name: "l_invoices",
              icon: FileTextIcon,
              notificationCounts: clientsTotalCounts.drive && clientsTotalCounts.drive.invoice ? clientsTotalCounts.drive.invoice : 0,
              // notificationCounts: getTopicNotificationCounts("invoice"),
            }))
        }
        if (window.location.pathname == "/dashboard" || guestUserModal) {
            topicList = [
              {
                key: "invoice",
                name: "l_invoices",
                icon: FileTextIcon,
                // notificationCounts: getTopicNotificationCounts("invoice"),
                notificationCounts: clientsTotalCounts.drive && clientsTotalCounts.drive.invoice ? clientsTotalCounts.drive.invoice : 0,
              },
              {
                key: "estimate",
                name: "l_estimates",
                icon: EstimateIcon,
                // notificationCounts: getTopicNotificationCounts("estimate"),
                notificationCounts: clientsTotalCounts.drive && clientsTotalCounts.drive.estimate ? clientsTotalCounts.drive.estimate : 0,
              },
              {
                key: "credit_notes",
                name: "l_credit_note",
                icon: FilesIcon,
                // notificationCounts: getTopicNotificationCounts("credit_notes"),
                notificationCounts: clientsTotalCounts.drive && clientsTotalCounts.drive.creit_note ? clientsTotalCounts.drive.creit_note : 0,
              },
              {
                key: "subscription",
                name: "l_subscriptions",
                icon: ArrowsClockwiseIcon,
                notificationCounts: clientsTotalCounts.drive && clientsTotalCounts.drive.subscription ? clientsTotalCounts.drive.subscription : 0,
                // notificationCounts: getTopicNotificationCounts("subscription"),
              },
            ];
            if (window.location.pathname == "/dashboard") {
              const spaceTab = [{
                key: "space",
                name: "l_space_document",
                icon: FileCloudIcon,
                // notificationCounts: getTopicNotificationCounts("space"),
                notificationCounts: clientsTotalCounts.drive && clientsTotalCounts.drive.documents ? clientsTotalCounts.drive.documents :10
              }]
              topicList = topicList.concat(spaceTab)  
            }
          } else {
          topicList = [
            {
              key: "invoice",
              name: "l_invoices",
              icon: FileTextIcon,
              notificationCounts: clientsTotalCounts.drive && clientsTotalCounts.drive.invoice ? clientsTotalCounts.drive.invoice : 0,
            },
            {
              key: "estimate",
              name: "l_estimates",
              icon: EstimateIcon,
              // notificationCounts: getTopicNotificationCounts("estimate"),
              notificationCounts: clientsTotalCounts.drive && clientsTotalCounts.drive.estimate ? clientsTotalCounts.drive.estimate : 0,
            },
            {
              key: "credit_notes",
              name: "l_credit_note",
              icon: FilesIcon,
              // notificationCounts: getTopicNotificationCounts("credit_notes"),
              notificationCounts: clientsTotalCounts.drive && clientsTotalCounts.drive.creit_note ? clientsTotalCounts.drive.creit_note : 0,
            },
            {
              key: "subscription",
              name: "l_subscriptions",
              icon: ArrowsClockwiseIcon,
              notificationCounts: getTopicNotificationCounts("subscription"),
              notificationCounts: clientsTotalCounts.drive && clientsTotalCounts.drive.subscription ? clientsTotalCounts.drive.subscription : 0,
            },
            {
              key: "google_drive",
              name: "l_documents",
              icon: FilesIcon,
              // notificationCounts: getTopicNotificationCounts("documents"),
              notificationCounts: clientsTotalCounts.drive && clientsTotalCounts.drive.documents ? clientsTotalCounts.drive.documents : 0
            },
            // {
            //   key: "space",
            //   name: "l_space_document",
            //   icon: FileCloudIcon,
            //   notificationCounts: getTopicNotificationCounts("space"),
            // },
            // {
            //   key: "lease_document",
            //   name: "l_lease_document",
            //   icon: FilesIcon,
            //   notificationCounts: getTopicNotificationCounts("space"),
            // },
          ];
          }
          if (userType == "operator") {
            const payoutsTab = [{
              key: "payouts",
              name: "l_payouts",
              icon: FileCloudIcon,
              notificationCounts: getTopicNotificationCounts("space"),
            }]
            topicList = topicList.concat(payoutsTab)  
          }
          setSectionList(topicList);
    }, [clientsTotalCounts, commanoffcanvasIsOpen])
    
  return (
    <div className='m-2'>
        <ul className="w-100 d-flex flex-column left-part overflow-hiiden-auto comman-vertical-tabs z-index-2 list-view-toggle pe-0 mt-0 pt-1">
            {sectionList.map((topic, key) => {
                return (
                <li className="comman_action_icon">
                    <a href="#/"
                    key={key}
                    className={`position-relative task-sub-list w-100 p-10 mb-2 hovertextgreen on-hover-active-toggle-img text-on-hover-active-green ${
                        myDriveTopic && myDriveTopic.key == topic.key ? "active" : ""
                    }`}
                    onClick={() => {
                        dispatch(setMyDriveTopic(topic))
                    }}
                    >
                    <div className="w-100 d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center flex-grow-1 w100minus35 text-truncate">
                        <div className="h32w32 d-flex align-items-center justify-content-center action_icon with_bg">
                            <topic.icon
                            size={20}
                            weight={`${
                                topic.name === "l_credit_note"
                                ? "fill"
                                : "light"
                            }`}
                            className="HW18 active-svg"
                            />
                        </div>
                        <div className=" flex-grow-1 w-0 text-truncate ps-10px hover-span">
                            {_l(`${topic.name}`)}
                        </div>
                        </div>
                        {topic.notificationCounts > 0 ? (
                        <div className="badge-green d-flex justify-content-center h18w18 rounded-circle">
                            <span className="m-auto p-0">
                            {topic.notificationCounts}
                            </span>
                        </div>
                        ) : (
                        <React.Fragment></React.Fragment>
                        )}
                    </div>
                    </a>
                </li>
                );
            })}
        </ul>
    </div>
  )
}

export default MyDrive