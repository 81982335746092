import React from 'react';
 

function MailContext(props){
	const height 	= (props.height) ? props.height : "13";
	const width 	= (props.width) ? props.width : "16";
	const viewBox 	= (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW 	= (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return(		
		<React.Fragment>
            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons ${CommanHW}`}>
            <path d="M7 8H19" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M7 10H19" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M7 13H19" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M17 4L13.7873 1.26355C13.3405 0.912149 12.6595 0.912149 12.2126 1.26355L9 4" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M24 11.5L22 12" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M15 19L23.5 24.5" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M4 12L2.5 11.5" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M11 19L2.5 24.5" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M19 25H22.8321C23.4771 25 24 24.4638 24 23.8024V11.3782C24 11.2169 23.9686 11.0572 23.9078 10.9084C23.8469 10.7597 23.7577 10.6249 23.6454 10.5119L22.1431 9" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M2 15V23.7769C2 24.4524 2.55057 25 3.22973 25H19" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M4 9L2.38192 10.6197C2.261 10.7408 2.16494 10.8852 2.09936 11.0446C2.03379 11.2039 2.00001 11.375 2 11.5478V16" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M22 7.7383V5.25087C22 4.56004 21.4425 4 20.7549 4H5.24514C4.55747 4 4 4.56004 4 5.25087V11.8846L11.0953 19L12.4084 18.2751C12.5897 18.175 12.7932 18.1226 13 18.1226C13.2068 18.1226 13.4103 18.175 13.5916 18.2751L14.9047 19L22 11.8846V7.7383Z" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
		</React.Fragment>
	)
}

export default MailContext