import React, { useEffect, useState } from "react";
import Select from "react-select";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { validateEmail } from "../../hooks/utilities";
import Toasts from "../Toasts/Toasts";
import CustomerServices from "../../services/customer-services";
import { _l } from "../../hooks/utilities"; 
const AddClient = ({ projectId, show, handleClose, handleClient }) => {

  const [companyName, setCompanyName] = useState("");
  const [contactName, setContactName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [toastClass, setToastClass] = useState("");
  const [toastMessage, setToastMessage] = useState("");
  const [toastHeader, setToastHeader] = useState("");
  const [countryCodes, setCountryCodes] = useState([]);
  const [countryCode, setCountryCode] = useState({ value: '+33', label: '+33' })
  const prepareToast = (classType, header, message) => {
    setShowToast(true);
    setToastClass(classType);
    setToastHeader(header);
    setToastMessage(message);
  };

  const validateInputFields = () => {
    if (companyName == '') {
      prepareToast("unsucess", _l('l_error'), _l('l_please_enter_company_name'));
      return false;
    }
    else if (contactName == '') {
      prepareToast("unsucess", _l('l_error'), _l('l_please_enter_contact_name'));
      return false;
    } else if (countryCode == '') {
      prepareToast('unsucess', 'Error', _l('l_please_select_country_code'));
      return false;
    } else if (!mobileNumber || !mobileNumber.trim().length) {
      prepareToast("unsucess", _l('l_error'), _l('l_please_enter_valid_mobile_number'));
      return false;
    }
    else if (email == '' || !validateEmail(email)) {
      prepareToast("unsucess", _l('l_error'), _l('l_please_enter_valid_email_address'));
      return false;
    }
    return true;
  }
  useEffect(() => {
    if (!show) return;

    setCompanyName('')
    setEmail('')
    setMobileNumber('')

    const fetchCountryCode = async () => {
      try {
        const res = await CustomerServices.getCountryCodes();

        if (res.status === 1) {
          setCountryCodes(
            res.data.map((code) => {
              return {
                value: code.code_value,
                label: code.code_value,

              };
            })
          );
        }
      } catch (err) {
        console.error(err);
      }
    }

    fetchCountryCode();
  }, [show])

  const handleSave = () => {
    if (validateInputFields()) {
      let countryCodeValue = countryCode.value
      CustomerServices.addClientToSpace(companyName, contactName, email, countryCodeValue + ' ' + mobileNumber, projectId).then((res) => {
        if (res.status == 1) {
          prepareToast("sucess", _l('l_success'), _l('l_add_client_sucessfully'));
          handleClient(res.data.contact_id, projectId)
          handleClose()
        } else {
          prepareToast("unsucess", _l('l_error'), res.message ? res.message : _l('l_something_went_wrong'));
        }
      });
    }
  }

  return (
    <>
      {showToast ? <Toasts delay={2500} handler={() => { setShowToast(false) }} header={toastHeader} message={toastMessage} toastClass={toastClass} cancelButtonHandler={() => { setShowToast(false); }} cancelButtonText={"cancel"} confirmButtonText={"Delete"} /> : <></>}
      <Modal
        show={show}
        onHide={handleClose}
        className="custom-modal-style"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="lg"
        scrollable={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>{_l('l_add_client')}</Modal.Title>
        </Modal.Header>
        <Modal.Body className=" pt-0">
          <div className="form-wrapper-main">
            <div className="row ">
              <Form.Group className="col-xl-6 c-input-box pb-4 position-relative ">
                <Form.Label className="input-label ">
                {_l('l_company_name')}
                </Form.Label>
                <Form.Control
                  placeholder={`${_l('l_company_name_placeholder')}`}
                  type="text"
                  name=""
                  value={companyName}
                  onChange={(e) => {
                    const reg = new RegExp('^[a-zA-Z0-9. ]+$');
                    const inputValue = e.target.value;
                    if (reg.test(inputValue) || inputValue == '')
                      setCompanyName(e.target.value);

                  }}
                />
              </Form.Group>
              <Form.Group className="col-xl-6 c-input-box pb-4 position-relative ">
                <Form.Label className="input-label ">
                {_l('l_contact_name')} 
                </Form.Label>
                <Form.Control
                  placeholder={_l('l_contact_name_placeholder')}
                  type="text"
                  name=""
                  value={contactName}
                  onChange={(e) => {
                    const reg = new RegExp('^[a-zA-Z0-9. ]+$');
                    const inputValue = e.target.value;
                    if (reg.test(inputValue) || inputValue == '')
                      setContactName(e.target.value);

                  }}
                />
              </Form.Group>
              <Form.Group className="col-xl-6 c-input-box position-relative">
                <Form.Label className="input-label no-asterisk ">
                {_l('l_mobile_number')}
                </Form.Label>
                <div className="row">
                  <div
                    className="c_input_border drop_image col-3 col-md-3  col-xl-3 position-relative"

                  >
                    <Select
                      className="custom-select-menu"
                      classNamePrefix="react-select"
                      placeholder={`${_l("l_region")}`}
                      options={countryCodes}
                      closeMenuOnSelect={true}
                      value={countryCode}
                      onChange={(value) => { setCountryCode(value) }}
                    />
                  </div>
                  <div className="col-9 col-md-9 col-xl-9 ps-md-0">
                    <Form.Control
                      placeholder={_l('l_mobile_number_placeholder')}
                      type="Number"
                      name="phonenumber"
                      value={mobileNumber}
                      onChange={(e) => {
                        setMobileNumber(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </Form.Group>
              <Form.Group className="col-xl-6 c-input-box pb-4 position-relative">
                <Form.Label className="input-label ">
                {_l('l_contact_email')}
                </Form.Label>
                <Form.Control
                  placeholder={_l('l_email_placeholder')}
                  value={email}
                  type="email"
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </Form.Group>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0 justify-content-end">
          <Button variant="secondary" size="sm" onClick={handleClose}>
          {_l('l_close')}
          </Button>
          <Button variant="primary" size="sm" onClick={handleSave}>
          {_l('l_save')}{" "}
          </Button>
        </Modal.Footer>
      </Modal >
    </>
  );
};

export default AddClient;
