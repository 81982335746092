import React, { useEffect, useState } from "react";
import LogoGreenDark from "../../assets/icons/LogoGreenDark";
import LogoCircle from "../../assets/images/myr-ai-logo-circle.svg";
import { TaskModalClose, _l, formateDate, getNotificationCountFromSpaceState, hideRightPanelNew, loadDataToIDB, showLeftPanelNew, sumUnreadNotificationsOfSpace } from "../../hooks/utilities";
import { SetSelectedTask, commanoffcanvasisOpen, dayGridDay, dayGridMonth, dayGridWeek, emailTokenExpired, getDefaultTopics, leftPanelFilters, setDaterangeFilter, setDaterangeFilterApplied, setFilterSpaceProjectid, setLeftPanelTab, setOpenOrCloseContactDirectoryChat, setSelectedGloablView } from "../../actions/customer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import customerServices from "../../services/customer-services";
import GaugeIcon from "../../assets/icons/GaugeIcon";
import BuildingsIcon from "../../assets/icons/BuildingsIcon";
import UserGearIcon from "../../assets/icons/UserGearIcon";
import UserCircleIcon from "../../assets/icons/UserCircleIcon";
import UserPlusIcon from "../../assets/icons/UserPlusIcon";
import UsersIcon from "../../assets/icons/UsersIcon";
import PhoneDisconnectIcon from "../../assets/icons/PhoneDisconnectIcon";
import WrenchIcon from "../../assets/icons/WrenchIcon";
import EnvelopeIcon from "../../assets/icons/EnvelopeIcon";
import HardDrivesIcon from "../../assets/icons/HardDrivesIcon";
import CalendarBlankIcon from "../../assets/icons/CalendarBlankIcon";
import DotsThreeVerticalIcon from "../../assets/icons/DotsThreeVerticalIcon";
import ArrowsClockwiseIcon from "../../assets/icons/ArrowsClockwiseIcon";

const LeftPanelMain = ({ 
    setshowMyProfile = () => {}, 
    showMyProfile,
    contactlistShow,
    setContactlistShow = () => {},
    contactDirTopicChange = () => {},
    setSettingTopicName = () => {},
    setTopicMainKey = () => {},
    handleChangeViewtoFavView = () => {},
    isSynced,
    setShowSpaceList = () => {},
    staffChatCounts,
    tenentsChatCounts,
 }) => {
  const { leftPanelTab, taskFilters, commanoffcanvasIsOpen, clientsTotalCounts } = useSelector((state) => state.customer);

  const dispatch = useDispatch();
  const navigate = useNavigate();


  const userType = localStorage.getItem("user_type");
  let projectId = localStorage.getItem("selectedOffice");
  let currentCompanyID = localStorage.getItem("currentCompanyID") ? localStorage.getItem("currentCompanyID") : 0;
  const contact_role = localStorage.getItem("contact_role");
  const is_agent = localStorage.getItem("is_agent") == 1 ? "1" : "0";

  const [showText, setshowText] = useState(true)
  
  const handleChangeTab = (tab, navigation) => {
    TaskModalClose();
    dispatch(SetSelectedTask());
    localStorage.setItem("currentLeftPanelTab", tab);
    dispatch(commanoffcanvasisOpen(false));
    if (tab) {
      dispatch(setLeftPanelTab(tab));
      setShowSpaceList(false);
    }
    if (navigation) {
    navigate(navigation);
    }
    if (tab == "my-tenants") {
      localStorage.removeItem("selectedTopic");
      localStorage.removeItem("activeGroupId");
      dispatch(getDefaultTopics(0, "", "", null));
    }
    if (tab == "my-contacts") {
      dispatch(setLeftPanelTab("my-contacts"));
      dispatch(setOpenOrCloseContactDirectoryChat(true));
      setContactlistShow(true);
      contactDirTopicChange({}, false);
    } else {      
      dispatch(setOpenOrCloseContactDirectoryChat(false));
      setContactlistShow(false);
      localStorage.removeItem("isContactDir");
    }

    if (tab == "my-calendar") {
      dispatch(setSelectedGloablView("calendarView"));
        dispatch(dayGridDay(false));
        dispatch(dayGridWeek(false));
        dispatch(dayGridMonth(true));
        dispatch(setDaterangeFilterApplied(true));
        dispatch(leftPanelFilters("","","applyForMeAndMonth"));
        localStorage.setItem("fromDate",formateDate(moment(new Date()).startOf('month')));
        localStorage.setItem("toDate", formateDate(moment(new Date()).endOf('month')));
    } else {
      dispatch(dayGridDay(true));
      dispatch(dayGridWeek(false));
      dispatch(dayGridMonth(false));
      handleChangeViewtoFavView();
    }
    hideRightPanelNew();
    showLeftPanelNew();

    if (tab == "my-request" || tab == "my-drive" || tab == "my-tenants" || tab == "my-staff") {
      dispatch(setDaterangeFilter("all"))
      dispatch(setDaterangeFilterApplied(true));
    }else{
      dispatch(setDaterangeFilter("today"))
      dispatch(setDaterangeFilterApplied(false));
      dispatch(leftPanelFilters("","","all"))
    }

    if (contact_role == 3 && tab == "my-space") {
      localStorage.setItem("fav_dashboard", "AichatView")
      dispatch(setSelectedGloablView("AichatView"));;
    }

  }
  
  const updateIDB = async() =>{
    try {
      let res = await customerServices.updateIDBJson()
      if (res && res.status) {
        loadDataToIDB(localStorage.getItem("staff_id") , false)
      } else {
        
      }
    } catch (error) {
      
    }

  }


  useEffect(() => {
    if (leftPanelTab == "my-email" ) {
        dispatch(getDefaultTopics(projectId, "", "", "MyEmail",taskFilters));
    }
  }, [leftPanelTab])

  useEffect(() => {
    if ((leftPanelTab === "my-providers" && currentCompanyID > 0) || (leftPanelTab == "homepage" && commanoffcanvasIsOpen == true) || (leftPanelTab === "equipments" && currentCompanyID > 0)) {
      setshowText(true);
    } else {
      setshowText(false);
      
    }
    
  }, [leftPanelTab,currentCompanyID, commanoffcanvasIsOpen ])

useEffect(() => {
  const handleClickOutside = (event) => {
    // Get the menu element by its ID
    const menuElement = document.getElementById('profile-menu');
    
    // Check if the menu element exists and if the click is outside of it
    if (menuElement && !menuElement.contains(event.target)) {
      // setIsOpen(false); // Example action: close the menu
            setshowMyProfile(false)
    }
  };

  // Attach the event listener to document
  document.addEventListener('mousedown', handleClickOutside);

  // Clean up the event listener on component unmount
  return () => {
    document.removeEventListener('mousedown', handleClickOutside);
  };
}, []);

  
  
  return (
    <>
      <div
        id="intro-office-list"
        className="expanded fixed-left-panel intro-office-list office-list"
      >
        <div className="fixed-left-panel-wrapper h-100 d-flex flex-column">
          <div className="upper-part ">
            <a href="#/" className="company-logo-box position-relative office-box w-100 ps14px"
            onClick={()=>{
              handleChangeTab("homepage", "/"); 
            }}>
              <div className="d-flex align-items-center  h-100">
                <a href="#" className={`comman-round-box ${userType == "staff" ? 'h50px' : 'h40w40'} `}>
                {(leftPanelTab === "my-providers" && currentCompanyID > 0) || commanoffcanvasIsOpen  
                  ? userType == "staff" 
                    ? <img src={LogoCircle} className="header-logo-img h-100 w-100" /> 
                    :<div className="comman-bg-img h40w40 bg-style-cover" style={{backgroundImage: `url(${localStorage.getItem('company_logo_header')})`}}></div>
                  : userType == "staff" 
                  ? <LogoGreenDark className="header-logo-img h-100 w-100" /> 
                    : <div className="comman-bg-img h40w40 bg-style-cover" style={{backgroundImage: `url(${localStorage.getItem('company_logo_header')})`}}></div> 
                 }
                {commanoffcanvasIsOpen && leftPanelTab == "homepage" && clientsTotalCounts.dashboard > 0 &&  <span class="rounded-pill badge-custom p-0 ">{clientsTotalCounts.dashboard}</span>}
                </a>
                <div className="align-items-center company-logo-box d-flex flex-grow-1 justify-content-between on-hover-active-toggle-img position-relative ps-4 pe-0 comman_action_icon w100minus40">
                    <div className="title-fonts fw-semibold text-truncate c-font f-18 text-uppercase hover-span w100minus100">
                      {localStorage.getItem("company_name")}
                    </div>
                    {
                      clientsTotalCounts && clientsTotalCounts.dashboard > 0 ? <>
                      <div className="rounded-pill badge-custom h18w18 position-static my-auto badge-green rounded-circle d-flex right-accordian-header-pill border-0">
                      <span className="m-auto ps-0 ">{clientsTotalCounts.dashboard}</span>
                      </div>
                      </> : <></>
                    }
                  {/* <a href="#/" className="h32w32 action_icon with_bg"
                  onClick={()=>{
                    updateIDB()
                  }}>
                    <ArrowsClockwiseIcon className="HW18" />
                  </a> */}
              </div>
              </div>
            </a>
          </div>
          <ul className="office-list-main mt-0 pt-10px" id="left-office-list">

            {/* ----------------------------- My Request start ----------------------------- */}

            <li className={`office-box p-0 on-hover-active-toggle-img ${leftPanelTab == "my-request" ? "active" : ""}`}>
              <a
                href="#/"
                className="w-100 d-flex align-items-center p20px px-0 h70w70 px-0 d-flex align-items-center comman_action_icon pe-20pximp"
                onClick={() => {
                  if (leftPanelTab != "my-request") {
                    dispatch(leftPanelFilters("","","applyForMewithAll"));
                    dispatch(setSelectedGloablView("listView"));
                    handleChangeTab("my-request" , "/");                
                  }
                }}
              >
                <div className="d-flex align-items-center flex-grow-1">
                  <div className="d-flex flex-column justify-content-center align-items-center  w-70px">
                    <div className="p-20 py-0 text-center">
                    <div className="h32w32">
                      <GaugeIcon className="HW28" />
                    </div>
                    </div>
                     
                    {showText ?  <div class={`color-white-80 c-font f-9  office-subtitle  hover-span fw-light hover-span line-height-1   text-truncate text-center w-100 pt-1 px-1`}>{_l("l_my_tasks")}</div> : <React.Fragment></React.Fragment>}
                    
                  </div>
                  <div className="c-font f-15 hover-span">
                    {_l("l_my_tasks")}
                  </div>
                </div>
                  {
                      clientsTotalCounts && clientsTotalCounts.my_task > 0? <>
                      <div className="badge-green border-0 d-flex h18w18 my-auto rounded-circle">
                      <span className="m-auto ps-0 ">{clientsTotalCounts.my_task}</span>
                      </div>
                      </> : <></>
                    }
              </a>
            </li>
            {/* ----------------------------- My Request End ----------------------------- */}

            {/* ----------------------------- My Spaces start ----------------------------- */}

            {userType === "operator" && contact_role == 3 && is_agent == 1 ? <React.Fragment></React.Fragment> :
            <li className={`office-box p-0 on-hover-active-toggle-img ${leftPanelTab == "my-space" ? "active" : ""}`}>
              <a
                href="#/"
                className="w-100 d-flex align-items-center p20px px-0 h70w70 px-0 d-flex align-items-center comman_action_icon pe-20pximp"
                onClick={() => {
                  if (leftPanelTab != "my-space") {
                    // handleChangeTab();
                    setShowSpaceList(true);
                  }
                }}
              >
                <div className="d-flex align-items-center flex-grow-1">
                  <div className="d-flex flex-column justify-content-center align-items-center w-70px">
                  <div className="p-20 py-0 text-center">
                  <div className="action_icon h30w30 without-bg">
                    <BuildingsIcon className="HW28" />
                  </div>
                  </div>
                  {showText ?  <div class={`color-white-80 c-font f-9  office-subtitle  hover-span fw-light hover-span line-height-1   text-truncate text-center w-100 pt-1 px-1`}>{_l("l_space")}</div> : <React.Fragment></React.Fragment>}
                    </div>
                  <div className="c-font f-15 hover-span">
                   {contact_role == 3 ? _l("l_chat_modal") : _l("l_space")}
                  </div>
                </div>
                {console.log("getNotificationCountFromSpaceState",getNotificationCountFromSpaceState())}
              {
                      getNotificationCountFromSpaceState() > 0 ? <>
                      <div className="badge-green border-0 d-flex h18w18 my-auto rounded-circle">
                      <span className="m-auto ps-0 ">{getNotificationCountFromSpaceState()}</span>
                      </div>
                      </> : <></>
                    }
              </a>
            </li>
            }

            {/* ----------------------------- My Spaces End ----------------------------- */}

            {/* ----------------------------- My Clients start ----------------------------- */}
            {userType === "operator" && contact_role != 3 ?
            <li className={`office-box p-0 on-hover-active-toggle-img ${leftPanelTab == "my-clients" ? "active" : ""}`}>
              <a
                href="#/"
                className="w-100 d-flex align-items-center p20px px-0 h70w70 px-0 d-flex align-items-center comman_action_icon pe-20pximp"
                onClick={() => {
                  if (leftPanelTab != "my-clients") {
                    handleChangeTab("my-clients" , "/");                  
                  }
                }}
              >
                  <div className="d-flex flex-column justify-content-center align-items-center p-20 py-0 text-center w-70px">
                  <div className="p-20 py-0 text-center">
                  <div className="action_icon h30w30 without-bg">
                    <UserGearIcon className="HW28" />
                  </div>
                  </div>
                  {showText ?  <div class={`color-white-80 c-font f-9  office-subtitle  hover-span fw-light hover-span line-height-1   text-truncate text-center w-100 pt-1 px-1`}>{_l("l_my_clients")}</div> : <React.Fragment></React.Fragment>}
                    </div>
                  <div className="c-font f-15 hover-span">
                    {_l("l_my_clients")}
                  </div>
              </a>
            </li>
            : <React.Fragment></React.Fragment>}
            {/* ----------------------------- My Clients End ----------------------------- */}

            {/* ----------------------------- My Agents start ----------------------------- */}
          {userType === "operator" && contact_role != 3 ?
            <li className={`office-box p-0 on-hover-active-toggle-img ${leftPanelTab == "my-agents" ? "active" : ""}`}>
              <a
                href="#/"
                className="w-100 d-flex align-items-center p20px px-0 h70w70 px-0 d-flex align-items-center comman_action_icon pe-20pximp"
                onClick={() => {
                  if (leftPanelTab != "my-agents") {
                    handleChangeTab("my-agents" , "/");
                    dispatch(setSelectedGloablView("AichatView"));
                  }
                }}
              >
                  <div className="d-flex flex-column justify-content-center align-items-center w-70px">
                  <div className="p-20 py-0 text-center">
                  <div className="action_icon h30w30 without-bg">
                    <UserCircleIcon className="HW28" />
                  </div>
                  </div>
                  {showText ?  <div class={`color-white-80 c-font f-9  office-subtitle  hover-span fw-light hover-span line-height-1   text-truncate text-center w-100 pt-1 px-1`}>{_l("l_my_agents")}</div> : <React.Fragment></React.Fragment>}
                    </div>
                  <div className="c-font f-15 hover-span">
                    {_l("l_my_agents")}
                  </div>
              </a>
            </li>
            : <React.Fragment></React.Fragment>}
            {/* ----------------------------- My Agents End ----------------------------- */}

            {/* ----------------------------- chat Agents start ----------------------------- */}
          {userType === "operator" && contact_role == 3 && is_agent == 1 ?
            <li className={`office-box p-0 on-hover-active-toggle-img ${leftPanelTab == "chat-agents" ? "active" : ""}`}>
              <a
                href="#/"
                className="w-100 d-flex align-items-center p20px px-0 h70w70 px-0 d-flex align-items-center comman_action_icon pe-20pximp"
                onClick={() => {
                  if (leftPanelTab != "chat-agents") {
                    handleChangeTab("chat-agents" , "/");
                    dispatch(setSelectedGloablView("AichatView"));
                  }
                }}
              >
                  <div className="d-flex flex-column justify-content-center align-items-center w-70px">
                  <div className="p-20 py-0 text-center">
                  <div className="action_icon h30w30 without-bg">
                    <UserCircleIcon className="HW28" />
                  </div>
                  </div>
                  {showText ?  <div class={`color-white-80 c-font f-9  office-subtitle  hover-span fw-light hover-span line-height-1   text-truncate text-center w-100 pt-1 px-1`}>{_l("l_chat_modal")}</div> : <React.Fragment></React.Fragment>}
                    </div>
                  <div className="c-font f-15 hover-span">
                    {_l("l_chat_modal")}
                  </div>
              </a>
            </li>
            : <React.Fragment></React.Fragment>}
            {/* ----------------------------- chat Agents End ----------------------------- */}


            {/* ----------------------------- My Providers start ----------------------------- */}
          {["1","2"].includes(String(contact_role)) && userType === "contact" ?
            <li className={`office-box p-0 on-hover-active-toggle-img ${leftPanelTab == "my-providers" ? "active" : ""}`}>
              <a
                href="#/"
                className="w-100 d-flex align-items-center p20px px-0 h70w70 px-0 d-flex align-items-center comman_action_icon pe-20pximp"
                onClick={() => {
                  if (leftPanelTab != "my-providers") {
                    dispatch(setFilterSpaceProjectid(0));
                    handleChangeTab("my-providers" , "/providerlist");
                  }
                }}
              >
                <div className="d-flex align-items-center flex-grow-1">
                <div className="d-flex flex-column justify-content-center align-items-center  w-70px">
                <div className="p-20 py-0 text-center">
                  <div className="action_icon h30w30 without-bg">
                    <UserPlusIcon className="HW28" />
                  </div>
                  </div>
                  {showText ?  <div class={`color-white-80 c-font f-9  office-subtitle  hover-span fw-light hover-span line-height-1   text-truncate text-center w-100 pt-1 px-1`}>{_l("l_providers")}</div> : <React.Fragment></React.Fragment>}
                  </div>
                  <div className="c-font f-15 hover-span">
                  {_l("l_providers")}
                  </div>
                </div>
                  {
                      clientsTotalCounts && clientsTotalCounts.proviers > 0 ? <>
                      <div className="badge-green border-0 d-flex h18w18 my-auto rounded-circle">
                      <span className="m-auto ps-0 ">{clientsTotalCounts.proviers}</span>
                      </div>
                      </> : <></>
                    }
              </a>
            </li>
            : <React.Fragment></React.Fragment>}
            {/* ----------------------------- My Providers end ----------------------------- */}

            {/* ----------------------------- My Tenants start ----------------------------- */}
          {userType === "contact" && contact_role != 3 ?
            <li className={`office-box p-0 on-hover-active-toggle-img ${leftPanelTab == "my-tenants" ? "active" : ""}`}>
              <a
                href="#/"
                className="w-100 d-flex align-items-center p20px px-0 h70w70 px-0 d-flex align-items-center comman_action_icon pe-20pximp"
                onClick={() => {
                  if (leftPanelTab != "my-tenants") {
                    handleChangeTab("my-tenants" , "/");
                    dispatch(setSelectedGloablView("AichatView"));
                  }
                }}
              >
                  <div className="d-flex flex-column justify-content-center align-items-center w-70px">
                  <div className="p-20 py-0 text-center">
                  <div className="action_icon h30w30 without-bg">
                    <UsersIcon className="HW28" />
                  </div>
                  </div>
                  {showText?  <div class={`color-white-80 c-font f-9  office-subtitle  hover-span fw-light hover-span line-height-1   text-truncate text-center w-100 pt-1 px-1`}>{_l("l_contacts")}</div> : <React.Fragment></React.Fragment>}
                    </div>
                  <div className="d-flex justify-content-between flex-grow-1">
                  <div className="c-font f-15 hover-span">
                  {_l("l_contacts")}
                  </div>
                  {tenentsChatCounts > 0 ? (
                    <div className="pe-1 d-flex align-items-center">
                      <span className="badge-custom d-flex p-0 rounded-pill with-position-relative">
                        {tenentsChatCounts}
                      </span>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </a>
            </li>
            : <React.Fragment></React.Fragment>}
            {/* ----------------------------- My Tenants end ----------------------------- */}            

            {/* ----------------------------- Staff List start ----------------------------- */}
          {
           userType === "staff"?
            <li className={`office-box p-0 on-hover-active-toggle-img ${leftPanelTab == "my-staff" ? "active" : ""}`}>
              <a
                href="#/"
                className="w-100 d-flex align-items-center p20px px-0 h70w70 px-0 d-flex align-items-center comman_action_icon pe-20pximp"
                onClick={() => {
                  if (leftPanelTab != "my-staff") {
                    handleChangeTab("my-staff" , "/");
                  }
                }}
              >
                  <div className="d-flex flex-column justify-content-center align-items-center w-70px">
                  <div className="p-20 py-0 text-center">
                  <div className="action_icon h30w30 without-bg">
                    <UsersIcon className="HW28" />
                  </div>
                  </div>
                  {showText ?  <div class={`color-white-80 c-font f-9  office-subtitle  hover-span fw-light hover-span line-height-1   text-truncate text-center w-100 pt-1 px-1`}>{_l("l_staff")}</div> : <React.Fragment></React.Fragment>}
                  </div>
                  <div className="d-flex justify-content-between flex-grow-1">
                  <div className="c-font f-15 hover-span">
                  {_l("l_staff")}
                  </div>
                  {staffChatCounts > 0 ? (
                    <div className="pe-1 d-flex align-items-center">
                      <span className="badge-custom d-flex p-0 rounded-pill with-position-relative">
                        {staffChatCounts}
                      </span>
                    </div>
                  ) : (
                    <></>
                  )}
                  </div>
              </a>
            </li>
            : <React.Fragment></React.Fragment>
          }
            {/* ----------------------------- Staff List End ----------------------------- */}

            {/* ----------------------------- Leads start ----------------------------- */}
          {
           userType === "staff"?
            <li className={`office-box p-0 on-hover-active-toggle-img ${leftPanelTab == "my-leads" ? "active" : ""}`}>
              <a
                href="#/"
                className="w-100 d-flex align-items-center p20px px-0 h70w70 px-0 d-flex align-items-center comman_action_icon pe-20pximp"
                onClick={() => {
                  if (leftPanelTab != "my-leads") {
                    handleChangeTab("my-leads" , "/leads");
                  }
                }}
              >
                  <div className="d-flex flex-column justify-content-center align-items-center w-70px">
                  <div className="p-20 py-0 text-center">
                  <div className="action_icon h30w30 without-bg">
                    <PhoneDisconnectIcon className="HW28" />
                  </div>
                  </div>
                  {showText ?  <div class={`color-white-80 c-font f-9  office-subtitle  hover-span fw-light hover-span line-height-1   text-truncate text-center w-100 pt-1 px-1`}>{_l("l_leads")}</div> : <React.Fragment></React.Fragment>}
                  </div>
                  <div className="c-font f-15 hover-span">
                  {_l("l_leads")}
                  </div>
              </a>
            </li>
            : <React.Fragment></React.Fragment>
          }
            {/* ----------------------------- Leads End ----------------------------- */}

            {/* ----------------------------- Equipments start ----------------------------- */}
          {(["1","2"].includes(String(contact_role)) && userType === "contact") || userType === "staff" ?
            <li className={`office-box p-0 on-hover-active-toggle-img ${leftPanelTab == "equipments" ? "active" : ""}`}>
              <a
                href="#/"
                className="w-100 d-flex align-items-center p20px px-0 h70w70 px-0 d-flex align-items-center comman_action_icon pe-20pximp"
                onClick={() => {
                  if (leftPanelTab != "equipments") {
                    handleChangeTab("equipments" , "/equipment-table");
                  }
                }}
              >
                  <div className="d-flex flex-column justify-content-center align-items-center w-70px">
                  <div className="p-20 py-0 text-center">
                  <div className="action_icon h30w30 without-bg">
                    <WrenchIcon className="HW28" />
                  </div>
                  </div>
                  {showText ?  <div class={`color-white-80 c-font f-9  office-subtitle  hover-span fw-light hover-span line-height-1   text-truncate text-center w-100 pt-1 px-1`}>{_l("l_equipments")}</div> : <React.Fragment></React.Fragment>}
                    </div>
                  <div className="c-font f-15 hover-span">
                  {_l("l_equipments")}
                  </div>
              </a>
            </li>
            : <React.Fragment></React.Fragment>}
            {/* ----------------------------- Equipments end ----------------------------- */}

          </ul>
          <div className="lower-part mt-auto border-top pt-0">
            <ul className="office-list-main">
            {/* ----------------------------- My Email start ----------------------------- */}
            {contact_role != 3 ? <li className={`office-box p-0 on-hover-active-toggle-img ${leftPanelTab == "my-email" ? "active" : ""}`}>
              <a
                href="#/"
                className="w-100 d-flex align-items-center p20px px-0 h70w70 px-0 d-flex align-items-center comman_action_icon pe-20pximp"
                onClick={() => {
                  if (leftPanelTab != "my-email") {
                    dispatch(emailTokenExpired(true))
                    handleChangeTab("my-email" , "/MyEmail");
                  }
                }}
              >
                  <div className="d-flex flex-column justify-content-center align-items-center w-70px">
                  <div className="p-20 py-0 text-center">
                  <div className="action_icon h30w30 without-bg">
                    <EnvelopeIcon className="HW28" />
                  </div>
                  </div>
                  {showText ?  <div class={`color-white-80 c-font f-9  office-subtitle  hover-span fw-light hover-span line-height-1   text-truncate text-center w-100 pt-1 px-1`}>{_l("l_email")}</div> : <React.Fragment></React.Fragment>}
                  </div>
                  <div className="c-font f-15 hover-span">{_l("l_email")}</div>
              </a>
            </li>: <React.Fragment></React.Fragment> }
            {/* ----------------------------- My Email End ----------------------------- */}

            {/* ----------------------------- Drive Start ----------------------------- */}
            {contact_role != 3 ?
            <li className={`office-box p-0 on-hover-active-toggle-img ${leftPanelTab == "my-drive" ? "active" : ""}`}>
              <a
                href="#/"
                className="w-100 d-flex align-items-center p20px px-0 h70w70 px-0 d-flex align-items-center comman_action_icon pe-20pximp"
                onClick={() => {
                  if (leftPanelTab != "my-drive") {
                    localStorage.setItem("lastLeftPanelTab", leftPanelTab)
                    handleChangeTab("my-drive", "/my-drive");
                  }
                }}
              >
                <div className="d-flex align-items-center flex-grow-1">
                  <div className="d-flex flex-column justify-content-center align-items-center w-70px">
                  <div className="p-20 py-0 text-center">
                  <div className="action_icon h30w30 without-bg">
                    <HardDrivesIcon className="HW28" />
                  </div>
                  </div>
                  {showText ?  <div class={`color-white-80 c-font f-9  office-subtitle  hover-span fw-light hover-span line-height-1   text-truncate text-center w-100 pt-1 px-1`}>{_l("l_drive")}</div> : <React.Fragment></React.Fragment>}
                    </div>
                  <div className="c-font f-15 hover-span">{_l('l_drive')}</div>
                </div>
                {
                      clientsTotalCounts && clientsTotalCounts.drive && (Number(clientsTotalCounts.drive.subscription) > 0 || Number(clientsTotalCounts.drive.invoice) > 0 || Number(clientsTotalCounts.drive.estimate) > 0 || Number(clientsTotalCounts.drive.creit_note > 0) || Number(clientsTotalCounts.drive.documents > 0) ) ? <>
                      <div className="badge-green border-0 d-flex h18w18 my-auto rounded-circle">
                      <span className="m-auto ps-0 ">{(Number(clientsTotalCounts.drive.subscription) + Number(clientsTotalCounts.drive.invoice) + Number(clientsTotalCounts.drive.estimate) + Number(clientsTotalCounts.drive.creit_note) + Number(clientsTotalCounts.drive.documents))}</span>
                      </div>
                      </> : <></>
                    }
              </a>
            </li>
            : <React.Fragment></React.Fragment>}
            {/* ----------------------------- Drive End ----------------------------- */}


            {/* ----------------------------- Calendar Start ----------------------------- */}
            {contact_role != 3 ?
            <li className={`office-box p-0 on-hover-active-toggle-img ${leftPanelTab == "my-calendar" ? "active" : ""}`}>
              <a
                href="#/"
                className="w-100 d-flex align-items-center p20px px-0 h70w70 px-0 d-flex align-items-center comman_action_icon pe-20pximp"
                onClick={() => {
                  if (leftPanelTab != "my-calendar") {
                    handleChangeTab("my-calendar","/");
                  }
                }}
              >
                  <div className="d-flex flex-column justify-content-center align-items-center w-70px">
                  <div className="p-20 py-0 text-center">
                  <div className="action_icon h30w30 without-bg">
                    <CalendarBlankIcon className="HW28" />
                  </div>
                  </div>
                  {showText ?  <div class={`color-white-80 c-font f-9  office-subtitle  hover-span fw-light hover-span line-height-1   text-truncate text-center w-100 pt-1 px-1`}>{_l("l_calendar")}</div> : <React.Fragment></React.Fragment>}
                    
                    </div>
                  <div className="c-font f-15 hover-span">{_l('l_calendar')}</div>
              </a>
            </li>
            : <React.Fragment></React.Fragment>}
            {/* ----------------------------- Calendar End ----------------------------- */}
            </ul>
              <div className="company-logo-box position-relative d-flex align-items-center justify-content-between comman-round-box pe-10px new_user_profile_bottom hovertextgreen on-hover-active-toggle-img text-on-hover-active-green">
                <a
                  href="#/"
                  className="d-flex align-items-center w-100 h70w70"
                  onClick={() => {
                    setshowMyProfile(!showMyProfile);
                  }}
                >
                  <div
                    className={`h32w32 comman-round-box rounded-circle mx20px ${
                      userType == "staff"
                        ? "staff_user"
                        : userType == "operator"
                        ? "operator_user"
                        : "contact_user"
                    } `}
                  >
                    <div
                      className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                      style={{
                        backgroundImage: `url(${localStorage.getItem(
                          "contact_image"
                        )})`,
                      }}
                    ></div>
                  </div>
                  <div className=" d-flex flex-column">
                    <div className="c-font f-15 fw-semibold hover-span">
                      {localStorage.getItem("full_name")}
                    </div>
                    <div className="c-font f-10 color-white-60 hover-span">
                      {_l("l_my_profile")}
                    </div>
                  </div>
                </a>
                <div className="comman_action_icon">
                <a href="#/" className="h32w32 action_icon with_bg"
                  onClick={()=>{
                    updateIDB()
                  }}>
                    <ArrowsClockwiseIcon className="HW18" />
                  </a>
                  </div>
              </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LeftPanelMain;
