import { Address } from './Address';
import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setOnboardingStepData } from "../../../actions/onboarding-actions/onboarding-actions";
import { _l, hideLeftPanelNew, removeExpandedformIntro, showError, updateSpaceDetailInLocalStorage } from "../../../hooks/utilities";
import OnboardingFooter from "../OnboardingFooter";
import {
  showConfirmation,
  showMessage,
  toastCloseAction,
} from "../../../actions/messages";
import CustomerServices from "../../../services/customer-services";
import {
  getFloorDetails,
  setFloorPlanning,
  updateFloorSetting,
} from "../../../actions/benchmark-actions/benchmark-actions";
import SpaceSummary from "./SpaceSummary";
import { FloorListing } from "./FloorListing";
import { FloorSetup } from "./FloorSetup";
import { OnboardingFlowFinalStep } from "./OnboardingFlowFinalStep";
import { useNavigate, useSearchParams } from "react-router-dom";
import { DEFAULT_ADDRESS, DEFAULT_LAT, DEFAULT_LNG, DEFAULT_REGION_ID, DEFAULT_REGION_NAME, SPACE_TYPE_OPTIONS } from "../../../constants/constants";
import OnboardingHeader from "../OnboardingHeader";
import { getCustomerSpaces, setProject } from "../../../actions/customer";
import Geocode from "react-geocode";
import customerServices from '../../../services/customer-services';
import leaseServices from '../../../services/lease-services';
import { fetchMessages, getGroupDetails } from '../../../actions/chat';
import chatServices from '../../../services/chat-services';
import moment from 'moment';
import ReactHtmlParser from "react-html-parser";
import LeaseContractSetup from './LeaseContractSetup';
import AddSubContractModal from '../../Modals/AddSubContractModal';
import BuildingsIcon from '../../../assets/icons/BuildingsIcon';
import CheckIcon from '../../../assets/icons/CheckIcon';
import CubeIcon from '../../../assets/icons/CubeIcon';
import DeskIcon from '../../../assets/icons/DeskIcon';
Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAP_SECRET);
Geocode.setLanguage("fr");
Geocode.setRegion("fr");
const SpaceDetails = ({ 
  spaceBasicDetails, 
  setOnboardingStep,
  setSpaceBasicDetails
 }) => {
  const dispatch = useDispatch();
  const [currentStep, setCurrentStep] = useState(localStorage.getItem("leaseDetailType") == "lease-contract" ? "lease-contract" : "space-type");
  const [regionList, setRegionList] = useState([]);
  const [coOrdinates, setCoOrdinates] = useState({
    lat: 48.83888,
    lng: 2.38062,
  });
  const [spaceDetail, setSpaceDetail] = useState({
    address: DEFAULT_ADDRESS,
    title: "",
    region_id: DEFAULT_REGION_ID,
    office_id: "",
    superfice: 1000,
    number_of_desk: 10,
    country_id: "",
    is_edit: "",
    logo: "",
    is_virtual_space: -1,
    space_type: 1,
    space_logo: "",
    floor_count: 1,
    space_id: 0,
    id: 0,
    floors: [],
    region_name: DEFAULT_REGION_NAME,
    lat: DEFAULT_LAT,
    lng: DEFAULT_LNG,
    place_id :"",
    project_id: "",
    lease_id : 0
  });
  const [floorDetail, setFloorDetail] = useState();
  const [addNewFloorLoader, setAddNewFloorLoader] = useState(false);
  const [loadingNextStep, setLoadingNextStep] = useState(false);
  const [headerTitle, setHeaderTitle] = useState("");
  const [headerDescription, setHeaderDescription] = useState("");
  const { floorDetails } = useSelector((state) => state.benchmarkReducer);
  const language = useSelector((state) => state.customer.get_language);
  const chatPlanTittle = useSelector((state) => state.customer.chatPlanTittle);
  const {messageList} = useSelector((state) => state.chat);
  const [searchParams, setSearchParams] = useSearchParams();
  const [lastMessage, setLastMessage] = useState("");
  const [toggleSubContactModal, setToggleSubContactModal] = useState(false)
  // ---------------------------------------------------------------- lease states start ----------------------------------------------------------------
;
  const [leaseData, setLeaseData] = useState({
    leaseStartDate : "",
    leaseEndDate : "",
    noticeDates : [{ notice_date: ""}],
    isRentingorOwning : "renting",
    amount : "",
    isMonthlyOrAnnualli : "monthly",
    termandCondition : "",
    selectedServices : [],
    selectedEquipments : [],
    selectedResidents : [],
    space_type : spaceDetail.space_type
  })
  const [leaseDocuments, setLeaseDocuments] = useState([]);
  const [otherDocuments, setOtherDocuments] = useState([]);
  const [topics, setTopics] = useState();
  const [chartData, setChartData] = useState([]);
  const [documents, setDocuments] = useState([])
  // ---------------------------------------------------------------- lease states end----------------------------------------------------------------
  let space_setup_pending = localStorage.getItem("space_setup_pending")
  let is_registered = localStorage.getItem("is_registered")
  const navigate = useNavigate();
  const stepArray = [
    "space-type",
    "location",
    "space-category",
    "lease-contract",
    "floor-listing",
    "flow-complete",
  ];
  const contact_role = localStorage.getItem("contact_role");
  let currentFloorList = floorDetails.floor.slice();
  const clientId = localStorage.getItem("client_id");
  const contactId = localStorage.getItem("contact_id");
  const validateStep = () => {
    switch (currentStep) {
      case "space-type":
        if (spaceDetail.is_virtual_space < 0) {
          dispatch(
            showMessage(
              "unsucess",
              _l("l_error"),
              _l("l_please_select_space_type")
            )
          );
          return false;
        }
        break;
      case "location":
        if (spaceDetail.region_id < 0) {
          dispatch(
            showMessage(
              "unsucess",
              _l("l_error"),
              _l("l_please_select_the_region")
            )
          );
          return false;
        } else if (!spaceDetail.address || !spaceDetail.address.trim().length) {
          dispatch(
            showMessage(
              "unsucess",
              _l("l_error"),
              _l("l_please_add_space_address")
            )
          );
          return false;
        }
        break;
      case "lease-contract":
        if (leaseData.leaseStartDate == '') {
          dispatch(
            showMessage(
              "unsucess",
              _l("l_error"),
              _l("l_please_select_the_lease_start_date")
            )
          );
          return false;
        } else if (leaseData.leaseEndDate == '') {
          dispatch(
            showMessage(
              "unsucess",
              _l("l_error"),
              _l("l_please_select_the_lease_end_date")
            )
          );
          return false;
        } else if (leaseData.noticeDates && leaseData.noticeDates.filter((date) => date.notice_date != '').length == 0) {
          dispatch(
            showMessage(
              "unsucess",
              _l("l_error"),
              _l("l_please_select_the_notice_lease_end_date")
            )
          );
          return false;
        } else if (leaseData.amount == '') {
          dispatch(
            showMessage(
              "unsucess",
              _l("l_error"),
              _l("l_please_enter_amount")
            )
          );
          return false;
        } else if (leaseDocuments && leaseDocuments.length == 0) {
          dispatch(
            showMessage(
              "unsucess",
              _l("l_error"),
              _l("l_please_upload_lease_document")
            )
          );
          return false;
        }
        break;
      case "floor-listing":
        if (
          !spaceDetail.title &&
          !spaceDetail.title.trim().length &&
          !spaceDetail.address &&
          !spaceDetail.address.trim().length
        ) {
          dispatch(
            showMessage(
              "unsucess",
              _l("l_error"),
              _l("l_please_enter_space_title")
            )
          );
          return false;
        }
        if (spaceDetail.superfice <= 0) {
          dispatch(
            showMessage(
              "unsucess",
              _l("l_error"),
              _l("l_please_enter_superficy")
            )
          );
          return false;
        } else if (spaceDetail.number_of_desk <= 0) {
          dispatch(
            showMessage(
              "unsucess",
              _l("l_error"),
              _l("l_please_enter_number_of_desks")
            )
          );
          return false;
        } else if (spaceDetail.floor_count <= 0) {
          dispatch(
            showMessage(
              "unsucess",
              _l("l_error"),
              _l("l_please_enter_number_of_floors")
            )
          );
          return false;
        }
        break;
      default:
        break;
    }
    return true;
  };
  const getSpaceDetails = async (project_id) => {
    try {
      const response = await CustomerServices.getSpaceDetails(project_id);
      if (response.status && response.data && Object.keys(response.data)) {
        let spaceData = response.data;
          const spaceType = SPACE_TYPE_OPTIONS.find(r => r.value == spaceData.space_type);
          setSpaceBasicDetails({
            address: spaceData.postal_address,
            title: spaceData.office_title,
            region_id: spaceData.region,
            office_id: 0,
            superfice: spaceData.area,
            number_of_desk: spaceData.number_of_desk,
            country_id: 0,
            is_edit: 1,
            id: spaceData.id,
            logo: spaceData.logo,
            is_virtual_space: 0,
            space_type: spaceType ? spaceType.value : 0,
            space_logo: spaceData.logo,
            floor_count: spaceData.floors && spaceData.floors.length ? spaceData.floors.length : 1,
            space_id: spaceData.id,
            project_id: spaceData.project_id,
            floors: spaceData.floors || [],
            ai_api_call_for_floor_data : spaceData.ai_api_call_for_floor_data,
            lease_id : spaceData.lease_id
          });
          dispatch(getFloorDetails(project_id, 1));
          setOnboardingStep("space-details");
      } 
    }catch (e) {
      console.error(e);
    }
    finally {
    }
  };
  /**
   *
   * @param {*} step : name of step
   * @param {*} direction : Direction -> 1 for next, -1 for previous
   */
  const changeNextStep = async (step, direction, skipButtonClicked = false) => {
    let indexOfCurrentStep = stepArray.findIndex((x) => x == step);
    if (skipButtonClicked) {
      setCurrentStep(stepArray[indexOfCurrentStep + 1]);
      return
    }
    if (indexOfCurrentStep === -1) {
      if (direction === -1) {
        setCurrentStep("floor-listing");
      } else {
        setLoadingNextStep(true);
        saveFloorSettings(floorDetail);
        navigate(`/explore-features?space=${spaceDetail.project_id}`);
      }
    } else {
      if (direction > 0) {
        if(!validateStep())
        {
          return false;
        }
        setLoadingNextStep(true);
        if ([3].includes(indexOfCurrentStep)) {   
          try {
             const response = await leaseServices.addeditProjectLease(
              spaceDetail.project_id > 0 ? spaceDetail.project_id : 0,
              leaseData,
              leaseDocuments,
              otherDocuments,
              spaceDetail.lease_id > 0 ? spaceDetail.lease_id : 0
             )
             if (response.status == 1) {
              setCurrentStep(stepArray[indexOfCurrentStep + 1]);
              setLoadingNextStep(false)
             }
          } catch (error) {
            showError("l_something_went_wrong");
            console.error(error);
          }
        } else if ([2, 4].includes(indexOfCurrentStep)) {
          const projectId = Number(searchParams.get("space"));
          try {
            const response = await CustomerServices.addEditSpaces(
              spaceDetail.address,
              spaceDetail.title.trim() || spaceDetail.address,
              spaceDetail.region_id,
              spaceDetail.superfice,
              spaceDetail.number_of_desk,
              spaceDetail.id,
              0,
              { value: spaceDetail.space_type },
              [],
              spaceDetail.id,
              0,
              0,
              0,
              spaceDetail.logo || spaceDetail.space_logo,
              [],
              spaceDetail.floor_count,
              spaceDetail.region_name,
              coOrdinates.lat,
              coOrdinates.lng,
              spaceDetail.place_id
            );
            if (response.status == 1) {
              getSpaceDetails(response.data.project_id)
              updateSpaceDetailInLocalStorage(response.data.project_id, {office_title: spaceDetail.title, postal_address: spaceDetail.address, number_of_desk: spaceDetail.number_of_desk, superficies: spaceDetail.superfice, floor_count: spaceDetail.floor_count});
              dispatch(setProject(response.data.project_id));
              if (projectId) {
                dispatch(getFloorDetails(projectId, 1));
                setSpaceDetail({
                  ...spaceDetail,
                  id: response.data.id,
                });
              } else {
                setSearchParams({ space: response.data.project_id });
                dispatch(getCustomerSpaces(clientId, contactId));
                if (!spaceDetail.project_id) {
                  setSpaceDetail({
                    ...spaceDetail,
                    project_id: response.data.project_id,
                    id: response.data.id,
                  });
                  dispatch(getFloorDetails(response.data.project_id, 1));
                }
              }
              setCurrentStep(stepArray[indexOfCurrentStep + 1]);
            }
            else {
              showError(_l(response.message));
            }
          } catch (e) {
            showError("l_something_went_wrong");
            console.error(e);
          }
        } else {
          setCurrentStep(stepArray[indexOfCurrentStep + 1]);
        }
      } else if (direction < 0 && indexOfCurrentStep != 0) {
        setCurrentStep(stepArray[indexOfCurrentStep - 1]);
      } else if (indexOfCurrentStep === 0) {
        if(spaceDetail.project_id)
        {
          navigate(`/explore-features?space=${spaceDetail.project_id}`);
        }
        else {
          navigate(`/premiumsubscription`);
        }
      }
      setLoadingNextStep(false);
      hideLeftPanelNew();
    }
  };
  const addEditFloor = async (direction, changeStep = true) => {
    if (direction == -1) {
      if (floorDetails.floor.length > 1 && contact_role == 1) {
        let lastFloor = currentFloorList.pop();
        dispatch(
          showConfirmation(
            _l("l_are_you_sure"),
            `${_l("l_delete_floor_confirmation")} ${lastFloor.title} ?`,
            _l("l_delete"),
            _l("l_no"),
            () => {},
            async () => {
              if (lastFloor.id > 0) {
                try {
                  const response = await CustomerServices.deleteFloorItems(
                    spaceDetail.project_id,
                    "floor",
                    [lastFloor.id],
                    "floor_id"
                  );
                  if (response.status == 1) {
                    dispatch(
                      setFloorPlanning(
                        response.data.type_of_space,
                        response.data.floor
                      )
                    );
                    setSpaceDetail({
                      ...spaceDetail,
                      floor_count: response.data.floor.length,
                    });
                    if (changeStep) {
                      setCurrentStep("floor-listing");
                    }
                  } else {
                    showError(response.message);
                  }
                } catch (e) {
                  console.error(e);
                } finally {
                  dispatch(toastCloseAction());
                }
              }
            }
          )
        );
      }
    } else if (!addNewFloorLoader) {
      setAddNewFloorLoader(true);
      try {
        const response = await CustomerServices.addFloorItem(
          spaceDetail.project_id,
          1,
          "",
          "floor",
          0,
          currentFloorList.length
        );
        if (response.status) {
          dispatch(
            setFloorPlanning(response.data.type_of_space, response.data.floor)
          );
          setSpaceDetail({
            ...spaceDetail,
            floor_count: response.data.floor.length,
          });
          setCurrentStep("floor-listing");
        }
      } catch (e) {
        console.error(e);
      } finally {
        setAddNewFloorLoader(false);
      }
    }
  };
  const selectFloorForSetup = (floor) => {
    setCurrentStep("floor-setup");
    setFloorDetail({
      ...floor,
    });
  };
  const validFloorSettings = (floorData) => {
    let validDetails = true;
    let totalFloorSuperficy = 0;
    let totalFloorDesks = 0;
    currentFloorList.forEach((floor) => {
      if (floor.id != floorData.id) {
        if (floor.superficy) {
          totalFloorSuperficy += Number(floor.superficy);
        }
        if (floor.number_of_desk) {
          totalFloorDesks += Number(floor.number_of_desk);
        }
      }
    });
    if (totalFloorSuperficy > spaceDetail.superfice) {
      validDetails = false;
      return validDetails;
    }
    if (totalFloorDesks > spaceDetail.number_of_desk) {
      validDetails = false;
      return validDetails;
    }
    return validDetails;
  };
  const saveFloorSettings = async (floorData) => {
    if (validFloorSettings(floorData)) {
      try {
        const response = await CustomerServices.addUpdateFloorSettings(
          floorData,
          [],
          spaceDetail.project_id
        );
        if (response.status) {
          let targetFloor = response.data.floor.find(
            (x) =>
              x.title &&
              x.title.trim() &&
              x.title.trim().toLowerCase() ==
                floorData.title.trim().toLowerCase()
          );
          targetFloor = {
            ...floorData,
            id: targetFloor.id,
          };
          dispatch(
            updateFloorSetting(targetFloor, response.data.type_of_space)
          );
        }
      } catch (e) {
        console.error(e);
      } finally {
        setLoadingNextStep(false);
      }
    } else {
      setLoadingNextStep(false);
    }
  };
  const nextButtonText = () => {
    let buttonText = _l("l_next");
    if (["flow-complete"].includes(currentStep)) {
      buttonText = "";
    }
    return currentStep === "floor-setup" ? _l("l_save") : buttonText;
  };
  useEffect(() => {
    dispatch(
      setOnboardingStepData({
        step: "space-onboarding",
      })
    );
    const getPendingSpaceDetails = async(project_id) => {
      try {
        const response = await CustomerServices.getSpaceDetails(project_id);
        if (response.status && response.data && Object.keys(response.data)) {
          let spaceData = response.data;
          setSpaceDetail({
            address: spaceData.postal_address,
            title: spaceData.office_title,
            region_id: spaceData.region,
            office_id: 0,
            superfice: spaceData.area,
            number_of_desk: spaceData.number_of_desk,
            country_id: 0,
            is_edit: 1,
            id: spaceData.id,
            logo: spaceData.logo,
            is_virtual_space: 0,
            space_logo: spaceData.logo,
            floor_count: spaceData.floors && spaceData.floors.length ? spaceData.floors.length : 1,
            space_id: spaceData.id,
            project_id: spaceData.project_id,
            floors: spaceData.floors || [],
            place_id : (spaceData.place_id && spaceData.place_id != undefined) ? spaceData.place_id : ''
          });
        }
        else {
          showError(response.message);
        }
      }
      catch(e)
      {
        showError("l_something_went_wrong");
      }
    };
    CustomerServices.getRegionList()
      .then((res) => {
        if (res.status == 1 && res.data) {
          setRegionList(
            res.data.map((data) => {
              return {
                label: data.name,
                value: data.id,
              };
            })
          );
        }
      })
      .catch((e) => {
        console.error(e);
      });
    let element = document.getElementById("left-panel-main");
    if (element) {
      element.classList.remove("d-none");
    }
    const projectId = Number(searchParams.get("space"));
    if (!projectId) {
      dispatch(setProject(0));
      localStorage.setItem("selectedOffice", 0);
    }
    if (JSON.parse(space_setup_pending) == 1 && projectId > 0) {    
      let project_Id= localStorage.getItem("chat_project_id");
      let user_type = localStorage.getItem("user_type");
      handleCreateChatGroup(project_Id,"myr_support",user_type,localStorage.getItem("staff_id"));
      getPendingSpaceDetails(project_Id);
    }
  }, []);
  useEffect(() => {
    if (spaceBasicDetails && Object.keys(spaceBasicDetails).length) {
      Geocode.fromAddress(spaceBasicDetails.address).then(
        (response) => {
          const { lat, lng } = response.results[0].geometry.location;
          setCoOrdinates({
            lat: lat,
            lng: lng,
          });
        },
        (error) => {
          console.log(error);
        }
      );
      setSpaceDetail(spaceBasicDetails);
    }
  }, [spaceBasicDetails]);
  useEffect(() => {
    let spaces = localStorage.getItem("spaces");
    const projectId = Number(searchParams.get("space"));
    if (
      spaces &&
      spaces.length > 0 && 
      !projectId &&
      (spaceDetail.title.trim().length || spaceDetail.address.trim().length)
    ) {
      spaces = JSON.parse(spaces);
      let newSpaceDetail = {
        ...spaceDetail,
        id: -1,
        project_status: 1,
        office_title: spaceDetail.title || spaceDetail.address,
        project_id: -1,
        members: []
      };
      if (spaces.length) {
        let newSpaceIndex = spaces.findIndex(
          (x) =>
            (x.office_title &&
              x.office_title.length &&
              (x.office_title.trim().toLowerCase() ===
                spaceDetail.title.trim().toLowerCase() ||
                x.office_title.trim().toLowerCase() ===
                  spaceDetail.address.trim().toLowerCase())) ||
            (x.id === -1 && x.project_id === -1)
        );
        if (newSpaceIndex > -1) {
          spaces[newSpaceIndex] = {
            ...spaces[newSpaceIndex],
            ...newSpaceDetail,
          };
        } else {
          spaces.push({ ...newSpaceDetail });
        }
      } else {
        spaces.push({ ...newSpaceDetail });
      }
      dispatch(setProject(-1));
    }
    let title = "";
    let description = "";
    switch(currentStep)
    {
      case "space-type":
        title = _l("l_space_title_1");
        description = _l("l_space_subtitle_1");
        break;
      case "location":
        title = _l("l_space_title_2");
        description = _l("l_space_subtitle_2");
        break;
      case "space-category":
        title = _l("l_space_title_3");
        description = _l("l_basic_details_subtitle");
        break;
      case "lease-contract":
        title = spaceBasicDetails && spaceBasicDetails.title ? spaceBasicDetails.title + _l("l_space_title_3_new") : "space " +_l("l_space_title_3_new");
        description = _l("l_basic_details_subtitle");
        break;
      case "floor-listing":
        title = _l("l_space_title_4");
        description = _l("l_space_subtitle_4");
        break;
      default:
        break;
    }
    setHeaderDescription(description);
    setHeaderTitle(title);
  }, [currentStep, language, spaceBasicDetails]);
  useEffect(() => {
    if(messageList && messageList.length > 0){
      setLastMessage(messageList[messageList.length - 1]);
    }
  }, [JSON.stringify(messageList)])
  useEffect(() => {
    if (spaceDetail && spaceDetail.project_id && currentStep == "lease-contract") {
      leaseServices.getLeaseTopics(spaceDetail.project_id, spaceDetail.lease_id).then((res)=>{
        if (res && res.status) {
          setTopics(res.data.topics)
        }
      })
      leaseServices.getLeaseChartData(spaceDetail.project_id, spaceDetail.lease_id).then((res)=>{
        if (res && res.status) {
          setChartData(res.data)
        }
      })
      leaseServices.getProjectLease(spaceDetail.project_id ,spaceDetail.lease_id).then((res)=>{
        if (res && res.status && res.data && res.data.detail) {
          localStorage.removeItem("leaseDetailType");
          let details = res.data.detail
          let documents = res.data.documents && res.data.documents.length > 0 ? res.data.documents : []
          setLeaseData({
            ...leaseData,
              leaseStartDate : new Date(details.start_date),
              leaseEndDate : new Date(details.end_date),
              noticeDates : details.notice_date_detail,
              isRentingorOwning : res.data.lease_type == 1 ? "renting" : "owning",
              amount : details.amount,
              isMonthlyOrAnnualli : details.payment_type == 1 ? "monthly" : "annually",
              termandCondition : details.termination_condition,
              space_type : spaceDetail.space_type,
          })
          setDocuments(documents)
          setLeaseDocuments(documents.filter((file) => file.is_contract_file == 1));
          setOtherDocuments(documents.filter((file) => file.is_contract_file == 0));
        }
      })
    }
  }, [spaceDetail, currentStep])
  const handleCreateChatGroup =(projectId,main_key,main_sub_key,filter_key)=>{
    customerServices.createChatGroup(projectId,main_key,main_sub_key,filter_key).then((res)=>{
      if(res.status && res.data){
        let groupID = res.data._id
        localStorage.setItem("activeGroupId", groupID);
        chatServices.getGroupDetails( localStorage.getItem("chatUserId"), groupID).then((res) => {
          if (res.success) {
            localStorage.setItem("getGroupDetails", JSON.stringify(res.result))
            dispatch(getGroupDetails(res.result))
            dispatch(fetchMessages( localStorage.getItem("chatUserId"), groupID,0,()=>{}));
          }else{
            dispatch(showMessage("unsucess", _l("l_error"),res.message));
          }
        });
      }
    })
  }
  useEffect(() => {
    removeExpandedformIntro() 
  })
  return (
    <>
    <div className={`onboarding-wrapper-main d-flex m-auto h-100 flex-column onboarding-with-bg ${["floor-setup"].includes(currentStep) ? "with-modified-width" : ""} `}>
      <OnboardingHeader 
        title={headerTitle}
        description={headerDescription}
        backBtnHandler={() => {
          changeNextStep(currentStep, -1);
        }}
        nextBtnHandler={() => {
          changeNextStep(currentStep, 1);
        }}
        backButtonText={
          currentStep === "floor-setup" ? _l("l_cancel") : _l("l_previous")
        }
        skipButtonHandler={() => changeNextStep(currentStep, 1, true)}
        hideBackButton={["flow-complete"].includes(currentStep) || ((["space-type"].includes(currentStep) && JSON.parse(space_setup_pending) == 1) || (["space-type"].includes(currentStep) &&is_registered == 1))}
        nextButtonText={nextButtonText()}
        loadingNextStep={loadingNextStep}
        showContractbtn={["floor-listing","lease-contract"].includes(currentStep) }
        skipButtonText={[ "lease-contract"].includes(currentStep) ? _l("l_skip") : ""}
          subAddContract = {() => {
          setToggleSubContactModal(true);
        }}
        from={currentStep == "location" ? "space-details" : "lease-setup"}
        spaceRecords={spaceBasicDetails && spaceBasicDetails.space_records ? spaceBasicDetails.space_records : []}
      />
      <div className={`onboarding-content-scroll-wrapper d-flex flex-column pb-4 ${currentStep !== "floor-setup" ? "comman-main-box" : ""}`}>
      {["floor-listing", "floor-summary"].includes(currentStep) ? (
          <SpaceSummary
            currentStep={currentStep}
            summaryData={spaceDetail}
            addEditFloor={addEditFloor}
            spaceDetail={spaceDetail}
            setSpaceDetail={setSpaceDetail}
            showContractbtn={["floor-listing","lease-contract"].includes(currentStep) }
            subAddContract = {() => {
              setToggleSubContactModal(true);
            }}
          />
        ) : (
          <React.Fragment></React.Fragment>
        )}
        {currentStep == "space-type" ? (
          <div className="d-flex justify-content-center flex-wrap ">
            <div className="mx-3">
              <div className="space-select-box select-input-cox radius_5 bg-transparent">
                <input
                  className="bottom-0 end-0 form-check-input h_230px comman-input-check-box position-absolute start-0 top-0 w-100 bg-transparent"
                  name="group1"
                  type="radio"
                  value={0}
                  checked={spaceDetail.is_virtual_space == 0}
                  onChange={(e) => {
                    setSpaceDetail({
                      ...spaceDetail,
                      is_virtual_space: e.target.value,
                    });
                    setCurrentStep("location");
                  }}
                />
                <a href="#/" className="check-image">
                  <div className="check-image-box">
                    <CheckIcon className="HW14 color-white-03-solid" />
                  </div>
                </a>
                <div className=''>
                <div className="d-flex bg-white-03 m-auto h_230px radius_3">
                  <BuildingsIcon className="h60w60 stroke-width-3px" />
                </div>
              </div>
              <div className="text-center p-10">
                <div className="text-center c-font f-16 p-10 px-0 fw-semibold color-white-80">
                  {_l("l_physical_space")}
                </div>
                <div className="text-center c-font f-14 color-white-60 fw-light">
                  {_l("l_physical_space_description")}
                </div>
              </div>
              </div>
            </div>
            <div className="mx-3">
              <div className="space-select-box select-input-cox radius_5 for-disabled bg-transparent">
                <input
                  className="bottom-0 end-0 form-check-input h_230px comman-input-check-box position-absolute start-0 top-0 w-100 bg-transparent"
                  name="group1"
                  type="radio"
                  checked={spaceDetail.is_virtual_space == 1}
                  disabled
                />
                <a href="#/" className="check-image">
                  <div className="check-image-box">
                    <CheckIcon className="HW14 color-white-03-solid" />
                </div>
                </a>
                <div className=''>
                <div className="d-flex bg-white-03 m-auto h_230px radius_3">
                  <CubeIcon className="h60w60 stroke-width-3px" />
                </div>
              </div>
              <div className="text-center p-10">
                <div className="text-center c-font f-16 p-10 px-0 fw-semibold color-white-80">
                  {_l("l_virtual_space")}
                </div>
                <div className="text-center c-font f-14 color-white-60 fw-light">
                  {_l("l_virtual_space_description")}
                </div>
              </div>
              </div>
            </div>
          </div>
        ) : currentStep == "location" ? (
         <Address setSpaceDetail={setSpaceDetail} spaceDetail={spaceDetail} regionList={regionList} setRegionList={setRegionList} coOrdinates={coOrdinates} setCoOrdinates={setCoOrdinates} />
        ) : currentStep == "space-category" ? (
          <>
               <div className="row g-4">
                 {SPACE_TYPE_OPTIONS.length > 0 &&
                   SPACE_TYPE_OPTIONS.map((item, index) => {
                     return (
                       <Col xs={4} key={index}>
                         <a href="#/" className="select-input-cox p-20 w-100 d-block z-index-3 radius_5">
                           <input
                             className="bottom-0 end-0 form-check-input h-100 position-absolute start-0 top-0 w-100 comman-input-check-box"
                             name="group1"
                             value={item.value}
                             checked={item.value == spaceDetail.space_type}
                             type="radio"
                             onChange={(e) =>
                               setSpaceDetail({
                                 ...spaceDetail,
                                 space_type: e.target.value,
                               })
                             }
                           />
                           <div className="check-image">
                           <div className="check-image-box">
                             <CheckIcon className="HW14 strokeblack" />
                           </div>
                           </div>
                           <div class="align-items-center d-flex z-2 position-relative">
                             <div class="h25w25">
                               <DeskIcon
                                 className="HW22"
                               />
                             </div>
                             <div class="c-font f-14 text-truncate color-white-80 ps-2">
                               {item.label}
                             </div>
                           </div>
                         </a>
                       </Col>
                     );
                   })}
               </div>
               <div className='mt-15px'>
               </div>
          </>
        ) : currentStep == "lease-contract" ? (
          <LeaseContractSetup
          leaseData={leaseData}
          setLeaseData={setLeaseData}
          leaseDocuments={leaseDocuments}
          setLeaseDocuments={setLeaseDocuments}
          otherDocuments={otherDocuments}
          setOtherDocuments={setOtherDocuments}
          spaceDetail={spaceDetail}
          topics={topics}
          chartData={chartData}
          documents={documents}
          setDocuments={setDocuments}
          spaceBasicDetails = {spaceBasicDetails}
          isFrom={"lease-setup"}
          setSpaceDetail={setSpaceDetail}
          setTopics={setTopics}
           />
        ):
        currentStep == "floor-listing" ? (
          <FloorListing
            setFloor={selectFloorForSetup}
            setCurrentStep={setCurrentStep}
            spaceDetail={spaceDetail}
            setSpaceDetail={setSpaceDetail}
          />
        ) : currentStep == "floor-setup" ? (
          <FloorSetup
            spaceDetail={spaceDetail}
            setSpaceDetail={setSpaceDetail}
            floorDetail={floorDetail}
            setFloorDetail={setFloorDetail}
            setCurrentStep={setCurrentStep}
            saveFloorSettings={saveFloorSettings}
            deleteFloor={() => {addEditFloor(-1, true)}}
            currentStep={currentStep}
          />
        ) :
        currentStep == "flow-complete" ? (
          <OnboardingFlowFinalStep
            congratulationMessage={"l_onboarding_complete_message"}
            navigation={true}
            destination={chatPlanTittle.title == "Add space & agents" ?`/employees?space=${spaceDetail.project_id}&actionType=add-employee` :`/aiChat}`}
          />
        ) : (
          <React.Fragment></React.Fragment>
        )}
      </div>
      <OnboardingFooter
        backBtnHandler={() => {
          changeNextStep(currentStep, -1);
        }}
        nextBtnHandler={() => {
          changeNextStep(currentStep, 1);
        }}
        backButtonText={
          currentStep === "floor-setup" ? _l("l_cancel") : _l("l_previous")
        }
        hideBackButton={["space-type", "flow-complete"].includes(currentStep)}
        currentStep={stepArray.findIndex((x) => x == currentStep) + 1}
        nextButtonText={nextButtonText()}
        skipButtonHandler={() => {}}
        skipButtonText={""}
        totalSteps={stepArray.length}
        loadingNextStep={loadingNextStep}
      />
      {/* feed chat bottom popup start */}
      </div>
      {lastMessage.message && JSON.parse(space_setup_pending) == 1 || JSON.parse(is_registered) == 1 ?
       <a href="#/" className='bg-white-03 p-10 comman_action_icon w_350 radius_3 feed-chat-main' onClick={()=>{navigate(`/aichat`)}} style={{"marginLeft" : "50px"}}>
        <div className='d-flex align-items-center text-truncate pb-2'>
          <div className='h32w32 d-flex align-items-center action_icon with_bg rounded-5 '>
            <div
              className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
              style={{
                backgroundImage: `url('https://myrpp.myr.ai/orb/assets/images/myrtasklogo.png')`,
              }}
            ></div>
          </div>
          <div className='c-font f-14 fw-semibold ps-10px'>Feedchat : Myr.ai support</div>
        </div>
        <div className='d-flex align-items-center text-truncate'>
          <div className='d-flex align-items-center text-truncate'>
            <span className='c-dots  c-7 in-progress-white rounded-circle me-3' ></span>
            <div className='c-font f-14 me-3'>{lastMessage.createdAt ? moment(lastMessage.createdAt).format("YYYY/MM/DD HH:mm ") : ""}</div>
          </div>
          <div className='c-font f-14 me-3 text-truncate w100minus100per'>{lastMessage.message ? ReactHtmlParser(lastMessage.message) : ""}</div>
        </div>
      </a> : <></>}
      {/* feed chat bottom popup end */}
      {toggleSubContactModal ? 
        <AddSubContractModal
          show={toggleSubContactModal}
          handleClose={() => {setToggleSubContactModal(false)}}
          spaceDetail={spaceDetail}
          setSpaceDetail={setSpaceDetail}
        /> 
        : <></>}
      </>
  );
};
export default SpaceDetails;
