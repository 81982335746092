import React, { useEffect, useRef, useState } from "react";
import ScheduleDropDown from "./MyPeople/ScheduleDropDown";
import { CardListScroll, DefaultLeftPanel, TaskModalClose, _l, addActiveBorderforWeekCalendar, addexpandedClass, favoriteDdashboardSelected, formateDate, hideLeftPanelNew, leftPanelMouseUpEvent, loadDataToIDB, showError, showLeftPanel, showLeftPanelNew, removeDuplicates, showContactDirectory, fetchTasksFromIDB, TaskCommentModalOpen, addexpandedClassIntoOffice, SetLightAndDarkTheme, updateBurgerMenuIDB } from "../hooks/utilities";
import CustomerServices from "../services/customer-services";
import {
  getCustomerSpaces,
  projectTaskCounts,
  setProject,
  getTopicTasks,
  leftPanelFilters,
  getTaskCount,
  setActiveTaskStatusFilters,
  setTaskUnreadCmtCntAll,
  getDefaultTopics,
  isIBANModalOpen,
  setCreateScheduledetails,
  setVirtualSpaceList,
  clearDocumentData,
  commanoffcanvasisOpen,
  setAllChatUserList,
  selectedChatUser,
  setSelectedGloablView,
  getTaskList,
  lastSessionOfChat,
  setFetchedTaskandMessages,
  updateTaskList,
  setBulkTaskRating,
  setDaterangeFilterApplied,
  setSelectedDateView,
  dayGridDay,
  setShowWeekcalendardays,
  setDaterangeFilter,
  dayGridMonth,
  dayGridWeek,
  setTopicThreadId,
  clearDefaultTopicsList,
  SetSelectedTask,
  clientTabData,
  setGloablSearchString,
  fullScreenLoaderChangeSpace,
  clearChatTaskList,
  contactDirCount,
  selectJobProposalPage,
  setOpenOrCloseContactDirectoryChat,
  accordionsEventKey,
  setProviderAgnetList,
  setProvideClientList,
  emailTokenExpired,
  isTenantsData,
  setLeftPanelTab,
  getCompanyEmployeeData,
  getTaskChecklist,
  setApiLoaderShow,
  setTentantsEmployeeData,
  updatedDefaultTopics,
} from "../actions/customer";
import {
  fetchUnreadMsgCount,
  setOpenGeneralChatGroup,
  userOffline,
  setUnreadMsgCount,
  getGroupDetails,
  setClearmessage,
  groupIn,
  fetchMessages,
  connectToSocket,
  updateNewMessage,
  playMsgAudio
} from "../actions/chat";
import { fetchAuthToken } from "../actions/chat-auth";
import { useSelector, useDispatch } from "react-redux";
import AddEditCategory from "./Modals/AddEditCategory";
import jquery from "jquery";
import { useNavigate, useSearchParams } from "react-router-dom";
import moment from "moment";
import { loginAsUser } from "../actions/auth";
import { showFullScreenLoader, showMessage, toastCloseAction, showIDBDataFetchingLoader } from "../actions/messages";
import { adminLoginAsStaff } from "../actions/admin-actions";
import ProviderDropdownWithprogressBar from "./Dropdowns/ProviderDropdownWithprogressBar";
import { is_discord_connected } from "../actions/discord_auth";
import { is_googlecalendar_connected } from "../actions/googlecalendar_auth";
import { is_google_drive_connected } from "../actions/google-drive";
import CommanOffCanvas from "./Offcanvas/CommanOffCanvas";
import authServices from "../services/auth-services";
import ManagePrjoectMember from "./Modals/ManagePrjoectMember";
import adminServices from "../services/admin-services";
import { GET_DEFAULT_TOPICS, USER_LOGOUT } from "../actions/action-type";
import AdminServices from "../services/admin-services";
import OpenAi from "./Modals/OpenAi";
import { setOnboardingStepData } from "../actions/onboarding-actions/onboarding-actions";
import TopicSettingModal from "./Modals/TopicSettingModal";
import StaffIbanSetup from "./Modals/StaffIbanSetup";
import Toasts from "./Toasts/Toasts";
import AlertModal from "./Modals/AlertModal";
import CelebrationIcon from "../assets/icons/CelebrationIcon";
import PaymentModal from "./Modals/PaymentModal";
import DocumentsServices from "../services/documents-services";
import { checkAndCreateTables, insertCollectionToDB, readCollectionFromDB } from "../hooks/indexed_db_helpers";
import chatServices from "../services/chat-services";
import MyProfileDropDownNew from "./Dropdowns/MyProfileDropDownNew";
import LeftPanelSubMenu from "./LeftPanel/LeftPanelSubMenu";
import LeftPanelMain from "./LeftPanel/LeftPanelMain";
import customerServices from "../services/customer-services";
import { DEFAULT_MY_TASKS_PAGE_FILTER } from "../constants/constants";
import AddNewCategory from "./Modals/AddNewCategory";
import SignInIcon from "../assets/icons/SignInIcon";
let filterStatus = [];
let projectFilters = {my_spaces: false, project_status: [], account_executive: [], account_manager: []};
let searchMentionUsers = [];
const CONTACT_MENU_OPTIONS = ["plan", "mail", "company-profile"];
const OPERATOR_MENU_OPTIONS = ["plan", "mail", "company-profile", "proposals"];
const STAFF_MENU_OPTIONS = ["teams", "company-profile", "mail"];
let SPACE_LIST_RESULTS_PER_PAGE = 8;
let VISIBLE_MENUS = [];

const LeftPanel = ({
  setSelectedProject = ()=>{},
  projectId,
  isStatusCleared,
  clearStatusFilter,
  pageName = null,
  removedFilterStatus,
  setTaskModalClose,
  allMAilMessages,
  allImportantMessages,
  hideMailSyncBtn,
  setShowMailModal,
  synchronizedMail,
  setSynchronizedMail,
  hideDriveSyncBtn = true,
  setIsKanbanView =()=>{}
}) => {
  const navigate = useNavigate();
  const [selectedOffice, setSelectedOffice] = useState(
   localStorage.getItem("is_registered") == 1 ? 0 : 0
  );
  const adminUser = localStorage.getItem("staff_id");
  const startDate = localStorage.getItem('selectedDate')
  const [SettingMenuRender, setSettingMenuRender] = useState(false);
  const [showScheduleDropDown, setShowScheduleDropDown] = useState(false);
  const [selectOperators, setSelectOperators] = useState({});
  const [Modal1show, setModal1show] = useState(false);
  const [activeSpaces, setActiveSpaces] = useState([]);
  const [selectedSpace, setSelectedSpace] = useState({});
  const [todoTasks, setTodoTasks] = useState(0);
  const [inProgressTasks, setInProgressTasks] = useState(0);
  const [completedTasks, setCompletedTasks] = useState(0);
  const [clientIdLoginAsUser, setClientIdLoginAsUser] = useState(0);
  const [searchString, setSearchString] = useState("");
  const [isOfficeSelected, setIsOfficeSelected] = useState(
    localStorage.getItem("selectedOffice") ?  localStorage.getItem("selectedOffice") : -1
  );
  const [teamLeftPanel, setTeamLeftPanel] = useState(false);
  const dispatch = useDispatch();
  const { openGeneralChatGroup, groupListExternal, chatSocket, newMessage} = useSelector((state) => state.chat);
  const customerData = useSelector((state) => state.customer);
  const spaceState = useSelector((state) => state.customer.spaces);
  const messagesStates = useSelector((state) => state.messages);
  const { isGetTaskId, callID} = useSelector((state) => state.vapi);
  const { taskFilters, selectedUserForChat, globalView, selectedProject, defaultTopics, isContactDirChat, leftPanelTab, commanoffcanvasIsOpen, tenantsEmployeeData} = useSelector((state) => state.customer);
  const { importantMessages, inboxMessages, statusClose, virtualSpaceList,isAllChatUserlistShow ,globalSearchString, emailTokenVaild, isTenantsDataVaild, providerWallet } = useSelector((state) => state.customer);
  virtualSpaceList.forEach((w) => {w.is_virtual_space = 1});
  virtualSpaceList.forEach((w) => {w.is_project_member = 1});
  virtualSpaceList.forEach((w) => {w.project_status = 1});
  virtualSpaceList.forEach((w) => {w.project_id = w.id});
  const activeTaskStatusFilters = useSelector(
    (state) => state.customer.activeTaskStatusFilters
  );
  const isPremiumEstimateEdit = useSelector(
    (state) => state.customer.isPremiumEstimateAccess
  );
const projectAttendancCounts =  useSelector((state) => state.customer.projectAttendancCount)
  const isIBANModal = useSelector((state) => state.customer.isIBANModalOpen);
  const [project_id, setproject_id] = useState();
  const [settingTopicName, setSettingTopicName] = useState("");
  const [topicMainKey, setTopicMainKey] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [toastClass, setToastClass] = useState("");
  const [toastMessage, setToastMessage] = useState("");
  const [toastHeader, setToastHeader] = useState("");
  const [officeId, setOfficeId] = useState(0);
  const [estimateId, setEstimateId] = useState(0);
  const [showProjectStatusList, setshowProjectStatusList] = useState(false);
  const [selectedspaceStatus, setSelectedspaceStatus] = useState(0)
    const disablePages = [
    "companyprofile",
    "mywallet",
    "myspacenew",
    "MyEmail",
    "premiumsubscription",
    "leadPage"
  ];
  const [showBackDropClass, setShowBackDropClass] = useState(false);
  const [spaceSearchOnGoing, setSpaceSearchOnGoing] = useState(false);
  const noLeftPanel = [
    "CompanyProfile",
    "MyProfile",
    "MyWallet",
    "Onboarding",
    "SpaceOnBoardingEquipments",
    "SpaceOnBoardingNew",
    "SpaceOnBoarding",
    "SpaceOnBoardingSchedule",
    "SpaceOnBoardingTeam",
    "SpaceOnBoardingTenet",
    "spaceOnboardingInvite",
    "PaymentPage",
    "paymentsetupnew",
    "PremiumSubscription",
    "SpaceOnBoardingNew",
    "dashboardwithdaterange",
    "dashboardpre"
  ];
  const noLeftPanelPages = [
    "CompanyProfile",
    "MyProfile",
    "MyWallet",
    "Onboarding",
    "SpaceOnBoardingEquipments",
    "SpaceOnBoardingNew",
    "SpaceOnBoarding",
    "SpaceOnBoardingSchedule",
    "SpaceOnBoardingTeam",
    "SpaceOnBoardingTenet",
    "spaceOnboardingInvite",
    "PaymentPage",
    "paymentsetupnew",
    "PremiumSubscription",
    "SpaceOnBoardingNew",
    "dashboardwithdaterange",
    "dashboardpre"
  ];
  const fullScrollPages =["Dashboard","LiteVersion", "benchmarkList", "documents", "MyEmail", "Wallet", "leadPage", "MyPeople", "Proposals", "schedule", "client", "AdminTaskPage","SpaceOnBoarding","ChatAi","MyProfile","benchmark"];
  const noActiveSpacePageNames = ["invoice", "estimate", "subscription", "staff", "benchmark", "schedule", "CompanyProfile", "Proposals", "MyProfile", "client", "fullcalendar", "AdminTaskPage", "credit_notes", "_equipments", "PaymentPage"];
  if(!openGeneralChatGroup || !isAllChatUserlistShow){
    noLeftPanelPages.push("Chat");
    noLeftPanelPages.push("LiteVersion");
  }
  const client_id = localStorage.getItem("client_id");
  const contact_id = localStorage.getItem("contact_id");
  const admin = localStorage.getItem("admin");
  const adminArchieve = localStorage.getItem("adminArchieve");
  const [userList, setUserList] = useState([]);
  const [pendingPaymentInfo, setPendingPaymentInfo] = useState({});
  const [subscriptionLoader, setSubscriptionLoader] = useState(false);
  const staffOrContactId =
    localStorage.getItem("user_type") == "contact"
      ? localStorage.getItem("contact_id")
      : localStorage.getItem("staff_id");
  let user_type = localStorage.getItem("user_type");
  user_type = user_type === "contact" ? "client" :user_type === "operator" ? "operator" : "staff";
  const userType = localStorage.getItem("user_type");
  const contact_role = localStorage.getItem("contact_role");
  const isAdmin = localStorage.getItem("admin");
  const spaceArray = localStorage.getItem("spaces") ? JSON.parse(localStorage.getItem("spaces")) : [];
  const savedProject = localStorage.getItem("selectedOffice");
  const disabledThirdPartyToolAccess = spaceArray && spaceArray.length <= 1 && localStorage.getItem("space_setup_pending") > 0;
  const [spaces, setSpaces] = useState([]);
  const [clientTeamData, setClientTeamData] = useState([]);
  const [searchCategory, setSearchCategory] = useState("");
  const [preEstiPageDisable, setPreEstiPageDisable] = useState("");
  const [filterTaskActive, setFilterTaskActive] = useState([]);
  const [projectTaskCount, setProjectTaskCount] = useState([]);
  const [estimateDetails, setEstimateDetails] = useState({});
  const [managePeopleModal, setManagePeopleModal] = useState(false);
  const [emailModalOpenSet, setEmailModalOpenSet] = useState(false);
  const [showOpenAiModal, setshowOpenAiModal] = useState(false);
  const [spacecontent, setspaeContent] = useState("")
  const viewPage = window.location.pathname;
  const email = localStorage.getItem("email");
  let defaultTaskStatus = [0, 1, 4, 5];
  if ( ["Dashboard" , "calendar" , "fullcalendar" ,"dashboard" , "AdminTaskPage"].includes(pageName)) {
    // 0 - for request task
    defaultTaskStatus = [0, 1, 4, 5];
  }
  const [spaceFilter, setSpaceFilter] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState();
  const [createTeamModal, setCreateTeamModal] = useState(false);
  const [equipmentModal, setEquipmentModal] = useState(false);
  const [employeeData, setEmployeeData] = useState("");
  const spaceClient = useSelector((state) => state.customer.spaceClient);
  const [onboardDocument, setOnboardDocument] = useState("");
  const [showDocumentCanvas, setShowDocumentCanvas] = useState(false);
  const [topicType, setTopicType] = useState("");
  const [selectedTeamForEdit, setSelectedTeamForEdit] = useState();
  const [showAddTeamMember, setshowAddTeamMember] = useState(false);
  const [adminTeamList, setAdminTeamList] = useState([]);
  const [spaceData, setspaceData] = useState([])
  const [showLeftPanelMenu, setshowLeftPanelMenu] = useState(false)
  const getOnboardDocument = useSelector((state) => state.documentsReducer.getOnboardDocument);
  const [selectedStaffMembersForSpaceFilter, setSelectedStaffMembersForSpaceFilter] = useState([]);
  const [showStaffIBANSetup, setShowStaffIBANSetup] = useState(false);
  const [spaceInvitationData, setSpaceInvitationData] = useState({});
  const [menuOptionsFloat, setMenuOptionsFloat] = useState(true);
  const [benchmarkDiscardModal, setBenchmarkDiscardModal] = useState({});
  const [searchCategoty, setsearchCategoty] = useState("")
  const [searchTopic, setsearchTopic] = useState("")
  const [showForMeSection, setShowForMeSection] = useState(false)
  const [isLoginAsUserDataLoaded, setIsLoginAsUserDataLoaded] = useState([]);
  const [contactlistShow, setContactlistShow] = useState(false);
  const [showMyProfile, setshowMyProfile] = useState(false)
  let space_pending = localStorage.getItem("space_setup_pending")
  let is_registered = localStorage.getItem("is_registered")
  const panelRef = useRef();
  const officeListDivRef = useRef();
  const officeSearchboxRef = useRef();
  const topicSearch = useRef();
  const {
    leadsFilters,
  } = useSelector((state) => state.leadsReducer);
  const [searchParams, setSearchParams] = useSearchParams();
  const [showAssistantTask, setShowAssistantTask] = useState(false);
  const [assistantTaskDetails, setAssistantTaskDetails] = useState({
    name : "please wait Task is generating ....."
  })
  const [showSpaceList, setShowSpaceList] = useState(false)
  let fav_dashboard = localStorage.getItem("fav_dashboard")
  const DEFAULT_SUB_MENU_PAGES = ["SpaceOnBoarding", "SpaceOnBoardingNew"];
  const projectStatusArray = [
    {
      id: 1,
      name: _l("l_todo"),
      class: "color-gray",
    },
    {
      id: 2,
      name: _l("l_free_trial"),
      class: "color-blue",
    },
    {
      id: 6,
      name: _l("l_active"),
      class: "color-green",
    },
    {
      id: 7,
      name: _l("l_inactive"),
      class: "color-white",
    },
  ];
  const isLeadPage = pageName == "leadPage" ? 1 : 0;
  const [leftpanelToggleOnLiteversion,setLeftpanelToggleOnLiteversion] = useState(false);
  const proposalUnreadComments = localStorage.getItem("unread_schedule_comment");
  let FavView = localStorage.getItem("fav_dashboard");
  const dashboardBenchmarkId = !isNaN(Number(searchParams.get("dashboard-benchmark-id"))) && Number(searchParams.get("dashboard-benchmark-id")) ? Number(searchParams.get("dashboard-benchmark-id")) : 0;
  let spacesArray = userType == "staff" ? activeSpaces : activeSpaces;
  var chatUserId = localStorage.getItem("chatUserId");
  const [isSynced, setisSynced] = useState("");
  const [staffChatCounts, setStaffChatCounts] = useState(0);
  const [tenentsChatCounts, setTenentsChatCounts] = useState(0);

  const closeInvitationAlert = async() => {
    try {
      const response = await CustomerServices.readSpaceInvitationAlert(spaceInvitationData.project_id);
      if(response.status)
      {
        setSpaceInvitationData({});
      }
      else {
        showError(response.message);
      }
    }
    catch(e)
    {
      console.error(e);
    }
  }
  useEffect(() => {
    if(noActiveSpacePageNames.indexOf == -1)
    {
      jquery('.office-box[data-space-div="'+isOfficeSelected+'"]').addClass('active');
    }
  }, [noActiveSpacePageNames])
  useEffect(() => {
    if(noLeftPanel.includes(pageName) && showLeftPanelMenu)
    showLeftPanel(true,true);
    if(!["operatorListReporting","equipmentListReporting","SpaceOnBoardingNew"].includes(pageName)){
      CardListScroll();
    }
    return () => {
    }
  }, [pageName,showLeftPanelMenu]);
  useEffect(() => {
    if (selectedTeam && Object.keys(selectedTeam).length > 0 && adminTeamList && adminTeamList.length > 0) {
      let virualspace = adminTeamList.filter((vs) =>  vs && vs.id == selectedTeam.id)
      dispatch(setUnreadMsgCount(parseInt(virualspace[0].unread_chat_msg_count)))
    } else if (selectedOffice > 0 && activeSpaces && activeSpaces.length > 0) {
      let space = activeSpaces.filter((as) => as && as.project_id == selectedOffice)
      if (space && space.length > 0) {
        dispatch(setUnreadMsgCount(parseInt(space[0].chat_notification_count)))
      }
    }
    if (selectedOffice > 0 && spacesArray.length > 0) {
      let tempSelectedSpace = spacesArray.filter((as) => as && as.project_id == selectedOffice)
      setSelectedSpace(tempSelectedSpace.length > 0 ? tempSelectedSpace[0] : {})
    }
  }, [selectedOffice, activeSpaces, adminTeamList])
  useEffect(() => {
    if (isAdmin == "true" && selectedUserForChat && Object.keys(selectedUserForChat).length > 0 && !selectedUserForChat.generalChat && pageName == "Chat") {
      dispatch(setAllChatUserList(true));
    }
    },[selectedUserForChat, pageName])
  useEffect(() => {
    jquery("body").removeClass("task-modal-offcanvas-open");
    if(pageName == "staff" || pageName == "client" || pageName == "_equipments")
    {
      jquery('._staff').attr('style', 'width:150px');
    }
    if(pageName == 'credit_notes')
    {
      jquery('._credit_notes').attr('style', 'width: 100px');
    }
    setFilterTaskActive([]);
    dispatch(setActiveTaskStatusFilters([], 1));
    filterStatus = [];
  }, [pageName])
  useEffect(() => {
      if(globalView == "listView" || globalView == "calendarView" || globalView == "kanbanView"){
      localStorage.removeItem("is_dashboard");
      localStorage.setItem("is_dashboard", !isNaN(Number(projectId)) && Number(projectId) > 0 ? 0 : 1);
    }else  {
      localStorage.removeItem("is_dashboard");
      localStorage.setItem("is_dashboard",0);
    }
    if(pageName == "LiteVersion"){
      setSelectedOffice()
    }
  },[pageName , globalView]);
  useEffect(() => {
    if (selectedProject && pageName != "dashboardnew" && is_registered != 1) {
      setSelectedOffice(leftPanelTab !== "my-space" ? 0 : Number(selectedProject));
      setSelectedSpace(leftPanelTab !== "my-space" ? {} : selectedSpace )
    }
  },[selectedProject])
  useEffect(() => {
    if (getOnboardDocument && Object.keys(getOnboardDocument).length > 0) {
      setOnboardDocument(getOnboardDocument);
    }
  },[getOnboardDocument]);
  useEffect(() => {
    if(pageName == "invoice" || pageName == "estimate" || pageName == "subscription" || pageName == "_equipments" || pageName == "staff" || pageName == "client" || pageName == "AdminTaskPage" || pageName == "credit_notes" || pageName == "fullcalendar" || pageName == "benchmark" || pageName == "schedule" )
    {
      jquery(".office-box").removeClass("active");
      jquery(".left-panel-company-setting").addClass("active");
    }
  }, [pageName])
  useEffect(() => {
    if((spaceState && spaceState.length == 0) && (virtualSpaceList && virtualSpaceList.length == 0) && (is_registered == 1) && !["Proposals", "SpaceOnBoarding", "SpaceOnBoardingNew", "MyProfile", "PremiumSubscription","hireNewAgent","OnboardingProposal","OnboardingEmployee"].includes(pageName)){
      hideLeftPanelNew();
    }
    const space_setup_pending = localStorage.getItem("space_setup_pending");
    if(space_setup_pending > 0 && !["ChatAi", "SpaceOnBoarding", "SpaceOnBoardingNew", "MyProfile", "PremiumSubscription" ,"Proposals"].includes(pageName) && ["operator", "contact"].includes(userType) && contact_role != 3){
      if(userType === "contact" || (userType === "operator" && (!spaceArray.length || spaceArray.every((w) => w.is_added_from_operator == 1))))
      {
        if (userType === "operator" && providerWallet == 0 ) {
          dispatch(selectJobProposalPage(false));
        } else {          
          navigate("/");
          dispatch(setSelectedGloablView("AichatView"));
        }
      }
    }
    const invitedProject = spaceArray.find((w) => w.space_invitation_alert);
    if(invitedProject)
    {
      setSpaceInvitationData({
        project_id: invitedProject.project_id,
        message: invitedProject.space_invitation_alert
      });
    }
    let currentSpaceDetails = spaceArray.find((w) => w.project_id == selectedOffice && w.project_id != -1);
    if(currentSpaceDetails)
    {
      if(currentSpaceDetails.is_external > 0 && !["/connect-provider"].includes(viewPage) && !["MyProfile", "ChatAi"].includes(pageName))
      {
        navigate(`/aichat`);
      }
      if(currentSpaceDetails.pending_benchmarks && currentSpaceDetails.pending_benchmarks.length && !["SpaceOnBoarding", "SpaceOnBoardingNew", "MyProfile", "PremiumSubscription","ChatAi"].includes(pageName))
      {
        if(currentSpaceDetails.pending_benchmarks.length === 1)
        {
          navigate(`/connect-provider?space=${currentSpaceDetails.project_id}&provider-type=benchmark&id=${currentSpaceDetails.pending_benchmarks[0].id}`);
        }
        else {
          navigate(`/explore-features?setting-menu=providers&section=benchmark&category=${currentSpaceDetails.pending_benchmarks[0].provider_id}&space=${currentSpaceDetails.project_id}`);
        }
      }
      else if(userType !== "staff" && currentSpaceDetails.status == 0 && ![, "SpaceOnBoarding", "SpaceOnBoardingNew", "MyProfile", "PremiumSubscription", "ChatAi","Dashboard"].includes(pageName))
      {
        if(userType == "operator")
        {
          navigate(`/proposals`, { state: { from: viewPage } });
        }
        else {
          navigate(`/explore-features?space=${currentSpaceDetails.project_id}`);
        }
      }
      else if(currentSpaceDetails.is_external && currentSpaceDetails.schedule_id && !["Chat"].includes(pageName) && userType !== "operator")
      {
        dispatch(
          setOnboardingStepData({
            showChat: true,
          })
        );
      }
      else if(userType === "operator" && Number(currentSpaceDetails.is_added_from_operator) && !currentSpaceDetails.is_onboard_completed_operator && !["PremiumSubscription", "Proposals", "PaymentPage", "SpaceOnBoarding", "MyProfile", "SpaceOnBoardingNew", "ChatAi"].includes(pageName))
      {
        navigate(`/explore-features?space=${selectedOffice}`);
      }
      else if(userType === "contact" && !Number(currentSpaceDetails.is_added_from_operator) && !currentSpaceDetails.is_onboard_completed_contact && !["PremiumSubscription", "PaymentPage", "SpaceOnBoarding", "MyProfile", "SpaceOnBoardingNew", "ChatAi"].includes(pageName))
      {
        navigate(`/explore-features?space=${selectedOffice}`);
      }
    }
    else if(spaceArray.length === 1 && ((spaceArray[0].pending_benchmarks && spaceArray[0].pending_benchmarks.length) || spaceArray[0].status == 0) && !["SpaceOnBoarding", "SpaceOnBoardingNew", "MyProfile", "PremiumSubscription", "ChatAi"].includes(pageName))
    {
      if(spaceArray[0].status == 0)
      {
        if(userType == "operator")
        {
          navigate(`/`, { state: { from: viewPage } });
        }
        else {
          navigate(`/explore-features?space=${spaceArray[0].project_id}`);
        }
      }
      else if(!["SpaceOnBoarding", "SpaceOnBoardingNew", "MyProfile", "PremiumSubscription", "ChatAi"].includes(pageName)) {
        if(spaceArray[0].pending_benchmarks.length === 1)
        {
          navigate(`/connect-provider?space=${spaceArray[0].project_id}&provider-type=benchmark&id=${spaceArray[0].pending_benchmarks[0].id}`);
        }
        else {
          navigate(`/explore-features?setting-menu=providers&section=benchmark&category=${spaceArray[0].pending_benchmarks[0].provider_id}&space=${spaceArray[0].project_id}`);
        }
      }
    }
    if (spaces.length && spaceState.length) {
      let sortedSpaces = spaces.filter(
        (space) => space.status == "1" || space.status == "2" || space.status == "0"
      );
      if (userType == 'staff') {
        sortedSpaces = spaces.filter(
          (space) => space.status == "1" || space.status == "2" || space.status == "0"
        );
      }
      if (
        selectedOffice > 0 &&
        sortedSpaces.length &&
        sortedSpaces.find((space) => space.project_id == selectedOffice)
      ) {
        sortedSpaces = [
          sortedSpaces.find((space) => space.project_id == selectedOffice),
          ...sortedSpaces.filter((space) => space.project_id != selectedOffice),
        ];
      }
      setActiveSpaces(sortedSpaces.length ? sortedSpaces : []);
      const firstProjectId = spaces.find(
        (offices) => offices.status == "1" || offices.status == "2"
      );
      localStorage.removeItem("selectedVirtualSpaceId");
      if (localStorage.getItem("user_type") == "staff") {
        let tempProjectId = savedProject
          ? savedProject
          : firstProjectId && firstProjectId.project_id
            ? firstProjectId.project_id
            : 0;
        let spaceDetails = sortedSpaces.filter(
          (space) => space.project_id == tempProjectId
        );
        if (spaceDetails.length) {
          localStorage.setItem(
            "virtualSpaceClientId",
            spaceDetails[0].client_id ? spaceDetails[0].client_id : ""
          );
        }
      }
    } else if (spaces.length && spaceState.length == 0) {
      setActiveSpaces([]);
    }else {
      setActiveSpaces(spaceState)
    }
    if (
      isOfficeSelected != "" &&
      activeSpaces &&
      activeSpaces.length &&
      localStorage.getItem("selectedOffice") &&
      parseInt(localStorage.getItem("selectedOffice")) > 0
    ) {
      const spaceId = spaces.filter(
        (space) => space.project_id == localStorage.getItem("selectedOffice")
      );
      if (spaceId[0]) {
        setOfficeOnTop(spaceId[0].id);
      }
      setIsOfficeSelected("");
    }
    if (activeSpaces &&
      activeSpaces.length &&
      localStorage.getItem("selectedOffice") &&
      parseInt(localStorage.getItem("selectedOffice")) > 0) {
      const spaceId = spaces.filter(
        (space) => space.project_id == localStorage.getItem("selectedOffice")
      );
    }
    else if(userType == "operator" && pageName == "Proposals")
    {
      dispatch(getDefaultTopics(0, "", "", pageName));
      dispatch(getTopicTasks(0, pageName));
    }
    spaces.map((space, index) => {
      if (selectedOffice === space.project_id) {
        dispatch(setTaskUnreadCmtCntAll(space.notification_count));
      }
    });
    if (activeSpaces.length) {
      reorderOffices();
    } 
    if (spaceState && spaceState.length) {
       userType == "staff" ? setSpaces(spaceState.concat(virtualSpaceList)) : setSpaces(spaceState);
       let tempSpaces = spaceState.concat(userType == "staff" ? virtualSpaceList : spaceState).filter((space)=>{
        if (userType != "operator") { 
          return space.is_project_member == 1
        }else{
         return space
        }
       })
        setSpaces(tempSpaces)
      }
    //Block Operator to access platform in case of pending payment action
    if(userType === 'operator')
    {
      let pendingPayments = localStorage.getItem('pending_payments');
      pendingPayments = pendingPayments ? JSON.parse(pendingPayments) : [];
      if(pendingPayments.length)
      {
        setPendingPaymentInfo(pendingPayments[0]);
        localStorage.removeItem("pending_payments");
      }
    }
  }, [spaceState, JSON.stringify(spaces) ,selectedOffice, spaceArray.length  ]);
  useEffect(() => {
    handleVisibleMenus();
    localStorage.removeItem("is_pilot_onboarding");
  }, [])
  useEffect(() => {
    if (projectAttendancCounts) {
      setProjectTaskCount(projectAttendancCounts)
    }
  }, [projectAttendancCounts])
  const handleChangeProjectStatus =(id)=>{
    if(id != 7){
      CustomerServices.changePeojectStatus(project_id,id ).then((res) => {
        if(res.status){
          setSelectedspaceStatus(id)
          dispatch(showMessage("sucess", _l('l_success'), res.message ?  _l(res.message)  : _l('l_space') + _l("l_status") + _l("l_change") + _l('l_successfully')));
          let index = activeSpaces.findIndex((space)=> parseInt(space.project_id) == parseInt(project_id));
          let updatelsit = activeSpaces[index]
          updatelsit.project_status = id
        }else{
        dispatch(showMessage("unsucess", _l('l_error'), res.message ?  _l(res.message)  : _l('l_something_went_wrong')));
        }
      })
    }else{
      handleSpaceDelete()
    }
  }
  useEffect(() => {
    if (isAdmin == "true" && userType == "staff" && ( globalView =="liteVersionView" || pageName == "Chat") && isAllChatUserlistShow) {
      jquery(".provider-list").addClass("expanded");
      showLeftPanelNew();
      dispatch(setAllChatUserList(true));
    }else{
      dispatch(setAllChatUserList(false));
    }
    if(( globalView != "liteVersionView") &&  pageName != "Chat"){
      localStorage.setItem("generalChatForLiteVersion",false);
      localStorage.setItem('isLiteVersion',false);
    }
  },[pageName, globalView]);
  useEffect(() => {
    SetLightAndDarkTheme()
  }, []);
  useEffect(() => {
    if (projectId > 0 && spaceState.length > 0) { 
      if(!["SpaceOnBoarding", "MyProfile", "ConnectProvider"].includes(pageName) && contact_role != 3 && is_registered != 1)
      {
        showLeftPanelNew();
      }
    }
    jquery(document).mouseup(function (e) {
      var container = jquery(".fixed-left-panel");
      if (!container.is(e.target) && container.has(e.target).length === 0) {
        setTeamLeftPanel(false);
        setshowLeftPanelMenu(false);
      }
    });
    jquery(".office-list-icon-search").on("click", function () {
      showLeftPanelNew();
      addexpandedClass();
    });
    jquery(".left_panel_toggle").on("click", function () {
    });
    jquery(".office-box").on("click", function (event) {
    });
    if (spaces && spaces.length && projectId) {
      const project = spaces.filter((s) => s.project_id == projectId);
      if (project.length) {
        localStorage.setItem("selected_project_client_id",project.client_id);
        if (localStorage.getItem("user_type") == "staff") {
          localStorage.setItem(
            "virtualSpaceClientId",
            project[0].client_id ? project[0].client_id : ""
          );
        }
      } else {
        localStorage.setItem("selected_project_client_id",spaces[0].client_id);
      }
      if(!["operatorListReporting","equipmentListReporting"].includes(pageName)){
        dispatch(projectTaskCounts(projectId, "", "", isLeadPage, pageName, globalView));
        if (isLeadPage) {
          dispatch(
            getTaskCount(client_id, contact_id, projectId, formateDate(startDate), taskFilters, '', '', '', '', 1, pageName,  '', '', leadsFilters)
          );
        }
      }
      if (pageName == "dashboardnew") {
        dispatch(getDefaultTopics(null, "", "", pageName,taskFilters));
      }else{
        dispatch(getDefaultTopics(projectId, "", "", pageName,taskFilters));
      }
    }else if(adminTeamList && adminTeamList.length > 0 && localStorage.getItem("selectedVirtualSpace") &&
    JSON.parse(localStorage.getItem("selectedVirtualSpace")).id){
      dispatch(getDefaultTopics(adminTeamList[0].id, "", "", pageName,taskFilters));
    }
    if (userType != "staff") {
      CustomerServices.getPremiumEstimateDetails().then((res) => {
        if (res && res.status) {
          setEstimateDetails(res.data);
          localStorage.setItem(
            "iban_modal_open",
            res.data && res.data.open_modal
          );
          dispatch(isIBANModalOpen());
        } else {
          setEstimateDetails({});
        }
      });
    }
  }, [projectId, pageName]);  
  useEffect(() => {
    if (customerData.clientTeamData) {
      setClientTeamData(customerData.clientTeamData[parseInt(projectId)]);
    }
  }, [customerData.clientTeamData]);
  useEffect(() => {
    if(space_pending == 1){
      hideLeftPanelNew();
    }
  }, [space_pending])
  useEffect(() => {
    if (removedFilterStatus && removedFilterStatus.flag == true) {
      filterTasksByStatus(removedFilterStatus.status);
    }
  }, [removedFilterStatus]);
  useEffect(() => {
    if (customerData.taskCounts) {
      setProjectTaskCount(customerData.taskCounts);
    }
  }, [customerData.taskCounts]);
  useEffect(() => {
    if (
      customerData.createSchduleDetails &&
      Object.keys(customerData.createSchduleDetails).length > 0 &&
      pageName != "benchmarkList"
    ) {
      dispatch(setCreateScheduledetails({}));
    }
  }, [customerData.createSchduleDetails]);
  const reorderOffices = () => {
    let activeSpaceList = spaceState.filter(
      (x) => x.status === "1" || x.status === "2"
    );
    activeSpaceList = [
      spaceState.find((space) => space.project_id == selectedOffice),
      ...spaceState.filter(
        (space) =>
          userType == 'staff' ? space.project_id != selectedOffice &&
            (space.status === "1" || space.status === "2" || space.status == '0') : space.project_id != selectedOffice &&
          (space.status === "1" || space.status === "2" || space.status == '0')
      ),
    ];
    if (activeSpaceList.length > 0) {
      setActiveSpaces(
        activeSpaceList.sort(function (a, b) {
          if (parseInt(a.pin_count) < parseInt(b.pin_count)) return 1;
          if (parseInt(a.pin_count) > parseInt(b.pin_count)) return -1;
          if (!a.chat_group_message || a.chat_group_message === '') return 1;
          if (!b.chat_group_message || b.chat_group_message === '') return -1;
          const chatA = moment(a.chat_group_message_date ? a.chat_group_message_date : '');
          const chatB = moment(b.chat_group_message_date ? b.chat_group_message_date : '');
          if (chatA.isBefore(chatB)) return 1;
          if (chatA.isAfter(chatB)) return -1;
          if ((parseInt(a.notification_count) + parseInt(a.chat_notification_count) + parseInt(a.unread_notification_count)) > (parseInt(b.notification_count) + parseInt(b.chat_notification_count) + parseInt(b.unread_notification_count))) return -1;
          if ((parseInt(a.notification_count) + parseInt(a.chat_notification_count) + parseInt(a.unread_notification_count)) < (parseInt(b.notification_count) + parseInt(b.chat_notification_count) + parseInt(b.unread_notification_count))) return 1;
          if (a.project_id == selectedOffice) return -1;
          return 0;
        })
      );
    }
  };
  const setOfficeOnTop = (spaceId) => {
    if (activeSpaces.length > 0) {
      const targetSpace = activeSpaces.filter(
        (office) => office && office.id === spaceId
      );
      const otherOffices = activeSpaces.filter(
        (office) => office && office.id !== spaceId
      );
      var allOffices = targetSpace.concat(otherOffices);
      allOffices.sort(function (a, b) {
        if (parseInt(a.pin_count) < parseInt(b.pin_count)) return 1;
        if (parseInt(a.pin_count) > parseInt(b.pin_count)) return -1;
        if (!a.chat_group_message || a.chat_group_message === '') return 1;
        if (!b.chat_group_message || b.chat_group_message === '') return -1;
        const chatA = moment(a.chat_group_message_date ? a.chat_group_message_date : '');
        const chatB = moment(b.chat_group_message_date ? b.chat_group_message_date : '');
        if ((chatA).isBefore(chatB)) return 1;
        if ((chatA).isAfter(chatB)) return -1;
        if ((parseInt(a.notification_count) + parseInt(a.chat_notification_count) + parseInt(a.unread_notification_count)) > (parseInt(b.notification_count) + parseInt(b.chat_notification_count) + parseInt(b.unread_notification_count))) return -1;
        if ((parseInt(a.notification_count) + parseInt(a.chat_notification_count) + parseInt(a.unread_notification_count)) < (parseInt(b.notification_count) + parseInt(b.chat_notification_count) + parseInt(b.unread_notification_count))) return 1;
        if (a.project_id == spaceId) return -1;
        return 0;
      });
      setActiveSpaces(allOffices);
    }
  };
  useEffect(() => {
    let allSpaces = spaces.filter(
      (space) => space.status == "1" || space.status == "2"
    );
    if (allSpaces.length > 0) {
      const targetSpace = allSpaces.filter(
        (office) => office.id === isOfficeSelected.id
      );
      const otherOffices = allSpaces.filter(
        (office) => office.id !== isOfficeSelected.id
      );
      var allOffices = targetSpace.concat(otherOffices);
      allOffices.sort(function (a, b) {
        if (parseInt(a.pin_count) < parseInt(b.pin_count)) return 1;
        if (parseInt(a.pin_count) > parseInt(b.pin_count)) return -1;
        if (!a.chat_group_message || a.chat_group_message === '') return 1;
        if (!b.chat_group_message || b.chat_group_message === '') return -1;
        const chatA = moment(a.chat_group_message_date ? a.chat_group_message_date : '');
        const chatB = moment(b.chat_group_message_date ? b.chat_group_message_date : '');
        if ((chatA).isBefore(chatB)) return 1;
        if ((chatA).isAfter(chatB)) return -1;
        if ((parseInt(a.notification_count) + parseInt(a.chat_notification_count) + parseInt(a.unread_notification_count)) > (parseInt(b.notification_count) + parseInt(b.chat_notification_count) + parseInt(b.unread_notification_count))) return -1;
        if ((parseInt(a.notification_count) + parseInt(a.chat_notification_count) + parseInt(a.unread_notification_count)) < (parseInt(b.notification_count) + parseInt(b.chat_notification_count) + parseInt(b.unread_notification_count))) return 1;
        if (a.project_id == isOfficeSelected.id) return -1;
        return 0;
      });
      setActiveSpaces(allOffices);
    }
  }, []);
  useEffect(()=>{
    if(spaceClient && spaceClient.project_id)
    {
      onClickOfficeChange(spaceClient);
      localStorage.setItem("selected_project_client_id",spaceClient.client_id);
      let data = [];
      dispatch(clientTabData(data));
    }
  }, [spaceClient])
  const handleOfficeChange = (space, is_virtual_space = false) => {
    dispatch(lastSessionOfChat([]))
    setSelectedSpace(space);
    dispatch(commanoffcanvasisOpen(false)) 
    dispatch(SetSelectedTask({}));
    let project_id = space.project_id ? parseInt(space.project_id) : 0;
    if (is_virtual_space) {
      dispatch(setUnreadMsgCount(+space.unread_chat_msg_count > 0 ? +space.unread_chat_msg_count : 0))
    }else{
    dispatch(setUnreadMsgCount(+space.chat_notification_count > 0 ? +space.chat_notification_count : 0))
    }
    dispatch(setTaskUnreadCmtCntAll(parseInt(0)));
    if (is_virtual_space) {
      localStorage.setItem(
        "prevSelectedOffice",
        localStorage.getItem("selectedOffice")
      );
      project_id = space.id;
      setTeamLeftPanel(false);
      dispatch(getTopicTasks(project_id, "", 1));
      dispatch(getDefaultTopics(projectId, "", "", pageName));
      dispatch(setTaskUnreadCmtCntAll(parseInt(space.unread_notification_count)));
    } else {
      removeVirtualSpace();
      setSelectedTeam();
      setSelectedOffice();
      setOfficeOnTop(space.id);
      if (project_id != localStorage.getItem("selectedOffice")) {
      } else {
        setOfficeOnTop(space.id);
      }
    }
    setSearchCategory("");
    if (setTaskModalClose) {
      setTaskModalClose();
    }
    setSelectedOffice(Number(project_id));
    setSelectedProject(Number(project_id));
    dispatch(setProject(project_id));
    if(pageName === "SpaceOnBoarding")
    {
      let id = searchParams.get("space");
      if(id)
      {
        searchParams.set("space", project_id);
      }
      else {
        searchParams.append("space", project_id);
      }
      setSearchParams(searchParams);
    }
    dispatch(is_discord_connected(project_id));
    dispatch(is_google_drive_connected(project_id));
    dispatch(is_googlecalendar_connected(project_id));
    var chatUserId = localStorage.getItem("chatUserId");
    dispatch(fetchUnreadMsgCount(chatUserId, localStorage.getItem("isLiteVersion") == "true" ? 0 : project_id));
    dispatch(getTaskCount(
        client_id,
        contact_id,
        project_id,
       moment(localStorage.getItem('__selectedDate')).format("YYYY-MM-DD") ? moment(localStorage.getItem('__selectedDate')).format("YYYY-MM-DD") : moment(localStorage.getItem("selectedDate")).format("YYYY-MM-DD") ,
        taskFilters,
        activeTaskStatusFilters,
        "",
        "",
        "",
        (projectId == null || projectId == 0 || projectId == undefined) ? "liteVersionView" : globalView
    ));
    if (
      !customerData.clientTeamData ||
      !customerData.clientTeamData[parseInt(projectId)]
    ) {
    } else {
      setClientTeamData(customerData.clientTeamData[project_id]);
    }
    localStorage.setItem("selectedOffice", project_id);
    if (userType == "operator") {
      localStorage.setItem(
        "is_added_from_operator",
        space.is_added_from_operator ? space.is_added_from_operator : 0
      );
      localStorage.setItem(
        "operatorClientId",
        space.client_id ? space.client_id : 0
      );
      localStorage.setItem(
        "spaceClientDetails",
        JSON.stringify([
          {
            cp_id: space.client_id ? space.client_id : 0,
            mobile: space.phonenumber ? space.phonenumber : "",
            email: space.email ? space.email : "",
            profile_url: space.profile_image ? space.profile_image : "",
          },
        ])
      );
    }
    if (pageName == "dashboard") {
    }
    if (["SpaceOnBoarding", "Chat", "Proposals"].includes(pageName)) {
      jquery(".provider-list").addClass("expanded");
    }
    setTeamLeftPanel(false);
    setSearchString("")
  };
  const filterTasksByStatus = (statusId) => {
    let otherFilters = customerData.taskFilters ? customerData.taskFilters : [];
    // Remove all status which are not active
    filterStatus.forEach((status) => {
      if (!jquery(`#kpi_task_status_` + status).hasClass("active")) {
        setFilterTaskActive([...filterTaskActive, status]);
        var sIndex = filterStatus.indexOf(status);
        filterStatus.splice(sIndex, 1);
      }
    });
    const index = filterStatus.indexOf(statusId);
    if (index === -1) {
      filterStatus.push(statusId);
      jquery(`#kpi_task_status_` + statusId).addClass("active");
      setFilterTaskActive([...filterTaskActive, statusId]);
    } else {
      filterStatus.splice(index, 1);
      setFilterTaskActive(
        filterTaskActive.filter((status) => status != statusId)
      );
    }
    if (isLeadPage) {
      dispatch(
        getTaskCount(
          0,
          0,
          localStorage.getItem("selectedOffice"),
          moment(localStorage.getItem("selectedDate")).format("YYYY-MM-DD"),
          otherFilters,
          "",
          filterStatus,
          "",
          "",
          1,
          pageName,
          (projectId == null || projectId == 0 || projectId == undefined) ? "liteVersionView" : globalView,
          '',
          leadsFilters, globalSearchString
        )
      );
    } else {
      dispatch(
        getTaskCount(
          0,
          0,
          localStorage.getItem("selectedOffice"),
          moment(localStorage.getItem("selectedDate")).format("YYYY-MM-DD"),
          otherFilters,
          "",
          filterStatus,
          "",
          "",
          0,
          pageName,
          (projectId == null || projectId == 0 || projectId == undefined) ? "liteVersionView" : globalView
        )
      );
    }
    var isDateActive = 0;
    var viewAll = 1;
    if (filterStatus.length > 0) {
      clearStatusFilter(false);
    } else if (globalView == "calendarView"){
      jquery("#active_week_date").removeClass("c-gradinet-active-border");      
    }else {
      isDateActive = 1;
      viewAll = 0;
      addActiveBorderforWeekCalendar();
    }
    dispatch(
      setActiveTaskStatusFilters({
        filterStatus,
      })
    );
  };
  const spaceFilterOnStatus = (value, key) => {
    value = value.toString();
    let newArr = [...spaceFilter, value];
    if (spaceFilter.filter((x) => x == value).length) {
      newArr = spaceFilter.filter((c) => c != value);
    }
    setSpaceFilter(newArr);
    if(key === "my_spaces")
    {
      if(!projectFilters.my_spaces)
      {
        projectFilters.account_executive.push(adminUser);
        projectFilters.account_manager.push(adminUser);
        projectFilters.my_spaces = true;
      }
      else {
        projectFilters.account_executive = projectFilters.account_executive.filter((w) => w != adminUser);
        projectFilters.account_manager = projectFilters.account_manager.filter((w) => w != adminUser);
        projectFilters.my_spaces = false;
      }
    }
    else if (projectFilters[key])
    {
      if(projectFilters[key].includes(value))
      {
        projectFilters[key] = projectFilters[key].filter((w) => w.toString() != value.toString());
      }
      else {
        projectFilters[key].push(value);
      }
      if(projectFilters.my_spaces)
      {
        projectFilters.account_executive.push(adminUser);
        projectFilters.account_manager.push(adminUser);
      }
    }
    let allSpaces = spaces.filter(
      (space) => space.status == "0" || space.status == "1" || space.status == "2"
    );
    if (allSpaces.length > 0) {
      const targetSpace = allSpaces.filter(
        (office) => office.id === isOfficeSelected.id
      );
      const otherOffices = allSpaces.filter(
        (office) => office.id !== isOfficeSelected.id
      );
      var allOffices = targetSpace.concat(otherOffices);
      allOffices.sort(function (a, b) {
        if (parseInt(a.pin_count) < parseInt(b.pin_count)) return 1;
        if (parseInt(a.pin_count) > parseInt(b.pin_count)) return -1;
        if (!a.chat_group_message || a.chat_group_message === '') return 1;
        if (!b.chat_group_message || b.chat_group_message === '') return -1;
        const chatA = moment(a.chat_group_message_date ? a.chat_group_message_date : '');
        const chatB = moment(b.chat_group_message_date ? b.chat_group_message_date : '');
        if ((chatA).isBefore(chatB)) return 1;
        if ((chatA).isAfter(chatB)) return -1;
        if ((parseInt(a.notification_count) + parseInt(a.chat_notification_count) + parseInt(a.unread_notification_count)) > (parseInt(b.notification_count) + parseInt(b.chat_notification_count) + parseInt(b.unread_notification_count))) return -1;
        if ((parseInt(a.notification_count) + parseInt(a.chat_notification_count) + parseInt(a.unread_notification_count)) < (parseInt(b.notification_count) + parseInt(b.chat_notification_count) + parseInt(b.unread_notification_count))) return 1;
        if (a.project_id == isOfficeSelected.id) return -1;
        return 0;
      });
      let projectIdArray = [];
      if(searchMentionUsers.length)
      {
        projectIdArray = searchMentionUsers.reduce((commonProjects, obj) => 
        {
          const projects = obj.projects;
          return commonProjects.filter(project => projects.includes(project));
        }, searchMentionUsers[0].projects);
      }
      const myArrayFiltered = allSpaces.filter((el) => {
        let flag = true;
        if(projectIdArray.length && !projectIdArray.includes(el.project_id))
        {
          flag = false;
        }
        if(projectFilters["project_status"].length && !projectFilters["project_status"].includes(el.project_status))
        {
          flag = false;
        }
        if(flag)
        {
          return el;
        }
      });
      let appliedFilters = 0;
      Object.keys(projectFilters).forEach((key) => {
        if(projectFilters[key].length && !isNaN(projectFilters[key].length))
        {
          appliedFilters += projectFilters[key].length;
        }
      })
      if (appliedFilters > 0 || searchMentionUsers.length) {
        setActiveSpaces(myArrayFiltered);
      } else {
        setActiveSpaces(allOffices);
      }
    }
  };
  let onBoardingSpaces = spaceState;
  let getSearchedOffice = []
   getSearchedOffice = pageName === "SpaceOnBoarding"
    ?
    onBoardingSpaces
    :
    spacesArray && (spacesArray.length || projectFilters.project_status.length || searchMentionUsers.length)
      ? removeDuplicates(spacesArray.filter(
        (space) =>{
          removeDuplicates(spaceArray)
            if(searchString !== "" || spaceFilter.length > 1 || userType != "staff" ){
              return(
            (space &&
              space.office_title &&
              space.office_title
                .toLowerCase()
                .includes(searchString.toLowerCase())) ||
            (space &&
              space.postal_address &&
              space.postal_address
                .toLowerCase()
                .includes(searchString.toLowerCase())) ||
            (space &&
              space.name &&
              space.name
                .toLowerCase()
                .includes(searchString.toLowerCase())) ||
            (space &&
              space.company &&
              space.company
                .toLowerCase()
                .includes(searchString.toLowerCase())) 
                || (space && space.pin_count && space.pin_count == 1) 
          )
          } else{
             return( 
              selectedStaffMembersForSpaceFilter.length == 0 ? 
              userType == "staff" && space != undefined && (space.is_project_member > 0 || (space.pin_count && space.pin_count == 1)) && space.project_status != 7
             : spacesArray && space.project_status != 7 )
          }
        }
      ))
      : spaceState && spaceState.length > 0 && userType == "staff"  ? spaceState.filter((space)=>{
        return space.members.some((member)=> member.staff_id == localStorage.getItem("staff_id") ||
        (space && space.pin_count && space.pin_count == 1))
       }) 
       :[];
       if (getSearchedOffice && getSearchedOffice.length > 0 && Object.keys(selectedSpace).length > 0 && selectedSpace.project_id && (!projectFilters.project_status.length /* && !mentionStaffArray.length */)) {
        if (!getSearchedOffice.some((office)=> office.project_id == selectedSpace.project_id)) {
          getSearchedOffice = getSearchedOffice.concat(selectedSpace);
        }
       }
  useEffect(() => {
    if (isStatusCleared) {
      filterStatus = [];
      setFilterTaskActive([]);
    }
  }, [isStatusCleared]);
  useEffect(() => {
    if (activeTaskStatusFilters && activeTaskStatusFilters.length) {
      setFilterTaskActive(activeTaskStatusFilters);
      if (viewPage == "/" || isLeadPage) {
      }
    }
  }, [activeTaskStatusFilters]);
  useEffect(() => {
    dispatch(getCustomerSpaces(client_id, staffOrContactId));
    dispatch(fetchAuthToken(email, contact_id, "client"));
    if(pageName != "dashboardnew" && pageName != "MyEmail"){
      dispatch(getDefaultTopics(0, "", "", leftPanelTab == "my-request" ? "dashboardnew" : pageName));
    }
  }, []);
  useEffect(() => {
    if (leftPanelTab == "my-request" || leftPanelTab == "my-drive" ) {
      dispatch(setSelectedGloablView(globalView == "kanbanView" ? "kanbanView" : globalView == "calendarView" ? "calendarView" : "listView"));
      dispatch(leftPanelFilters("","","applyForMewithAll"));
      dispatch(setDaterangeFilter("all"))
      dispatch(setDaterangeFilterApplied(true));
    }
    if (leftPanelTab == "my-tenants" || leftPanelTab == "my-staff") {
      dispatch(setDaterangeFilter("all"))
      dispatch(setDaterangeFilterApplied(true));
    }
  }, [leftPanelTab])
  useEffect(() => {
    if (["my-request", "my-calendar"].includes(leftPanelTab)) {
      fetchTasksFromIDB(0,DEFAULT_MY_TASKS_PAGE_FILTER,[],[],dispatch)
    }
    if (localStorage.getItem("currentLeftPanelTab")) {
      dispatch(setLeftPanelTab( window.location.pathname == "/my-drive" ? "my-drive" : localStorage.getItem("currentLeftPanelTab")));
    }else{
      dispatch(setLeftPanelTab("homepage"));
      localStorage.setItem("currentLeftPanelTab", "homepage");
    }
    if ("my-calendar" == leftPanelTab) {
      dispatch(dayGridDay(false));
      dispatch(dayGridWeek(false));
      dispatch(dayGridMonth(true));
      dispatch(setDaterangeFilterApplied(true));
      dispatch(setDaterangeFilter("month"));
      dispatch(setSelectedDateView("monthView"));
      dispatch(leftPanelFilters("","","applyForMeAndMonth"));      
    }
  }, [leftPanelTab])
  const loginHandler = async (email, clientId, type, id, projectID,allData) => {
    setSelectedProject()
    const from_admin_side = 1
    localStorage.setItem("user_client_id",clientId);
    localStorage.setItem("user_project_id",project_id == 0 ? projectId : project_id);
    let userType = allData.user_type.toLowerCase() == "provider" ? "staff" : "contact";
    await checkAndCreateTables();

    authServices.getUserInfo(userType.toLowerCase(), id, from_admin_side).then(
      (res) => {
        if (res.status == 1 && res.data) {
          setSelectedProject()
          dispatch(setVirtualSpaceList([]));
          setSelectedTeam()
          localStorage.removeItem('selectedVirtualSpace')
            if (chatUserId != "" && chatUserId != undefined) {
              dispatch(userOffline({ userId: chatUserId }));
            }
            dispatch(showFullScreenLoader());

            dispatch(
              loginAsUser(email, clientId, project_id == 0 ? projectId : project_id)
            ).then(async(loginRes) => {
              if(loginRes.status == 1){
                await checkAndCreateTables();

                if (res.data.registration_confirmed == 0) {
                  localStorage.setItem("is_registration_confirmed", res.data.registration_confirmed);
                  localStorage.setItem("user_type", res.data.type);
                  localStorage.setItem("userId", res.data.id);
                }
                if (!localStorage.getItem("accessToken")) {
                  dispatch(
                    showMessage("unsucess", _l("l_error"), _l("l_invalid_credentials"))
                  );
                } else {
                  dispatch(showIDBDataFetchingLoader(true));
                  localStorage.setItem("password", "12345");
                  await loadDataToIDB(allData.staff_id);
                  if (localStorage.getItem("membership_package_id") == 1) {
                  navigate("/myprofile");
                  } else {
                    const client_id = localStorage.getItem("client_id");
                    const contact_id = localStorage.getItem("contact_id");
                    dispatch(getCustomerSpaces(client_id, contact_id));
                    dispatch(adminLoginAsStaff(true));
                    favoriteDdashboardSelected(loginRes.data.dashboard_name);
                    localStorage.setItem("fav_dashboard",loginRes.data.dashboard_name);
                    leftPanelFilters("","", "all")
                    dispatch(getGroupDetails({}))
                    dispatch(lastSessionOfChat([]))
                    dispatch(setSelectedGloablView("AichatView"));
                    navigate("/redirect", {
                      state: {
                        url: "/",
                      },
                    });
                  }
                }
                dispatch(leftPanelFilters("","","topicFilterClear"))
              }else{
                dispatch(showMessage("unsucess", _l("l_error"), loginRes.message));
              }
            });      
        }else{
          dispatch(showMessage("unsucess", _l("l_error"), res.message));
        }
      }
    ).catch(() => {
    });
  };
  const getUserList = async (client_id, project_id) => {
    const setDataToStateVariable = (data) => {
      if (data) {
        let customers =
          data.customers && data.customers.members
            ? data.customers.members
            : [];
        customers.sort(
          (a, b) =>
            b.is_primary - a.is_primary ||
            a.fullname.toLowerCase().localeCompare(b.fullname.toLowerCase())
        );
        let providers =
          data.providers && data.providers.members
            ? data.providers.members
            : [];
        providers.sort(
          (a, b) =>
            b.is_boss_operator - a.is_boss_operator ||
            a.fullname.toLowerCase().localeCompare(b.fullname.toLowerCase())
        );
        if (customers.length) {
          customers.forEach((customer, index) => {
            customer["type"] = data.customers.title;
            customer["user_type"] = data.customers.title;
          });
        }
        if (providers.length) {
          providers.forEach((provider, index) => {
            provider["type"] = data.providers.title;
            provider["user_type"] = data.providers.title;
          });
        }
        setUserList(customers.concat(providers).slice());
        if (providers.length == 0 && customers.length == 0) {
          setUserList([]);
        }
      }
    };
    const fetchListFromAPI = (updateLocalState = true) => {
      setIsLoginAsUserDataLoaded(false);
      CustomerServices.getDetailForLoginAsUser(client_id, project_id)
        .then((res) => {
          if (res.status && res.data) {
            if (updateLocalState) {
              setDataToStateVariable(res.data);
            }
            insertCollectionToDB(
              "login_as_user_list",
              [{ data: res.data, project_id }],
              "project_id"
            );
          }
        })
        .catch((e) => {
          console.error("Can not fetch login as users data....", e);
        })
        .finally(() => {
          setIsLoginAsUserDataLoaded(true);
        });
    };
    try {
      setIsLoginAsUserDataLoaded(false);
      const data = await readCollectionFromDB(
        "login_as_user_list",
        "project_id"
      );
      if (!data || !data.length) {
        fetchListFromAPI();
      } else {
        let currentProjectMemberData = data.find(
          (w) => w.project_id == project_id
        );
        if (currentProjectMemberData) {
          setDataToStateVariable(currentProjectMemberData.data);
          fetchListFromAPI(false); 
        } else {
          fetchListFromAPI();
        }
      }
    } catch (e) {
      fetchListFromAPI();
      console.error(e);
    } finally {
      setIsLoginAsUserDataLoaded(true);
    }
  };
  const closeSearchArea = () => {
      setShowBackDropClass(false);
      setSpaceSearchOnGoing(false);
  };
  useEffect(() => {
    let element = document.getElementById("modified_height");
    let element2 = document.body;
    const handleOfficeListOutsideClick = (e) => {
      if((officeListDivRef && officeListDivRef.current && officeSearchboxRef && officeSearchboxRef.current && !officeListDivRef.current.contains(e.target) && !officeSearchboxRef.current.contains(e.target) && officeListDivRef && topicSearch.current && officeSearchboxRef && officeSearchboxRef.current && !topicSearch.current.contains(e.target) && !officeSearchboxRef.current.contains(e.target)))
      {
        jquery("#intro-office-list").removeClass("searchbutton");
        closeSearchArea();
      }
    }
    document.addEventListener("click", handleOfficeListOutsideClick);
    if (
      noLeftPanel.indexOf(viewPage.replace("/", "")) > -1 ||
      noLeftPanelPages.indexOf(pageName) > -1
    ) {
      if (window.location.pathname != "/dashboard") {
        DefaultLeftPanel();
      }   
      document.addEventListener("mouseup", leftPanelMouseUpEvent);
      if (
        pageName != "Chat" &&
        pageName != "AddBenchmark" &&
        pageName != "documents" &&
        (projectId > 0 && projectId != "null") &&
        localStorage.getItem("is_dashboard") == 1
      ) {
        jquery(".office-box").removeClass("active");
      }
    }
    if (fullScrollPages.indexOf(pageName) > -1) {
      if (element && element2) {
        element.classList.add("modified-height", "modified-res-default-height");
        element2.classList.add("pb-0");
      }
    }
    return () => {
      document.removeEventListener("mouseup", leftPanelMouseUpEvent);
      document.removeEventListener("click", handleOfficeListOutsideClick);
      element = document.getElementById("modified_height");
      if (element && element2) {
        element.classList.remove("modified-res-default-height");
        element2.classList.remove("pb-0");
      }
    };
  }, [pageName,globalView]);
  useEffect(() => {
    if (pageName == "Dashboard" && globalView == 'listView' && window.location.pathname != "/dashboard") {
      showLeftPanelNew();
    }
  }, [pageName,globalView])
  useEffect(() => {
    setPreEstiPageDisable(false);
  }, [JSON.stringify(isPremiumEstimateEdit), JSON.stringify(isIBANModal)]);
  useEffect(() => {
    if (pageName == "MyEmail") {
      localStorage.setItem("email_filter_key", "inbox");
      localStorage.removeItem("email_filter_email");
    }
  }, []);
  useEffect(() => {
      CustomerServices.getMailSettings().then((res) => {
        if (res && res.status) {
          res.data.map((item) => {
            if (item["provider"] == "gmail" && setSynchronizedMail) {
              setSynchronizedMail("gmail");
            } else if (item["provider"] == "outlook_mail" && setSynchronizedMail) {
              setSynchronizedMail("outlook_mail");
            }
          });
        }
      });
  }, []);
  useEffect(() => {
    if (localStorage.getItem("selectedVirtualSpace")) {
      try {
        let virtualSPace = JSON.parse(
          localStorage.getItem("selectedVirtualSpace")
        );
        setSelectedTeam(
          virtualSPace && Object.keys(virtualSPace).length ? virtualSPace : {}
        );
        dispatch(getDefaultTopics(localStorage.getItem('selectedOffice'), "", "", pageName));
      } catch (error) {
        console.log(error);
      }
      jquery(".link-center-box-toggle").addClass("d-none");
    } else {
      setSelectedTeam();
      jquery(".link-center-box-toggle").removeClass("d-none");
    }
  }, [localStorage.getItem("selectedVirtualSpace")]);
  const selectAdminTeam = (team, is_remove = 0) => {
    dispatch(fullScreenLoaderChangeSpace(true));
    dispatch(setGloablSearchString(''))
    dispatch(lastSessionOfChat([]));
    TaskModalClose();
    dispatch(setSelectedDateView("dayView"));
    dispatch(setDaterangeFilterApplied(false));
    dispatch(selectedChatUser(undefined));
    dispatch(dayGridDay(true));
    dispatch(dayGridMonth(false));
    dispatch(dayGridWeek(false));
    dispatch(setShowWeekcalendardays(true));
    dispatch(setDaterangeFilter("today"));
    handleChangeViewtoFavView();
    localStorage.setItem("isLiteVersion", false);
    localStorage.setItem("generalChatForLiteVersion",false);
    dispatch(leftPanelFilters("","","all"));
    dispatch(getGroupDetails({}));
    dispatch(setFetchedTaskandMessages([]));
    dispatch(updateTaskList([]));
    localStorage.setItem("selectedDate", formateDate(new Date()));
    localStorage.removeItem("activeGroupId");
    dispatch(setClearmessage());
    if (is_remove) {
      handleOfficeChange(team);
    } else {
      setSelectedProject(team.id)
      localStorage.setItem("selectedVirtualSpace", JSON.stringify(team));
      localStorage.setItem("selected_project_client_id",team.id);
      setSelectedTeam(team);
      handleOfficeChange(team, true);
      if (pageName != "Chat") {
        navigate("/");
        dispatch(setSelectedGloablView("AichatView"));;
      }
    }
    if (contactlistShow) {
      contactDirTopicChange({}, true)
    }
    dispatch(accordionsEventKey(false));
  }; 
  const removeVirtualSpace = (flag = 0) => {
    if (flag && localStorage.getItem("prevSelectedOffice")) {
      localStorage.setItem("selectedOffice", localStorage.getItem("prevSelectedOffice"));
      localStorage.removeItem("prevSelectedOffice");
    }
    localStorage.removeItem("selectedVirtualSpace");
  };
  useEffect(() => {
    window.addEventListener('edit_wallet_settings', () => {
    });
  }, []);
  const [unreadComments, setUnreadComments] = useState(0);
  const [unreadMessages, setUnreadMessages] = useState(0);
  useEffect(() => {
    const totalMails = allMAilMessages ? allMAilMessages : [...(importantMessages || []), ...(inboxMessages || [])];
    let unread_comments = 0;
    let unread_messages = 0;
    (totalMails || []).map((email) => {
      unread_comments = unread_comments + (email['thread']['mentions'] || []).filter((comment) => comment['is_read'] == 0).length;
      unread_messages = unread_messages + ((email['conversation']['messages'].filter((reply) => reply['labelIds'] && reply['labelIds'].includes('UNREAD')) || []).length > 0 ? 1 : 0);
    });
    setUnreadComments(unread_comments);
    setUnreadMessages(unread_messages);
  }, [importantMessages, inboxMessages, statusClose, allMAilMessages]);
  const createChatChannels = (projectId = 0) => {
    dispatch(toastCloseAction());
    CustomerServices.createChatChannels(projectId).then((res) => {
      if (res.status) {
        dispatch(showMessage('sucess', "Success", res.message));
      } else {
        dispatch(showMessage("unsucess", _l("l_error"), res.message));
      }
    });
  };
  const getDataForProjectFilter = async() => {
    try {
      const response = await AdminServices.getMyRStaffList("0");
      if(response.status && response.data.length)
      {
        const accountExecutiveList = response.data.filter((w) => w.role == 3 && w.staffid != adminUser);
        const accountManagerList = response.data.filter((w) => w.role == 12 && w.staffid != adminUser);
        let props = [
          {
            key: "l_my_spaces", 
            id: -1,
            name: _l("l_my_space"),
            options: [],
            handler: spaceFilterOnStatus,
          },
          {
            key: "l_project_status", 
            handler: spaceFilterOnStatus,
            options: projectStatusArray.map((w) => {return {
                      ...w,
              }})
          },
          {
            key: "l_account_executive", 
            handler: spaceFilterOnStatus,
            options: accountExecutiveList.map((w) => {return {
                      ...w,
                      name: w.fullname,
                      id: w.staffid,
              }})
          },
          {
            key: "l_account_manager", 
            handler: spaceFilterOnStatus,
            options: accountManagerList.map((w) => {return {
                      ...w,
                      name: w.fullname,
                      id: w.staffid,
              }})
          },
        ];
        spaceFilterOnStatus("", "l_my_spaces");
      }
    }
    catch(e)
    {
      console.error(e);
    }
  }
  const getDashboardTopicsFromIDB = async() =>{
    let tempTopic = []
    const data = await readCollectionFromDB("topics", "project_id");
    if (data && data.length) {
      const currentProjectTopics = data.find((projectTopics) => projectTopics.project_id === '0');
      if (currentProjectTopics && currentProjectTopics.topics) {
        tempTopic = currentProjectTopics.topics.find((topics) => topics.main_key == "my_contact") && currentProjectTopics.topics.find((topics) => topics.main_key == "my_contact").categories.filter((category) => category.main_sub_key == "people" && category.filter_key != adminUser);
      }else{
        tempTopic = []
      }
    }
    dispatch(setTentantsEmployeeData(tempTopic));
  }
  useEffect(() => {
    var totalUnreadCount = 0;
    if (virtualSpaceList && virtualSpaceList.length > 0) {
      virtualSpaceList.map((item) => {
        totalUnreadCount += parseInt(item.unread_chat_msg_count) + parseInt(item.unread_notification_count);
        if (item.id == projectId) {
          dispatch(setTaskUnreadCmtCntAll(parseInt(item.unread_notification_count)));
        }
      });
    }
  }, [virtualSpaceList]);
  useEffect(() => {
    if((userType == "staff" || userType == "operator") && selectedSpace.client_id && selectedSpace.clientId != undefined){
      localStorage.setItem("virtualSpaceClientId", selectedSpace.client_id)
    }else if(projectId){
     let tempspace = spaceArray.filter((space)=>space.project_id == projectId)
     if(tempspace.length > 0){
      localStorage.setItem("virtualSpaceClientId", tempspace[0].client_id)
     }
    }
    if(userType == "staff" && spaceState.length == 0 && adminTeamList.length > 0 ){
      localStorage.setItem("virtualSpaceClientId", adminTeamList[0].id)
    }
  }, [selectedSpace , userType, projectId,adminTeamList,spaceState])
  useEffect(() => {
    CustomerServices.getCompanyEmployee().then((res) => {
      if (res && res.status && res.data) {
        let userLogin = [
          {
            "full_name" : localStorage.getItem("full_name"),
            "staff_id" : localStorage.getItem("staff_id"),
            "profile_image" : localStorage.getItem("contact_image"),
          }
        ]
        dispatch(getCompanyEmployeeData(userLogin.concat(res.data)));
        if (res.data && res.data.length == 0) {
          dispatch(isTenantsData(true));
        }else{
          dispatch(isTenantsData(false));
        }
      }
    });
    getDashboardTopicsFromIDB();
  }, []);
  useEffect(() => {
    if (leftPanelTab == "my-tenants") {
      localStorage.removeItem("selectedTopic");
      dispatch(setProject(0));
      getDashboardTopicsFromIDB();
    }
  }, [leftPanelTab])
  useEffect(() => {
    if (userType == "staff") {
      getDataForProjectFilter();
      if (clientTeamData && clientTeamData.myr_staff) {
        setEmployeeData(clientTeamData.myr_staff);
      }
    }
  }, [clientTeamData])
  useEffect(() => {
    if(pageName == 'dashboard' ){
      localStorage.removeItem("is_dashboard");
      localStorage.setItem("is_dashboard",0);
    }else if(pageName != 'LiteVersion' && globalView != "liteVersionView"){
      localStorage.removeItem("is_dashboard");
      localStorage.setItem("is_dashboard",1);
    }
    if(["PremiumSubscription", "Proposals", "MyEmail", "SpaceOnBoarding"].includes(pageName))
    {
      let menuName = "";
      switch(pageName)
      {
        case "PremiumSubscription":
          menuName = "plan";
          break;
        case "Proposals":
          menuName = "proposals";
          break;
        case "SpaceOnBoarding":
          if(window.location.href.includes("proposal"))
          {
            menuName = "proposals";
          }
          break;
        case "Proposals":
          menuName = "mail";
          break;
      }
    }
  },[]);
useEffect(() => {
  if (FavView == "AichatView" && pageName == "Dashboard" && globalView == "AichatView") {
    if(messagesStates && !messagesStates.idbDataLoader)
    {
      dispatch(fullScreenLoaderChangeSpace(false))
    }
    else {
      dispatch(fullScreenLoaderChangeSpace(true))
    }
  }else{
    dispatch(fullScreenLoaderChangeSpace(false))
  }
},[pageName, selectedOffice])
  useEffect(() => {
    generateThreadId();
  }, [taskFilters, globalView])
  useEffect(() => {
    if (localStorage.getItem("searchParamUserId") && ( !localStorage.getItem("is_auto_login") || localStorage.getItem("is_auto_login") == false)) {
      setSettingTopicName("l_drive");
      setTopicMainKey("documents");
    }
  }, [])
  const onClickOfficeChange = (space, isLoader) => {

    dispatch(setLeftPanelTab("my-space"));
    localStorage.setItem("currentLeftPanelTab", "my-space");
    navigate("/");
    // set default values of global state on space change
    dispatch(accordionsEventKey(false));
    dispatch(setGloablSearchString(''))
    dispatch(clearDefaultTopicsList())
    TaskModalClose();
    dispatch(setSelectedDateView("dayView"));
    dispatch(setDaterangeFilterApplied(false));
    dispatch(selectedChatUser(undefined));
    dispatch(dayGridDay(true));
    dispatch(dayGridMonth(false));
    dispatch(dayGridWeek(false));
    dispatch(setShowWeekcalendardays(true));
    dispatch(setDaterangeFilter("today"));
    handleChangeViewtoFavView();
    localStorage.setItem("isLiteVersion", false);
    localStorage.setItem("generalChatForLiteVersion",false);
    dispatch(leftPanelFilters("","","all"));
    dispatch(getGroupDetails({}));
    dispatch(setFetchedTaskandMessages([]));
    dispatch(updateTaskList([]));
    localStorage.setItem('selectedOffice', space.project_id);
    localStorage.setItem("selected_project_client_id",space.client_id);
    localStorage.setItem("selectedDate", formateDate(new Date()));
    localStorage.setItem("assistantCallerID", space.vapi_assistant_id);
    dispatch(setClearmessage());
    dispatch(updateTaskList([]));
    if (FavView == "AichatView" && isLoader !== false) {
      dispatch(fullScreenLoaderChangeSpace(true))
    }
    if((userType == "contact" || userType ==  "operator") && Number(contact_role) == 3){
      navigate("/");
      dispatch(setSelectedGloablView("AichatView"));
      handleChangeViewtoFavView()
    }
    if (globalView  != "AichatView") {
      localStorage.removeItem("activeGroupId");
    }
    if (
      localStorage.getItem(
        "membership_package_id"
      ) == 1
    ) {
      return;
    }
    if (projectId != space.project_id) {
      handleOfficeChange(space);
      if (pageName == 'Proposals') {
        navigate("/");
      }
    } else {
      jquery(".office-list").removeClass(
        "expanded"
      );
      setTeamLeftPanel(false);
      /*click on Selected space redirect  */
      if(localStorage.getItem("user_type") == 'contact'){
        if (pageName == "Chat") {
          navigate("/chat");
        }
      }else{
          navigate("/");
          dispatch(setSelectedGloablView("AichatView"));
      }
    }
    if (pageName != "Chat" && pageName !='LiteVersion') {
      jquery("body").removeClass(
        "with-right-penel"
      );
      showLeftPanelNew();
      jquery(".task-comment-modal").removeClass(
        "show-comments"
      );
      if (
        disablePages.indexOf(
          viewPage.replace("/", "")
        ) > -1 ||
        noLeftPanelPages.indexOf(pageName) > -1 ||
        isLeadPage
      ) {
        if(localStorage.getItem("user_type") == 'contact'){
          navigate("/");
          dispatch(setSelectedGloablView("AichatView"));;
        }else if (pageName !== "SpaceOnBoarding"){
          navigate("/");
          dispatch(setSelectedGloablView("AichatView"));;
        }
      }
    }else{
      hideLeftPanelNew();
      jquery(".provider-list").addClass("d-none")
    }
    dispatch(setOpenGeneralChatGroup(false));
    dispatch(clearDocumentData());
    if (contactlistShow) {
      contactDirTopicChange({}, true)
    }
    dispatch(accordionsEventKey(false));
    };
    const handleEditAdminTeam = (team, is_user_journey = '') => {
      if (is_user_journey == 'user_journey' || is_user_journey == 'my_people' || is_user_journey == 'task_topic') {
        CustomerServices.getTeamDetails(Math.abs(team)).then((res) => {
          if (res.status) {
            if (res.data) {
              const teamData = {
                id: res.data.id,
                members: res.data.team.map((x) => {
                  return {
                    member_id: x.member_id,
                    firstname: x.firstname,
                    lastname: x.lastname,
                  };
                }),
                teamTitle: res.data.name,
                manager_name: res.data.manager_name,
                manager_id: res.data.manager_id
              };
              setTopicType(is_user_journey)
              setSelectedTeamForEdit(teamData);
              setshowAddTeamMember(true);
            }
          } else {
            dispatch(
              showMessage("unsucess", _l("l_error"), res.message ? res.message : "")
            );
          }
        });
      } else {
        const teamData = {
          id: team.id,
          members: team.members.map((x) => {
            return {
              member_id: x.staffid,
              firstname: x.full_name,
              lastname: "",
            };
          }),
          teamTitle: team.name,
        };
        setSelectedTeamForEdit(teamData);
        setshowAddTeamMember(true);
      }
    };
    const handleFavoriteAdminTeam = (team) => {
      adminServices.setFavouriteVirtualSpace(team.id, team.is_selected == 0 ? 1 : 0).then((res) => {
        if (res.status) {
          jquery(".dropdown-menu").removeClass("show");
          let Arr = adminTeamList
          for (let i = 0; i < Arr.length; i++) {
            if (Arr[i].id == team.id) {
              Arr[i].is_selected = team.is_selected == 0 ? 1 : 0
            } else {
              Arr[i].is_selected = 0
            }
          }
          const sortArr = Arr.sort((a, b) => parseInt(b.is_selected) - parseInt(a.is_selected))
          dispatch(setVirtualSpaceList(sortArr));
          setAdminTeamList(sortArr);
        } else {
          dispatch(
            showMessage("unsucess", _l("l_error"), res.message ? res.message : "")
          );
        }
      });
    };
    const handleDeleteTeam = (data) => {
      CustomerServices.removeVirtualSpace(data.id).then((res) => {
        if (res.status) {
          setAdminTeamList(adminTeamList.filter((team) => team.id != data.id));
          dispatch(setVirtualSpaceList(adminTeamList.filter((team) => team.id != data.id)));
          dispatch(toastCloseAction());
          if (
            localStorage.getItem("selectedVirtualSpace") &&
            JSON.parse(localStorage.getItem("selectedVirtualSpace")).id == data.id
          ) {
            localStorage.setItem("selectedOffice",localStorage.getItem("prevSelectedOffice"));
            localStorage.removeItem("prevSelectedOffice");
            selectAdminTeam(
              { project_id: localStorage.getItem("prevSelectedOffice") },
              1
            );
            dispatch(
              getTopicTasks(
                localStorage.getItem("prevSelectedOffice"),
                "Dashboard"
              )
            );
            dispatch(
              getDefaultTopics(
                localStorage.getItem("prevSelectedOffice"),
                "",
                "",
                "Dashboard"
              )
            );
          }
          dispatch(
            showMessage("sucess", _l("l_success"), res.message ? res.message : "")
          );
        } else {
          dispatch(
            showMessage("unsucess", _l("l_error"), res.message ? res.message : "")
          );
        }
      });
    };
  const getspacedata = (e ,project_id)=>{
    CustomerServices.getSpaceDataOhover(project_id).then((res)=>
    {
      if(res.status)
      {
        setspaceData(res.data)
      }
    })
  }
  const selectLiteVersion = () => {
    DefaultLeftPanel();
    setLeftpanelToggleOnLiteversion(true);
    localStorage.setItem("isLiteVersion",true);
    jquery("body").removeClass("task-modal-offcanvas-open");
    if(pageName != "Dashboard" && pageName != "Chat"){
      navigate("/")
      dispatch(setSelectedGloablView("liteVersionView"))
    }else if( pageName == "Dashboard" && contact_role == 3){
      dispatch(leftPanelFilters("","","all"))
      dispatch(setSelectedGloablView("liteVersionView"))
    }
    setSelectedOffice(0)
    setSelectedProject(0)
    if (userType == "staff") { 
      localStorage.removeItem('selectedVirtualSpace')
    }
    if (pageName == "Chat") {
      setSelectedOffice(0)
      localStorage.setItem("selectedOffice",0);
    } else {
      if (projectId > 0) {
        handleChangeViewtoFavView()
        localStorage.setItem("selectedOffice",0);
        setSelectedProject(0)
    const savedDate = localStorage.getItem("selectedDate");
        dispatch(getTaskList(
          client_id,
          contact_id,
          0,
          moment(savedDate).format("YYYY-MM-DD"),
          1,
          activeTaskStatusFilters,
          taskFilters,
          "",
          0,
          1,
          "",
          "",
          "liteVersionView"
          ))
      } else {
        handleChangeViewtoFavView()
      }
    }
  }
  useEffect(() => {
    setTimeout(() => {
      setLeftpanelToggleOnLiteversion(false)
    }, 3000);
  },[leftpanelToggleOnLiteversion]);
  useEffect(() => {
    if (isAdmin == "true" && userType == "staff" && isAllChatUserlistShow) {
      jquery(".provider-list").addClass("expanded");
      showLeftPanelNew();
    }
  },[isAllChatUserlistShow]);
  const prepareToast = (className, messageBody, header) => {
    setShowToast(true);
    setToastHeader(header);
    setToastClass(className);
    setToastMessage(messageBody);
  };
  const handleSpaceDelete =() => {
    CustomerServices.deleteOrCancelSpace(officeId, project_id, estimateId,  3 ).then((res) => {
      if (res.status == 1) {
        dispatch(showMessage("sucess", _l('l_success'), res.message ? _l(res.message) : _l('l_space') + _l("l_delete") + _l('l_successfully')));
        dispatch(getCustomerSpaces(client_id, contact_id));        
      } else {
        dispatch(showMessage("unsucess", _l('l_error'), res.message ? _l(res.message) : _l('l_something_went_wrong')));
      }
    });
    setShowToast(false);
  }
  const handleChangeViewtoFavView  = () =>{
    let FavView = localStorage.getItem("fav_dashboard")
    FavView == "AichatView" ?
      dispatch(setSelectedGloablView("AichatView")) :
        FavView == "calendar" ?
          dispatch(setSelectedGloablView("calendarView"))
          : FavView == "my_spaces" ?
            dispatch(setSelectedGloablView("spaceView"))
            : FavView == "kanban_view" ?
              dispatch(setSelectedGloablView("kanbanView"))
              : FavView == "my_tasks" ? dispatch(setSelectedGloablView("listView"))
                : contact_role == 3 ? 
                 navigate("/") 
                   : dispatch(setSelectedGloablView("AichatView"));;
    FavView == "kanban_view" ? setIsKanbanView(true) :setIsKanbanView(false)
  }
  useEffect(() => {    
    CustomerServices.premiumEstimatePlan(project_id).then((res) => {
      if (res && res.status && res.data) {
        setEstimateId(res.data.estimate ? res.data.estimate.estimate_id : 0)
      }
    });
  }, [project_id])
  const handleVisibleMenus = () => {
    if(!menuOptionsFloat)
    {
      setMenuOptionsFloat(true);
      VISIBLE_MENUS = [];
      SPACE_LIST_RESULTS_PER_PAGE = 8;
    }
    else {
      setVisibleOptions();
      setMenuOptionsFloat(false);
      SPACE_LIST_RESULTS_PER_PAGE = 5;
    }
  };
  const setVisibleOptions = () => {
    switch(userType)
      {
        case "contact":
          VISIBLE_MENUS = CONTACT_MENU_OPTIONS;
          break;
        case "operator":
          VISIBLE_MENUS = OPERATOR_MENU_OPTIONS;
          break;
        default:
          VISIBLE_MENUS = STAFF_MENU_OPTIONS;
      }
  }
  const discardBenchmark = () => {
    localStorage.clear();
    localStorage.setItem("version", process.env.REACT_APP_VERSION);
    dispatch({
      type: USER_LOGOUT
    });
    setBenchmarkDiscardModal({});
    navigate("/login");
  };
  const proceedWithPendingPayment = async() => {
    try {
      setSubscriptionLoader(true);
      const email = localStorage.getItem("email");
      const subscriptionDetail = await DocumentsServices.getSubscriptionDetail(0, 0, pendingPaymentInfo.subscription_hash);
      const paymentMethod = subscriptionDetail.data.payment_mode;
      const response = await DocumentsServices.subscribe(
        pendingPaymentInfo.subscription_hash,
        "",
        email,
        1,
        pendingPaymentInfo.project_id,
        1,
        client_id,
        undefined,
        undefined,
        paymentMethod
      );
      if (response.status) {
        let pendingPayments = localStorage.getItem('pending_payments');
        pendingPayments = pendingPayments ? JSON.parse(pendingPayments) : [];
        pendingPayments = pendingPayments.filter((w) => w.subscription_id != pendingPaymentInfo.subscription_id);
        localStorage.setItem("pending_payments", JSON.stringify(pendingPayments));
        dispatch(getCustomerSpaces(client_id, contact_id));
        setPendingPaymentInfo({});
        if (response.data.site) {
          window.location.href = response.data.site;
        }
      }
    } catch (e) {
      console.error(e);
    } finally {
      setSubscriptionLoader(false);
    }
  };
  const generateThreadId = () => {
    //For Services
    const providerData = taskFilters.find((w) => w.main_key === "providers");
    if(providerData && adminArchieve && ["contact"].includes(userType) && globalView === "AichatView")
    {
      const topic = defaultTopics && defaultTopics.find((w) => w.main_key === "providers");
      if(topic && topic.categories.length)
      {
        topic.categories.forEach(async(category) => {
          if(category.filter_key == providerData.filter_key && category.assistant_id && category.assistant_id !== "" && (!category.thread_id || category.thread_id == "" ))
          {
            try {
              const response = await CustomerServices.generateAIThread(projectId, providerData.main_key, category.filter_key, category.main_sub_key);
              if(response.data)
              {
                dispatch(setTopicThreadId(response.data, providerData.main_key, category.filter_key, category.main_sub_key));
              }
            }
            catch(e)
            {
              console.error(e);
            }
          }
        });
      }
    }
    //For AI Assistant Topic for people section
    const peopleSectionData = taskFilters.find((w) => w.main_key === "my_contact");
    if(peopleSectionData && adminArchieve && ["contact"].includes(userType) && globalView === "AichatView")
    {
      const topic = defaultTopics && defaultTopics.find((w) => w.main_key === "my_contact");
      if(topic && topic.categories.length)
      {
        topic.categories.forEach(async(category) => {
          if((category.contact_role == 4 || category.contact_role == 3) && category.filter_key == peopleSectionData.filter_key && (!category.thread_id || category.thread_id == ""))
          {
            try {
              const response = await CustomerServices.generateAIThread(projectId, peopleSectionData.main_key, category.filter_key, category.main_sub_key);
              if(response.data)
              {
                dispatch(setTopicThreadId(response.data, peopleSectionData.main_key, category.filter_key, category.main_sub_key));
              }
            }
            catch(e)
            {
              console.error(e);
            }
          }
        });
      }
    }
  }
  useEffect(() => {
    //For search effect similar to global search in left panel
    if(spaceSearchOnGoing)
    {
      if(showBackDropClass)
      {
        jquery("#intro-office-list").addClass("expanded search-backdrop");
      }
      else {
        jquery("#intro-office-list").removeClass("expanded search-backdrop");
      }
    }
  }, [showBackDropClass])
  useEffect(() => {
    jquery(document).mouseup(function (e) {
     var container = jquery('.left-panel-company-setting');
     if (!container.is(e.target) && container.has(e.target).length === 0) {
       var container2 = jquery('.setting-menu-main');
       if (!container2.is(e.target) && container2.has(e.target).length === 0) {
         setSettingMenuRender(false);
       }
     }
   });
  })
  useEffect(() => {  
    jquery(document).mouseup(function (e) {
     var container = jquery('.new_user_profile_bottom');
     if (!container.is(e.target) && container.has(e.target).length === 0) {
       var container2 = jquery('.myprofilesetting-menu-main-close');
       if (!container2.is(e.target) && container2.has(e.target).length === 0) {
        setshowMyProfile(false);
       }
     }
   });
  })
  useEffect(() => {
    setContactlistShow(isContactDirChat);
    if (isContactDirChat) {
      showContactDirectory();
      dispatch(selectJobProposalPage(false));
    }else{
      dispatch(setOpenOrCloseContactDirectoryChat(false));
    }
  }, [isContactDirChat])
  useEffect(() => {
    if (leftPanelTab != "my-space" && window.location.pathname != "/myprofile") {
      setSelectedSpace({});
      setSelectedOffice(0);
      setSelectedProject(0);
    dispatch(setProject(0));
      localStorage.removeItem("selectedOffice");
    }
    if (leftPanelTab == "my-space") {
      setShowSpaceList(true);
    }
  }, [leftPanelTab])
  useEffect(() => {
    const checkEmailSync = async () => {
      let syncString = "";
      try {
        const response = await CustomerServices.getMailSettings();
        if (response.status) {
          response.data.map((item) => {
            if (item["provider"] == "gmail") {
              syncString = "gmail";
              setisSynced("gmail");
            } else if (item["provider"] == "outlook_mail") {
              syncString = "outlook_mail";
              setisSynced("outlook_mail");
            }
          });
        }
      } catch (e) {
        console.error(e);
      } finally {
        return syncString;
      }
    };
    checkEmailSync()
    if (localStorage.getItem("user_type") == "operator") {      
      customerServices.getProvidersAgents().then((res)=>{
        if (res && res.status) {
          dispatch(setProviderAgnetList(res.data))
        }
      })
      customerServices.getProvidersClients().then((res)=>{
        if (res && res.status) {
          dispatch(setProvideClientList(res.data))
        }
      })
    }
  }, [])
  const handleChatGroup = (group) => {
    localStorage.setItem("activeGroupId", group.groupId)
    dispatch(getGroupDetails({}));
    dispatch(setFetchedTaskandMessages([]));
    chatServices.getGroupDetails(chatUserId, group.groupId).then((res) => {
      if (res.success) {
        var activeGroupId = localStorage.getItem("activeGroupId");
        if (chatUserId != "" && activeGroupId != "" && activeGroupId != undefined) {
          var socketDataIn = {
            groupId: activeGroupId,
            userId: chatUserId,
          };
            dispatch(groupIn(socketDataIn));
            dispatch(fetchMessages( 
              localStorage.getItem("chatUserId"), 
              group.groupId,
              0,
              ()=>{},
              1,
              "",
              "",
              "",
              group.unread_count > 0 ? group.unread_count : 20,
          ));  
        }
        let groupWrapper = {...res.result, groupName : group.groupName};
          dispatch(getGroupDetails(groupWrapper))
          localStorage.setItem("getGroupDetails", JSON.stringify(groupWrapper))
      } else {
        dispatch(showMessage("unsucess", _l("l_error"),res.message));
      }
    });
  }
  const contactList = (groupListExternal,  searhContact) => {
    const filteredCategories = groupListExternal.filter((contact) => {
      const isTitleMatch = contact.groupName.toLowerCase().includes(searhContact.toLowerCase());
      return isTitleMatch;
    });
    return filteredCategories;
  };
  const contactDirTopicChange = (group, fromspace) => {
    showLeftPanelNew()
    if (fromspace == false) {
     if (!contactlistShow) {
      setSelectedSpace({});
      if (!is_registered) {
        dispatch(leftPanelFilters("","","all"))
        jquery("body").addClass("with-left-penel");
      }else{
        showLeftPanelNew()
      }
      setContactlistShow(true)
      dispatch(setOpenOrCloseContactDirectoryChat(true));
      dispatch(getGroupDetails({}))
      dispatch(setFetchedTaskandMessages([]))
      dispatch(updateTaskList([]))
      dispatch(setClearmessage());
      dispatch(clearChatTaskList());
      navigate("/");
      dispatch(setSelectedGloablView("AichatView"))
      dispatch(SetSelectedTask({}));
      TaskModalClose();
      dispatch(setBulkTaskRating([]))
      setSelectedProject(0)
      dispatch(contactDirCount(group.groupId))
      localStorage.setItem("selectedTopic",JSON.stringify(group))
      localStorage.setItem("isContactDir", 1)
      localStorage.removeItem('activeGroupId')
     } else {
      if (Object.keys(group).length) {
        handleChatGroup(group)
      }
      dispatch(contactDirCount(group.groupId))
      localStorage.setItem("selectedTopic",JSON.stringify(group))
     }
    } else {
      setContactlistShow(false)
      dispatch(setOpenOrCloseContactDirectoryChat(false));
      localStorage.removeItem("isContactDir")
    }
  } 
  useEffect(() => {
    if (leftPanelTab == "my-email") {
      customerServices.getEmailTokenActive().then((res) => {
        if (res.status && res.data.status) {
          dispatch(emailTokenExpired(false));
        }else{
          dispatch(emailTokenExpired(true));
        }
      })
    }
    if (leftPanelTab !== "my-providers") {
      localStorage.removeItem("currentCompanyID")
    }
    if (leftPanelTab == "my-request") {
      dispatch(getDefaultTopics(0, "", "", pageName,taskFilters));
    }
    dispatch(SetSelectedTask({}));
  }, [leftPanelTab])
  const getAssistantTaskDetails = async(taskID) =>{
    try {
      let response = await customerServices.getTasklistForChat(
        localStorage.getItem('selectedOffice'),
        [taskID]
      )
      if (response && response.status && response.data && Object.keys(response.data).length > 0) {
        setAssistantTaskDetails(response.data[0]);
        dispatch(getTaskChecklist(taskID, localStorage.getItem('selectedOffice'))); 
        dispatch(setApiLoaderShow(false));
        setShowAssistantTask(true);
        TaskCommentModalOpen();
      }else{
      dispatch(setApiLoaderShow(false));
      }
    } catch (error) {
    }
  }
  const getTaskID = () => {
    let counter = localStorage.getItem("currentCount") ? JSON.parse(localStorage.getItem("currentCount")) : 0;
    customerServices.getTaskIdOfVapiAssistantCall(
       localStorage.getItem('selectedOffice'),
       callID
     ).then((res)=>{
      if (res && res.status && typeof res.data === 'object' && res.data.task_id) {
        getAssistantTaskDetails(res.data.task_id)
      } else if(counter < 51 && isGetTaskId) {
        setTimeout(() => {;
          localStorage.setItem("currentCount", counter + 1)
          getTaskID();
        }, 2000);
      }
      else{
        setShowAssistantTask(false);
        dispatch(setApiLoaderShow(false));
        TaskModalClose();
        if (leftPanelTab == "homepage") {
          addexpandedClassIntoOffice();
        }else{
          showLeftPanelNew();
        }
      }
     })
    }
  useEffect(() => {
    if (isGetTaskId) {
      getTaskID();
      setAssistantTaskDetails({
         name : "please wait Task is generating ....."
      });
      dispatch(setApiLoaderShow(true));
    }else{
      localStorage.removeItem("currentCount");
    }
  }, [isGetTaskId])
  useEffect(() => {
   if (localStorage.getItem('chatAccessToken')) {
    if (Object.keys(chatSocket).length == 0) {
      dispatch(connectToSocket())
    }
   }
  }, [chatSocket])
  if (!commanoffcanvasIsOpen) {
    localStorage.removeItem('uniqueTaskId');
  }
  
  useEffect(() => {
    if(localStorage.getItem("selectedOffice")) return;
    let totalTaskCount = 0;
    let totalChatCount = 0;

    defaultTopics.filter((item) => item.main_key === "my_staff").forEach((item) => {
      item.categories.forEach((category) => {
        const { task_count, chat_count } = category.total_notification_count;
        totalTaskCount += task_count;
        totalChatCount += chat_count;
      });
    });

    setStaffChatCounts(totalTaskCount + totalChatCount);
  }, [defaultTopics]);

  if (!jquery.isEmptyObject(newMessage)) {
    let activeGroupId = localStorage.getItem("activeGroupId");

    const updateChatData = defaultTopics && defaultTopics.map((items) => {
      return {
        ...items,
        categories: items.main_key == "my_staff" ? items.categories && items.categories.map((i) => {
          const isData = i.chat_data && i.chat_data.groupId && i.chat_data.groupId == newMessage.group
          return {
            ...i,
            total_noti_count: isData ? Number(i.total_noti_count) + 1 : i.total_noti_count,
            chat_data: {
              ...i.chat_data,
              lastMessage: isData ? newMessage.message : i.chat_data && i.chat_data.lastMessage ? i.chat_data.lastMessage : "",
              lastMessageDate: isData ? newMessage.createdAt && newMessage.createdAt : i.chat_data && i.chat_data.lastMessageDate ? i.chat_data.lastMessageDate: "",
              lastMsgUserName: isData ? newMessage.senderName ? newMessage.senderName : "" : i.chat_data && i.chat_data.lastMsgUserName ? i.chat_data.lastMsgUserName : ""
            },
            total_notification_count: {
              ...i.total_notification_count,
              chat_count: isData && newMessage.group != activeGroupId ? Number(i.total_notification_count.chat_count) + 1 : i.total_notification_count.chat_count ? i.total_notification_count.chat_count : 0
            }
          }
        })
        : items.categories
      }
    })

    if (newMessage.projectId == 0) {
      const updateTenantData = tenantsEmployeeData && tenantsEmployeeData.map((tenant) => {
        if (tenant && tenant.chat_data && tenant.chat_data.groupId == newMessage.group) {
          tenant.chat_data.lastMessageDate = new Date();
          tenant.chat_data.lastMessage = newMessage.message;
          tenant.chat_data.lastMsgUserName = newMessage.senderName;
          tenant.chat_data.unreadCount = activeGroupId == newMessage.group ? 0 : tenant.chat_data.unreadCount + 1;
        }
        return tenant
      })
      dispatch(setTentantsEmployeeData(updateTenantData));
      if (newMessage.group != activeGroupId) {
        dispatch(playMsgAudio());
      }
      if (newMessage.projectId == 0 && leftPanelTab == "my-tenants") {
        updateBurgerMenuIDB('0', "my_contact", updateTenantData)
      }
    }

    dispatch(updatedDefaultTopics(updateChatData));

    dispatch(updateNewMessage());
  }

  useEffect(() => {
    if(localStorage.getItem("selectedOffice")) return;
    let totalCount = 0;

    tenantsEmployeeData && tenantsEmployeeData.map((item) => {
      totalCount += item && item.chat_data && item.chat_data && item.chat_data.unreadCount ? item.chat_data.unreadCount : 0; 
    });

    setTenentsChatCounts(totalCount);
  }, [tenantsEmployeeData]);

  useEffect(() => {
    if (leftPanelTab == "my-staff") {
      const getIDBStaffData = async () => {
        const IDBData = await readCollectionFromDB("topics", "project_id");

        if (IDBData && IDBData.length) {
          const CurrProject = IDBData.find((items) => items.project_id == 0);

          if (CurrProject && Object.keys(CurrProject).length && CurrProject.topics) {
            dispatch({
              type: GET_DEFAULT_TOPICS,
              payload: CurrProject.topics,
            });
          }
        }
      };

      getIDBStaffData();
    }
  }, [leftPanelTab]);  

  return (
    <>
       <div id="left-panel-main" className={`fixed-left-panel ${ showMyProfile ? "z-index-21" : ""}`} ref={panelRef}>
           { ((!DEFAULT_SUB_MENU_PAGES.includes(pageName) && ["homepage","my-clients", "my-providers", "equipments", "reporting-page"].includes(leftPanelTab) || (emailTokenVaild && leftPanelTab === "my-email") || (isTenantsDataVaild && leftPanelTab === "my-tenants" )) && (showSpaceList == false)) ?  
           <LeftPanelMain
            setshowMyProfile={setshowMyProfile}
            showMyProfile={showMyProfile}
            setSelectedOffice={setSelectedOffice}
            contactlistShow={contactlistShow}
            setContactlistShow={setContactlistShow}
            contactDirTopicChange={contactDirTopicChange}
            setSettingTopicName={setSettingTopicName}
            setTopicMainKey={setTopicMainKey}
            handleChangeViewtoFavView={handleChangeViewtoFavView}
            isSynced={isSynced}
            setShowSpaceList={setShowSpaceList}
            staffChatCounts={staffChatCounts}
            tenentsChatCounts={tenentsChatCounts}
           /> :
            <LeftPanelSubMenu
            setCompletedTasksCount={setCompletedTasks}
            setInProgressTasksCount={setInProgressTasks}
            setTodoTasksCount={setTodoTasks}
            projectId={projectId}
            searchCategory={searchCategory} 
            pageName={pageName}
            teamLeftPanel={teamLeftPanel}
            setSelectOperators={setSelectOperators}
            setShowScheduleDropDown={setShowScheduleDropDown}
            setShowProviderDropDown={setModal1show}
            allMAilMessages={allMAilMessages}
            allImportantMessages={allImportantMessages}
            hideMailSyncBtn={hideMailSyncBtn}
            createTeamModal={createTeamModal}
            setCreateTeamModal={setCreateTeamModal}
            equipmentModal={equipmentModal}
            setEquipmentModal={setEquipmentModal}
            selectAdminTeam={(team, is_remove) => selectAdminTeam(team, is_remove)}
            selectedTeam={selectedTeam}
            clientTeamData={clientTeamData}
            handleDeleteTeam={handleDeleteTeam}
            handleFavoriteAdminTeam={handleFavoriteAdminTeam}
            handleEditAdminTeam={handleEditAdminTeam}
            topicType ={topicType}
            setTopicType ={setTopicType}
            selectedTeamForEdit ={selectedTeamForEdit}
            setSelectedTeamForEdit ={setSelectedTeamForEdit}
            showAddTeamMember ={showAddTeamMember}
            setshowAddTeamMember ={setshowAddTeamMember}
            adminTeamList ={adminTeamList}
            setAdminTeamList ={setAdminTeamList}
            showUserList={isAllChatUserlistShow}
            searchCategoty={searchCategoty}
            searchTopic={searchTopic}
            showForMeSection={showForMeSection}
            setShowForMeSection={setShowForMeSection}
            spaces={getSearchedOffice}
            setSearchString={setSearchString}
            searchString={searchString}
            setshowMyProfile={setshowMyProfile}
            showMyProfile={showMyProfile}
            projectTaskCount={projectTaskCount}
            selectedOffice={selectedOffice}
            onClickOfficeChange={onClickOfficeChange}
            setSelectedOffice={setSelectedOffice}
            setSelectedProject={setSelectedProject}
            selectedSpace={selectedSpace}
            setSelectedSpace={setSelectedSpace}
            contactDirData={contactList(groupListExternal, searchTopic)}
            contactDirTopicChange={contactDirTopicChange}
            contactlistShow={contactlistShow}
            setContactlistShow={setContactlistShow}
            setsearchTopic={setsearchTopic}
            getspacedata={getspacedata}
            spaceData={spaceData}
            setUserList={setUserList}
            admin={admin}
            adminUser={adminUser}
            contact_role={contact_role}
            createChatChannels={createChatChannels}
            setproject_id={setproject_id}
            setshowProjectStatusList={setshowProjectStatusList}
            getSearchedOffice={getSearchedOffice}
            getUserList={getUserList} 
            setClientIdLoginAsUser={setClientIdLoginAsUser}
            setShowDocumentCanvas={setShowDocumentCanvas}
            setManagePeopleModal={setManagePeopleModal}
            setshowOpenAiModal={setshowOpenAiModal}
            setspaeContent={setspaeContent}
            setOfficeId={setOfficeId}
            setShowToast={setShowToast}
            prepareToast={prepareToast}
            setSelectedspaceStatus={setSelectedspaceStatus}
            showProjectStatusList={showProjectStatusList}
            projectStatusArray={projectStatusArray}
            userList={userList}
            setTopicMainKey={setTopicMainKey}
            setSettingTopicName={setSettingTopicName}
            handleChangeProjectStatus={handleChangeProjectStatus}
            loginHandler={loginHandler}
            selectedspaceStatus={selectedspaceStatus}
            isLoginAsUserDataLoaded={isLoginAsUserDataLoaded}
            handleChangeViewtoFavView={handleChangeViewtoFavView}
            isSynced={isSynced}
            tenantesList={employeeData}
            setEmailModalOpenSet = {setEmailModalOpenSet}
            showSpaceList={showSpaceList}
            setShowSpaceList={setShowSpaceList}
            staffChatCounts={staffChatCounts}
            tenentsChatCounts={tenentsChatCounts}
            /> }     
        <AddEditCategory projectId={projectId} />
        {showScheduleDropDown ? (
          <ScheduleDropDown show={showScheduleDropDown} staff={selectOperators} modal1handleClose={() => {
            setShowScheduleDropDown(false)
          }} />
        ) : (
          <></>
        )}
        {Modal1show ? (
          <>
            <ProviderDropdownWithprogressBar show={Modal1show}
              modal1handleClose={() => {
                setModal1show(false)
              }}
              staff={selectOperators} />
          </>
        ) : (
          <></>
        )}
        {showDocumentCanvas && onboardDocument ? (
            <CommanOffCanvas
              show={showDocumentCanvas}
              handleClose={() => {
                setShowDocumentCanvas(false);
              }}
              pinHandler={(id, type, flag) => {
              }}
              data={onboardDocument}
              setData={()=> {}}
              docType={"pilotonboarding"}
              setSelectedId={() => {}}
              isFullScreen={true}
            />
          ) : (
            <></>
          )}
        {
          showMyProfile ? 
          ( <MyProfileDropDownNew 
            contactlistShow = {contactlistShow}
            setContactlistShow = {setContactlistShow}
            setSelectedProject = {setSelectedProject}
            selectLiteVersion = {selectLiteVersion}
            disabledThirdPartyToolAccess = {disabledThirdPartyToolAccess}
            setSettingTopicName = {setSettingTopicName}
            setTopicMainKey= {setTopicMainKey}
            unreadComments = {unreadComments}
            unreadMessages = {unreadMessages}
            setSettingMenuRender = {setSettingMenuRender}
            SettingMenuRender = {SettingMenuRender}
            estimateDetails = {estimateDetails}
            viewPage = {viewPage}
            proposalUnreadComments = {proposalUnreadComments}
            setshowMyProfile={setshowMyProfile}
            setSelectedSpace = {setSelectedSpace}
            handleChangeViewtoFavView={handleChangeViewtoFavView}
            />
          ) : (<></>)
        }
        {
          managePeopleModal ?
          <ManagePrjoectMember 
          show={managePeopleModal}
          handleClose={() => {setManagePeopleModal(false)}}
          project_id={project_id}
          pageName={pageName}
          />
          :
          <></>
        }
        {
          emailModalOpenSet ?
          <AddNewCategory 
          show={emailModalOpenSet}
          handleClose={() => {setEmailModalOpenSet(false)}}
          project_id={project_id}
          pageName={pageName}
          activeTopicKey="addEmail"
          />
          :
          <></>
        }
        {
          showOpenAiModal ?
        <OpenAi
        show={showOpenAiModal}
        handleClose={()=> {setshowOpenAiModal(false)}}
        project_id={project_id}
        pageName={pageName}
        spacecontent={spacecontent}
        /> :<></>
        }
        {
          settingTopicName
          ?
          <TopicSettingModal 
            data={{main_key:topicMainKey, id: 1}} 
            handleClose={() => { 
              if (settingTopicName == 'l_drive' && topicMainKey == 'documents') {
                dispatch(setLeftPanelTab(localStorage.getItem('lastLeftPanelTab')));
              }
              setSettingTopicName(""); 
              if (localStorage.getItem("searchParamUserId")){
                localStorage.removeItem("searchParamUserId")
              }
            }} 
            show={settingTopicName.trim().length} 
            title={settingTopicName} 
            hideFilterArea={["third_party_integrations", "edit_user"].includes(topicMainKey)}
            hideSearchArea={["edit_user"].includes(topicMainKey)}
            fromDrive={settingTopicName == "l_drive" ? true : false}
            />
          :
          <React.Fragment></React.Fragment>
        }
        {
          showStaffIBANSetup
          ?
            <StaffIbanSetup handleClose={() => {setShowStaffIBANSetup(false)}} show={showStaffIBANSetup} />
          :
            <></>
        }
          {showToast ? (
        <Toasts
          delay={2500}
          handler={() => {
            setShowToast(false);
          }}
          header={toastHeader}
          message={toastMessage}
          toastClass={toastClass}
          cancelButtonHandler={() => {
            setShowToast(false);
          }}
          cancelButtonText={_l("l_cancel")}
          confirmButtonHandler={handleSpaceDelete}
          confirmButtonText={_l("l_delete")}
        />
      ) : (
        <></>
      )}
      </div>
      {
        spaceInvitationData.project_id > 0
        ?
          <AlertModal handleClose={closeInvitationAlert} message={spaceInvitationData.message} buttonText={"l_ok"} icon={{name: CelebrationIcon}} />
        :
          <React.Fragment></React.Fragment>
      }
      {
        benchmarkDiscardModal.project_id > 0 ?
        <AlertModal handleClose={() => {navigate(`/create-benchmark?space=${benchmarkDiscardModal.project_id}&provider-type=external-benchmark&id=${benchmarkDiscardModal.schedule_id}`);}} message={"l_external_benchmark_discard_alert"} buttonText={"l_continue"} icon={{name: SignInIcon}} secondaryButtonHandler={discardBenchmark} secondaryButtonText={"l_login"} />
        :
        <React.Fragment></React.Fragment>
      }
      {userType === "operator" &&
      pendingPaymentInfo &&
      pendingPaymentInfo.subscription_id ? (
        <PaymentModal
          address={pendingPaymentInfo.address}
          companyName={pendingPaymentInfo.company_name}
          amount={pendingPaymentInfo.subscription_amount}
          handleClose={() => {navigate("/")}}
          handleSave={proceedWithPendingPayment}
          show={pendingPaymentInfo.subscription_id > 0}
          loader={subscriptionLoader}
        />
      ) : (
        <React.Fragment></React.Fragment>
      )}
     { showAssistantTask ? 
            <CommanOffCanvas
                show={showAssistantTask}
                handleClose={()=>{
                  setShowAssistantTask(false)
                }}
                data={assistantTaskDetails && Object.keys(assistantTaskDetails).length > 0 ? assistantTaskDetails : {}}
                docType={"task"}
                pageName={"task"}
                setSelectedId={()=>{}}
                iscallModal={false}
                handleParentComponentAction={()=>{}}
                isFullScreen={true}
            />
            : <></>}
    </>
  );
};
export default LeftPanel;
