import React, { useEffect, useState } from 'react'
import StepIcon from "../../assets/icons/StepIcon";
import jQuery from "jquery";
import {
    TaskCommentModalOpen,
    _l,
    CloseCommentModal,
} from "../../hooks/utilities";
import { getComments } from '../../actions/documents-actions/documents-actions';
import { useDispatch } from 'react-redux';
import { getLeadActivities } from '../../actions/leads-actions/leads-actions';
import { useParams } from "react-router-dom";
import FloorFilterDropdown from '../Dropdowns/FloorFilterDropdown';
import CommanFooterPlus from '../CommanFooterPlus';
import ChatCircleIcons from '../../assets/icons/ChatCircleIcons';
function CommonFooter({ mentionList, count, type, handleAction, data, isAllowToEdit, docId, setShowCommentModal, ShowCommentModal, setShowCommentSection, setType, isExternal, spaceData,showFullscreen=false, selectedAttachment = undefined }) {
    const dispatch = useDispatch();
    const [unreadCommentCount, setUnreadCommentCount] = useState(0);
    const { id: hash_id, page } = useParams();
    useEffect(() => {
        if (data) {
            setUnreadCommentCount(data.unreadCommentCount)
        }
    }, [JSON.stringify(data), type])
    useEffect(() => {
        jQuery("#comment_close").on("hide", function () {
            setShowCommentModal(false)
        });
    }, [])
    useEffect(() => {
        if (type == 'lead') {
            dispatch(getLeadActivities(docId));
        }
        else {
            if(localStorage.getItem("isAttachPreview") && JSON.parse(localStorage.getItem("isAttachPreview")) == true) {

            } else {
                // When task attachment opens that comment api call issue    
                // dispatch(getComments(type == "leaseDocument" && selectedAttachment && selectedAttachment.id ? selectedAttachment.id : docId, type == "request-task" ? "task" : type, 0, hash_id,1 ));
            }
        }
    }, [docId, selectedAttachment.id]) 

    useEffect(() => {
        if((JSON.parse(localStorage.getItem("isAttachPreview")) == false || localStorage.getItem("isAttachPreview") == null) && type != 'lead') {
            dispatch(getComments(
                type == "leaseDocument" && selectedAttachment && selectedAttachment.id ? selectedAttachment.id : docId, 
                type == "request-task" ? "task" : type, 
                0,
                hash_id,
                1,
                0,
                0,
                0,
                data.is_app
                 ));
        }
    }, [localStorage.getItem("isAttachPreview"), docId, selectedAttachment.id]);
    useEffect(()=>{
        if(['task', 'benchmark', 'proposal', 'schedule','lead','request-task', 'invoice', 'invoice', 'estimate', 'credit_notes', 'subscription', 'documents', 'spaceDocument' ].includes(type)){
            if (!ShowCommentModal) {
                TaskCommentModalOpen();
                handleAction('getComment', '')
                setShowCommentModal(true);
            } 
        }else if(['pilotonboarding'].includes(type)){
            if (ShowCommentModal) {
                CloseCommentModal();
                setShowCommentSection(false);
                setShowCommentModal(false);
            }
        }
    },[type]);
    return (
        <div className={`d-flex justify-content-end align-items-end w-100 ${isExternal && type == "spaceDocument" ? "" :"ps-3"}`}>
            {isExternal && type == "spaceDocument"  ? (
                <div className={`comman_action_icon btn-group dropup me-auto ${isExternal && type == "spaceDocument" ? "z-index-11" : "z-index-5"}`}>
                    <a href="#/"
                        className="action_icon with_bg position-relative w55 h46 box-shadow-2 on-hover-active-toggle-img radius_3 "
                        type="button"
                        id="FloorFilterDropdown"
                        data-bs-toggle="dropdown"
                        data-bs-auto-close="outside"
                        aria-expanded="false"
                    >
                        <StepIcon className="HW20" />
                    </a>
                    <FloorFilterDropdown isExternal={isExternal} data={spaceData} />
                </div>) : <></>}
            {ShowCommentModal ? (
                <></>
            ) : (
                <>
                </>
            )}
            { window.location.pathname === "/providerlist" ? <></> : (showFullscreen && !isExternal) ?
            <CommanFooterPlus MainClass="with-position" showFullscreen={showFullscreen} id="commanFooterHandle" toggleId='commanFooterHandleShow' docType={type}/> : <></>}
            {ShowCommentModal ? "" : 
            <div
                className={`comman_action_icon paper-clip-file mb-1 me-10px ps-3 h-100  ${type == "task" || type == "request-task"  ? "with-absolute-bottom-160" :""}`}
            >
                <a href="#/"
                    className="action_icon  position-relative w55 h46 radius_3 with-comment-close bg-white-03-solid" id={`comment_close`}
                    onClick={() => {
                        const proposalId = localStorage.getItem("proposalId");
                        localStorage.setItem("proposalId", "")
                        setType("")
                        if (!ShowCommentModal || proposalId) {
                            if(proposalId)
                            {
                                localStorage.setItem("preventCommentSectionToggle", true);
                            }
                            TaskCommentModalOpen();
                            handleAction('getComment', '')
                            setShowCommentModal(true);
                        } else {
                            CloseCommentModal();
                            setShowCommentSection(false);
                            setShowCommentModal(false);
                        }
                    }}
                >
                    {unreadCommentCount > 0 ? (
                        <span
                            className=" rounded-pill badge-custom d-flex"
                            style={{
                                position: "absolute",
                                top: "-10px",
                                right: "-10px",
                                height: "20px",
                                width: "20px",
                                padding: "0px",
                            }}
                        >
                            {unreadCommentCount}
                        </span>
                    ) : (
                        <></>
                    )}
                    <div className="d-flex align-items-center justify-content-center w-100">
                        <ChatCircleIcons className="HW18 m-0" />
                        <span className="c-font f-12 title-fonts fw-semibold">{count}{" "}</span>
                    </div>
                </a>
            </div>
            }
        </div>
    )
}
export default CommonFooter