import React from 'react'

const InvoiceIcon = (props) => {
    const height = (props.height) ? props.height : "13";
	const width = (props.width) ? props.width : "16";
	const viewBox = (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW = (props.className) ? props.className : "";
  return (
    <>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons ${CommanHW}`}>
<g clip-path="url(#clip0_3936_141874)">
<path d="M6.4758 4.23376V4.87427M9.67839 8.71741V9.35796M9.67839 4.23376L6.4758 9.35796M16.4039 19.6063H3.59344C2.53207 19.6063 1.67188 18.7461 1.67188 17.6847V1.67168L2.95289 0.390625L4.23395 1.67168L5.51501 0.390625L6.79607 1.67168L8.07709 0.390625L9.35815 1.67168L10.6392 0.390625L11.9202 1.67168L13.2013 0.390625L14.4823 1.67168V17.6847C14.4823 18.7461 15.3425 19.6063 16.4039 19.6063Z" stroke="currentColor" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M4.23438 11.9213H11.9207M4.23438 17.0455H11.9207M4.23438 14.4833H9.35857M18.3259 17.686C18.3258 18.1956 18.1234 18.6844 17.7632 19.0448C17.5847 19.2233 17.3728 19.3648 17.1397 19.4614C16.9065 19.5579 16.6566 19.6076 16.4042 19.6076C16.1519 19.6076 15.902 19.5578 15.6688 19.4612C15.4357 19.3646 15.2239 19.223 15.0454 19.0445C14.6852 18.6842 14.4827 18.1955 14.4827 17.686V8.07812L15.7638 9.35918L17.0448 8.07812L18.3259 9.35918V17.686H18.3259Z" stroke="currentColor" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_3936_141874">
<rect width="20" height="20" fill="white"/>
</clipPath>
</defs>
</svg>

    </>
  )
}

export default InvoiceIcon