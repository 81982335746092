import React, {useRef, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { TaskModalClose, _l, adjustDynamicInputHeight, hideLeftPanelNew, hideRightPanelNew, showLeftPanelNew } from "../hooks/utilities";
import {
  getSelectedTask,
  getBenchmarkWeekSchedule, getBenchmarkDetail, favoriteDdashboard,  leftPanelFilters,  setSelectedGloablView,  commanoffcanvasisOpen, openChatAiInput, setDashBoardView, SetSelectedTask
} from "../actions/customer";
import ChatSupportDropdown from "./Dropdowns/ChatSupportDropdown";
import jquery from "jquery";
import LogoSmallIcon from "../assets/icons/LogoSmallIcon";
import SelectProviderCategory from "./Modals/SelectProviderCategory";
import CreateTaskOffcanvas from "./Offcanvas/CreateTaskOffcanvas";
import CreateTaskRequestOffcanvas from "./Offcanvas/CreateTaskRequestOffcanvas";
import { steps, stepsForStaff} from "../components/IntroComponents/IntroSteps";
import CreateVirtualSpace from "./Modals/CreateVirtualSpace";
import AddLeadModal from "./Modals/AddLeadModal";
import CommanOffCanvas from "./Offcanvas/CommanOffCanvas";
import NewSchedule from "./Offcanvas/NewSchedule/NewSchedule";
import { createScheduleDetails, updateTypeOfSpaceList } from "../actions/benchmark-actions/benchmark-actions";
import { ScheduleProvider } from "../context/ScheduleContext";
import AddNewDocument from "./Modals/AddNewDocument";
import CommanFooterPlus from "./CommanFooterPlus";
import LogoSmallIGreenGradient from "../assets/icons/LogoSmallIGreenGradient";
import { sendMessage } from "../actions/chat";
import { mentionStyle } from "../assets/mention/mentionStyle";
import { MentionsInput, Mention } from "react-mentions";
import { ATTACH_BASE_URL, USR_IMG_PLACEHOLDER } from "../actions/chat-action-type";
import $ from "jquery";
import moment from "moment";
import { Steps } from "intro.js-react";
import ChatIcon from "../assets/icons/ChatIcon";
import ListBulletsIcon from "../assets/icons/ListBulletsIcon";
import BuildingsIcon from "../assets/icons/BuildingsIcon";
import FunnelIcon from "../assets/icons/FunnelIcon";
import GearIcon from "../assets/icons/GearIcon";
import CalendarBlankIcon from "../assets/icons/CalendarBlankIcon";
import StarIcon from "../assets/icons/StarIcon";
import NoteBlankIcon from "../assets/icons/NoteBlankIcon";
import Kanban from "../assets/icons/Kanban";
import ArrowRightIcon from "../assets/icons/ArrowRightIcon";
import CaretCircleDownIcon from "../assets/icons/CaretCircleDownIcon";
import CaretDownIcon from "../assets/icons/CaretDownIcon";
import ChatCircleIcons from "../assets/icons/ChatCircleIcons";
import MapTrifoldIcon from "../assets/icons/MapTrifoldIcon";
import SmileyIcon from "../assets/icons/SmileyIcon";
import EmojiPicker from "./Emoji/EmojiPicker";
const Footer = ({ projectId, pageName = "Dashboard", setIsKanbanView = ()=> {}}) => {
  const { defaultTopics, benchmarkDetail, globalView, openChatAi, dateView,ChatAiDate, dashBoradView, taskandMessages, taskFilters, commanoffcanvasIsOpen,leftPanelTab } = useSelector((state) => state.customer);
  const {groupDetails} = useSelector((state) => state.chat);
  const { floorDetails } = useSelector((state) => state.benchmarkReducer);
  let userType = localStorage.getItem("user_type");
  const project_id = useSelector((state) => state.customer.selectedProject);
  const [customerAdmins, setCustomerAdmins] = useState([]);
  const [showTaskModal, setShowTaskModal] = useState(false);
  const [FavView, setFavView] = useState(localStorage.getItem("fav_dashboard") ? localStorage.getItem("fav_dashboard") : "");
  const [showToolsModal, setShowToolsModal] = useState(false);
  const [showLeadModal, setShowLeadModal] = useState(false);
  const [showCreateScheduleForCustomer, setCreateScheduleForCustomer] = useState(false);
  const [categoryListing, setCategoryListing] = useState([]);
  const [showScheduleModal, setShowScheduleModal] = useState(false);
  const [showBenchmarkModal, setShowBenchmarkModal] = useState(false);
  const [showVirtualSpace, setShowVirtualSpace] = useState(false);
  const [hideInEmail, setHideInEmail] = useState(false);
  const [commonModalData, setCommonModalData] = useState();
  const [NewScheduleFooter, setNewScheduleFooter] = useState(false);
  const [isBenchmark, setIsBenchmark] = useState(false);
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const selectedTask = useSelector((state) => state.customer.selectedTask);
  const isLeadPage = pageName == 'leadPage' ? 1 : 0
  const handleBenchmark = () => setShowBenchmarkModal(!showBenchmarkModal);
  const location = useLocation();
  const inputHeight = useRef();
  const [mentionList, setMentionList] = useState([]);
  var chatUserId = localStorage.getItem("chatUserId");
  const handleSchedules = (is_benchmark = false) => {
    if(!NewScheduleFooter && benchmarkDetail)
    {
      dispatch(
        getBenchmarkDetail(benchmarkDetail.id, client_id, "all")
      );
      dispatch(createScheduleDetails({}));
    }
    setNewScheduleFooter(!NewScheduleFooter);
    setIsBenchmark(is_benchmark);
  }
  const [selectedTaskId, setSelectedTaskId] = useState(0);
  const [task_type, setTask_type] = useState('');
  const [showEditTaskModal, setShowEditTaskModal] = useState(false);
  const [aiChatInput, setAiChatInput] = useState(false);
  const [replyMessageId, setReplyMessageId] = useState("");
  const [messageText, setMessageText] = useState("");
  const [futuretaskCount, setfuturetaskCount] = useState(0)
  const [futureRequestTaskCount, setFutureRequestTaskCount] = useState(0);
  const [futureNotstartedTaskCount, setFutureNotstartedTaskCount] = useState(0);
  const [futureInprogessTaskCount, setFutureInprogessTaskCount] = useState(0);
  const [futureDoneTaskCount, setFutureDoneTaskCount] = useState(0);
  const [aiFooterIconSwitch, setAiFooterIconSwitch] = useState(false);
  let contactRole = localStorage.getItem("contact_role");
  const isEmpDash = userType != "staff" && localStorage.getItem("contact_role") == 3 && (localStorage.getItem("selectedOffice") == 0 || localStorage.getItem("selectedOffice") == undefined) ? true : false;
  const isContactDir = localStorage.getItem("isContactDir") ? localStorage.getItem("isContactDir") : 0
  const DrivePageTab = ["/my-drive"];
  const [footerIconForDashboard, setfooterIconForDashboard] = useState([{
    name: "spaceView",
    uniq_key: "spaceView",
    title:"spaceView",
    pageName: "dashboardnew",
    view : ["spaceView"]
  },
  {
    name: "taskList",
    path : "/" ,
    uniq_key: "my_tasks",
    title:"l_list",
    pageName: "Dashboard",
    view : dateView == "dayView" ? ["liteVersionView", "listView"] : ["liteVersionView", "listView"]
  },
  {name : "logo", is_active : false , uniq_key : "logo" , title : "l_logo"},
  {
    name: "mapView",
    uniq_key: "mapView",
    title:"l_map",
    pageName: "dashboardnew",
    view : ["mapView"]
  },
  {
  name: "calendar",
  path : "/calendar" ,
  uniq_key: "calendar",
  title:"l_calendar",
  pageName: "calendar",
  view : ["calendarView"]
  },
]);
  const [footerIcon, setfooterIcon] = useState([ 
    {
      name: "taskList",
      path : "/" ,
      uniq_key: "my_tasks",
      title:"l_list",
      pageName: "Dashboard",
      view : dateView == "dayView" ? ["liteVersionView", "listView"] : ["liteVersionView", "listView"]
    },
    {
    name: "calendar",
    path : "/calendar" ,
    uniq_key: "calendar",
    title:"l_calendar",
    pageName: "calendar",
    view : ["calendarView"]
    },
    {
    name: "kanbanView",
    path : "/" ,
    uniq_key: "kanban_view",
    title : "l_kanban",
    pageName: "",
    view : ["kanbanView"]
    },
    {
    name: "mySpace",
    path : "/myspacenew" ,
    uniq_key: "my_spaces",
    title : "l_space",
    pageName: "MySpace",
    view :["spaceView"]
    },
])
  useEffect(() => {
    if (pageName === "ChatAi") {
      dispatch(setSelectedGloablView("AichatView"));
    }
  }, [pageName])
  useEffect(() => {
    jquery("#BottomMenu").on("click", function () {
      jquery("#BottomMenushow").toggleClass("show-menu-link");
      jquery(".rotate-image").toggleClass("plus-rotate-image");
    });
    localStorage.removeItem("isContactDir")
  }, []);
  const [showEmoji, setShowEmoji] = useState(false);
  const onClick = (emojiData) => {
    setMessageText((text)=> text + emojiData.emoji)
  }
  const handleEmojiSelect = (emoji) => {
    setMessageText((text)=> text + emoji)
  };
  useEffect(() => {
    setAiChatInput(openChatAi)
  }, [openChatAi])
  useEffect(() => {
    if (defaultTopics && defaultTopics.length > 0) {
      var main_key = userType == "contact" ? "provider_task" : "proposals";
      defaultTopics.map((item) => {
        if (item.main_key == main_key) {
          setCategoryListing(
            item.categories.map((item1) => {
              return {
                value: parseInt(item1.filter_key),
                label: item1.title,
                is_default: item1.is_default,
              };
            })
          );
        }
      });
    }
  }, [defaultTopics]);
  const dispatch = useDispatch();
  const client_id = localStorage.getItem("client_id");  
  useEffect(() => {
    if (selectedTask && Object.keys(selectedTask).length) {
      setTask_type(selectedTask.is_requested && selectedTask.is_requested == 1 ? 'request-task' : 'task')
      setCommonModalData(selectedTask)
      if (pageName == "Dashboard" && showEditTaskModal) {
        jquery(".task-list-accordian").addClass("list-view-toggle");
      }
      let task_id = selectedTask.is_requested && selectedTask.is_requested == 1 ? selectedTask.task_id : selectedTask.id
      setTimeout(() => {
        if (selectedTask && pageName == "Dashboard" && showEditTaskModal) {
          if (typeof task_id == 'string') {
            jquery("#task_" + task_id.replace(new RegExp("=", "g"), '')).addClass("active");
          } else {
            jquery("#task_" + task_id).addClass("active");
          }
        }
      }, 100)
    } else if (task_type == 'task' || task_type == 'request-task') {
      jquery(".task-list-accordian").removeClass("list-view-toggle");
      setShowEditTaskModal(false);
      setCommonModalData();
      setSelectedTaskId(0);
      setTask_type('')
    }
    jquery(document).ready(function () {
      jquery("input").change(function () {
      });
    });
    return () => {
      jquery(document).unbind("ready");
    };
  }, [JSON.stringify(selectedTask)]);
  useEffect(() => {
    if (benchmarkDetail) {
      setCommonModalData(benchmarkDetail);
    }
  }, [benchmarkDetail])
  if (isLeadPage) {
    jquery('.link-center-box-toggle').addClass('d-none')
  }
  useEffect(() => {
    if (leftPanelTab !== 'my-request') {
      let tempArr2 = footerIcon.filter((view)=>  view.uniq_key != "logo")
      let finalArr =tempArr2
      finalArr.splice(2, 0, {name : "logo", is_active : false , uniq_key : "logo" , title : "l_logo",view : []});
      let index = finalArr.findIndex((data)=> data.name == "taskList")
      let updateArr = finalArr[index]
      if(updateArr){
        updateArr.view =  dateView == "dayView" ? ["liteVersionView", "listView"] : ["liteVersionView", "listView"]
      }
      setfooterIcon(finalArr)
    }
  }, [FavView,pageName, globalView])
  useEffect(() => {
    if (leftPanelTab == "my-agents" || leftPanelTab == "my-tenants" || leftPanelTab == "my-staff") {
      setfooterIcon(
        [{
          name: "taskList",
          path : "/" ,
          uniq_key: "my_tasks",
          title:"l_list",
          pageName: "Dashboard",
          view : dateView == "dayView" ? ["liteVersionView", "listView"] : ["liteVersionView", "listView"]
        },
        {
        name: "chat",
        path : "/" ,
        uniq_key: "chat",
        title:"l_chat_modal",
        pageName: "chat",
        view : ["AichatView"]
        }]
      )
    } else if(leftPanelTab == "my-request"){
      setfooterIcon([ {
      name: "taskList",
      path : "/" ,
      uniq_key: "my_tasks",
      title:"l_list",
      pageName: "Dashboard",
      view : dateView == "dayView" ? ["liteVersionView", "listView"] : ["liteVersionView", "listView"]
    },
    {
    name: "calendar",
    path : "/calendar" ,
    uniq_key: "calendar",
    title:"l_calendar",
    pageName: "calendar",
    view : ["calendarView"]
    },
    {
    name: "kanbanView",
    path : "/" ,
    uniq_key: "kanban_view",
    title : "l_kanban",
    pageName: "",
    view : ["kanbanView"]
    }])
    }else{
      let footerIcon = [ 
        {
          name: "taskList",
          path : "/" ,
          uniq_key: "my_tasks",
          title:"l_list",
          pageName: "Dashboard",
          view : dateView == "dayView" ? ["liteVersionView", "listView"] : ["liteVersionView", "listView"]
        },
        {
        name: "calendar",
        path : "/calendar" ,
        uniq_key: "calendar",
        title:"l_calendar",
        pageName: "calendar",
        view : ["calendarView"]
        },
        {
        name: "kanbanView",
        path : "/" ,
        uniq_key: "kanban_view",
        title : "l_kanban",
        pageName: "",
        view : ["kanbanView"]
        },
        {
        name: "mySpace",
        path : "/myspacenew" ,
        uniq_key: "my_spaces",
        title : "l_space",
        pageName: "MySpace",
        view :["spaceView"]
        },
    ]
      let tempArr2 = footerIcon.filter((view)=>  view.uniq_key != "logo")
      let finalArr =tempArr2
      finalArr.splice(2, 0, {name : "logo", is_active : false , uniq_key : "logo" , title : "l_logo", view : []});
      let index = finalArr.findIndex((data)=> data.name == "taskList")
      let updateArr = finalArr[index]
      if(updateArr){
        updateArr.view =  dateView == "dayView" ? ["liteVersionView", "listView"] : ["liteVersionView", "listView"]
      }
      setfooterIcon(finalArr)
    }
  }, [JSON.stringify(leftPanelTab)])
useEffect(() => {
  setFavView(localStorage.getItem("fav_dashboard"))
  if(globalView == "AichatView"){
    scrollToBottom();
  }
}, [pageName, userType])
useEffect(() => {
  if(taskandMessages && taskandMessages.length > 0){
    let taskTime = ""
    let currentTime = (moment(new Date()).format("YYYY-MM-DD HH:mm:ss"));
    var validDateData = [];
    taskandMessages.map((eItem) => {
      if (eItem.startdate == null && eItem.duedate == null) {
      } else {
        validDateData.push({ ...eItem, createdAt: eItem.startdate ? eItem.startdate : eItem.duedate ? eItem.duedate : eItem.dateadded });
      }
    })
   let temptasks = validDateData.filter((task) =>{
    if (task.startdate != null && task.startdate != "" &&  task.startdate != undefined) {
      taskTime = (moment(task.startdate).format("YYYY-MM-DD HH:mm:ss"));
    } else if (task.duedate != null && task.duedate != "" && task.duedate != undefined) {
      taskTime = (moment(task.duedate).format("YYYY-MM-DD HH:mm:ss"));
    } 
    return(currentTime.toString() < taskTime.toString() )
  })
    setfuturetaskCount(temptasks.length)
    localStorage.setItem("futureTaskCount",temptasks.length)
  }else{
  setfuturetaskCount(0)
}
}, [JSON.stringify(taskandMessages)])
useEffect(() => {
  if(taskandMessages && taskandMessages.length > 0){
  let taskTime = ""
  let currentTime = (moment(ChatAiDate).format("YYYY-MM-DD HH:mm:ss"));
    var validDateData = [];
    taskandMessages.map((eItem) => {
      if (eItem.startdate == null && eItem.duedate == null) {
      } else {
        validDateData.push(eItem);
      }
    })
  let temptasks = validDateData.filter((task) =>{
    if (task.startdate != null && task.startdate != "" &&  task.startdate != undefined) {
      taskTime = (moment(task.startdate).format("YYYY-MM-DD"));
    } else if (task.duedate != null && task.duedate != "" && task.duedate != undefined) {
      taskTime = (moment(task.duedate).format("YYYY-MM-DD"));
    } 
    return(currentTime.toString() <= taskTime.toString() )
  })
  let tempfutureRequestTasckCount = temptasks.filter((task) => task.is_requested == 1).slice();
    let tempfutureNotstartedTasckCount = temptasks.filter((task) =>task.is_requested == 0 && task.status == 4).slice();
    let tempfutureInprogressTasckCount = temptasks.filter((task) =>task.is_requested == 0 && task.status == 1).slice();
    let tempfutureDonetTasckCount = temptasks.filter((task) =>task.is_requested == 0 && task.status == 5).slice();
    setFutureRequestTaskCount(tempfutureRequestTasckCount.length)
    setFutureNotstartedTaskCount(tempfutureNotstartedTasckCount.length)
    setFutureInprogessTaskCount(tempfutureInprogressTasckCount.length)
    setFutureDoneTaskCount(tempfutureDonetTasckCount.length)
}
}, [JSON.stringify(ChatAiDate), JSON.stringify(taskandMessages)])
useEffect(() => {
  localStorage.setItem("futureTaskCount",futuretaskCount)  
}, [futuretaskCount])
  const onPagechangeToggle = () => {
    jquery("body").removeClass("task-modal-offcanvas-open");
    hideRightPanelNew();
  };
  const handleSelectedTask = (
    taskId = 0,
    show = "",
    isRequestedTask = null,
    convertRequestTaskId = 0
  ) => {
    setShowEditTaskModal(false);
    setSelectedTaskId(taskId);
    localStorage.setItem("SelectedTask", taskId);
    localStorage.setItem(
      "TaskType",
      isRequestedTask && isRequestedTask != null ? isRequestedTask : 0
    );
    if (isRequestedTask || taskId) {
      setShowEditTaskModal(show);
    }
    jquery('#task_' + taskId).addClass('active')
    jquery('#list_view').addClass('list-view-toggle')
  };
  const sendChatFeedMsg = (val) => {
    scrollToBottom()
    if(val != ""){
      var textMessage = val;
      textMessage = textMessage.trim();
      if (textMessage != "") {
        var msgData = {
          groupId: localStorage.getItem("activeGroupId"),
          userId: localStorage.getItem("chatUserId"),
          message: textMessage,
          type: "text",
        };
        if(replyMessageId != ""){
          msgData.replyMsgId = replyMessageId;
        }
        dispatch(sendMessage(msgData));
        setMessageText("");
        setReplyMessageId("");
        jquery("#chatAIFeedInput").val("")
      }
    }
  }
  useEffect(() => {
    if(groupDetails && groupDetails.members){
      setMentionList(
        groupDetails.members.filter((item) => item._id != chatUserId).map((item) => {
          return {
            id: item._id,
            display: item.fname+" "+item.lname,
            image: item.displayPicture != "" ? ATTACH_BASE_URL+item.displayPicture : USR_IMG_PLACEHOLDER
          };
        })
      );
    }
  }, [groupDetails]);
  const inputRef = useRef(null);
  if(aiChatInput){
   jquery(document).mouseup(function (e) {
      var container = jquery(".aicommentbox , .footer-mid-btn-animation");
      if (!container.is(e.target) && container.has(e.target).length === 0) {
        setAiChatInput(false);
        dispatch(openChatAiInput(false));
      }
    });
  }
  useEffect(() => {
    if (aiChatInput) {
      var messageInput = $('#chatAIFeedInput');
      messageInput.focus();
    }
  }, [aiChatInput])
  const scrollToBottom = () => {
    var msgListDiv = document.getElementById("scrollableDiv");
    if(msgListDiv){
      msgListDiv.scrollTop = msgListDiv.scrollHeight;
    }
  };
  return (
    <>
    <Steps
  enabled={false}
  steps={userType == "staff" || (userType !="staff" && parseInt(contactRole) == 3) ? stepsForStaff : steps}
  initialStep={0}
  onExit={()=>{
    localStorage.removeItem("showintro")
  }}
  options={{
    hideNext: false,
    showBullets: true,
    showButtons: true,
    showProgress: false,
    tooltipPosition: 'bottom-middle-aligned',
  }}
/>
    <ScheduleProvider>
      <footer className={` ${pageName == "MyProfile" ? "d-none" : ""} ${globalView == "AichatView" ? "aichatfoooter" : ""} ${commanoffcanvasIsOpen ? "mainfooteroffcanvasopen" :""}`}>
        <div className="d-flex align-items-center justify-content-between position-relative">
          {leftPanelTab != "homepage" && globalView == "AichatView" ?
            <React.Fragment>
                 <div className={`comman_action_icon  ${localStorage.getItem("activeGroupId") ? "aicommentbox-goto-bottom" : ""}  `}>
                  {localStorage.getItem("activeGroupId") ? <div
                    className="  with-bg d-flex bg-base-header-color border on-hover-active-toggle-img radius_3 position-relative light-theme-comman-box-shadow"
                    style={{width:""}}
                    type="button"
                  >
                     <div className={` c-font f-10 g-0  pb-0 d-flex flex-column align-items-center title-fonts ${futureRequestTaskCount == 0  && futureNotstartedTaskCount == 0 &&futureInprogessTaskCount == 0 && futureDoneTaskCount == 0 ? "p-1" : "p-2"}`}>
                      <div className="d-flex align-items-center">
                        { futureRequestTaskCount  > 0 ? <div className="d-flex me-2">
                          <div className="  f-color-orange rounded-pill badge-custom with-orange-border with-position-relative fw-semibold m-auto h19w19 d-flex">
                            <div className="">{futureRequestTaskCount < 99 ? futureRequestTaskCount :"99+"  }</div>
                          </div>
                        </div> : <></>}
                        {futureNotstartedTaskCount  > 0 ? <div className="d-flex me-2">
                          <div className="rounded-pill f-color-white badge-custom with-inprogress-border with-position-relative fw-semibold m-auto d-flex h19w19">
                            <div className="">{futureNotstartedTaskCount < 99 ? futureNotstartedTaskCount :"99+"}</div>
                          </div>
                        </div> : <></>}
                        { futureInprogessTaskCount  > 0 ? <div className="d-flex me-2">
                          <div className="  f-color-white rounded-pill badge-custom with-to-do-border with-position-relative fw-semibold m-auto h19w19 d-flex">
                            <div className="">{futureInprogessTaskCount < 99 ? futureInprogessTaskCount : "99+"}</div>
                          </div>
                        </div> : <></>}
                        { futureDoneTaskCount > 0 ? <div className="d-flex">
                          <div className=" rounded-pill f-color-white badge-custom  with-done-border with-position-relative fw-semibold m-auto d-flex h19w19">
                            <div className="">{futureDoneTaskCount < 99 ? futureDoneTaskCount :"99+"}</div>
                          </div>
                        </div> : <></>}
                      </div>
                      <div className={`comman_action_icon pb-1 ${futureRequestTaskCount == 0  && futureNotstartedTaskCount == 0 &&futureInprogessTaskCount == 0 && futureDoneTaskCount == 0 ? "pt-0" : "pt-1"}`}>
                 <a href="#/" className="d-flex flex-column justify-content-center align-items-center position-relative"  onClick={()=>scrollToBottom()}>
                  {futuretaskCount > 0 ? <CaretDownIcon className="HW18" /> :  <CaretDownIcon className="HW18" />}
                 </a>  
                 </div>
                    </div>
                  </div> : <></>}
                </div> 
              <a href="#/" className="aicommentbox-goto-bottom d-none h40w40" 
              onClick={()=>scrollToBottom()}>
                {futuretaskCount > 0 ? <span className="badge-custom d-flex rounded-pill"><span className="m-auto ps-0">{futuretaskCount}</span></span> : <></>}
                <CaretCircleDownIcon className="HW40 opacity-60" />
                </a>
                {showEmoji ?
                 <EmojiPicker onEmojiSelect={handleEmojiSelect} />
                  : <></>
                }
           <div ref={inputRef} className={`aicommentbox radius_3 w-100 ms-0 d-none`}>
            <div className="d-flex m-auto flex-column chat-main-box">
              <div className="comment-input-box d-flex align-items-end chat-comment-box ">
                <div class="h30w30 comman-image-box with-bg rounded-circle">
                    <div class="comman-bg-img h-100 w-100 bg-style-cover rounded-circle" style={{
                        backgroundImage: `url('${localStorage.getItem("contact_image")}')`,
                    }} >
                    </div>
                </div>
                <div
                          className="mension-box mension-box--multiLine custom-width"
                          style={{
                            position: "relative",
                            overflowY: "visible",
                          }}
                        >
                <MentionsInput
                     id="chatAIFeedInput"
                      value={messageText}
                      className="mension-box"
                      placeholder={`${_l("l_hello_how_can_i_help_you")}`}
                      alwaysOpen={true}
                      onInput={(e)=>{adjustDynamicInputHeight(inputHeight,e)}}
                      onChange={(e)=>{
                        setMessageText(e.target.value)
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter" && !e.ctrlKey && !e.shiftKey) {
                          e.preventDefault();
                          sendChatFeedMsg(e.target.value);
                          scrollToBottom();
                        }
                      }}
                    >
                      <Mention
                        markup='<span style="font-weight: 600;"  contenteditable="false" data-mention-id="__id__"  data-mce-style="font-weight: 600;"  data-mce-selected="1">@__display__</span>'
                        trigger="@"
                        data={mentionList}
                        displayTransform={(id,display) => `@${display}`}
                        style={mentionStyle}
                        renderSuggestion={(
                          suggestion,
                          search,
                          highlightedDisplay
                        ) => {
                          return (
                            <div className="user d-flex align-items-center">
                              <div className="comman-image-box h25w25 rounded-circle mx-2">
                                <div
                                  className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                                  style={{
                                    backgroundImage: `url('${suggestion.image}')`,
                                  }}
                                ></div>
                              </div>
                              {highlightedDisplay}
                            </div>
                          );
                        }}
                      />
                    </MentionsInput>
                </div>
                <div className="comman_action_icon emojiabsolute">
                        <a href="#/" className={`send-arrow-btn`} 
                        onClick={() => { setShowEmoji(!showEmoji) }}
                        >
                          <SmileyIcon className="HW18" />
                        </a>
                      </div>
                <a href="#/" className="AiAbsolute">
                  <div className="footer-mid-btn-animation h35w40 position-relative">
                    <div>
                      <span className="footer-mid-circle  circle-1"></span>
                    </div>
                    <div>
                      <span className="footer-mid-circle  circle-2"></span>
                    </div> 
                    <div>
                      <span className="footer-mid-circle  circle-3"></span>
                    </div>
                    <div>
                      <span className="footer-mid-circle  circle-4"></span>
                    </div>
                    <span className="hello_box pt-0">
                      <LogoSmallIGreenGradient className=" stroke-transparent HW12 " /> 
                    </span>
                  </div>
                </a>
                <a href="#/" className={`send-arrow-btn p-0 `}>
                  <div
                    alt={"White arrow"}
                    className="img-width-18 m-auto"
                  >
                  <ArrowRightIcon className="HW16" onClick={() => { sendChatFeedMsg(jquery("#chatAIFeedInput").val()); }} />
                </div>
                </a>
              </div>
            </div>
          </div></React.Fragment> : <></> }
          {!hideInEmail && pageName != 'dashboard' && pageName != 'CompanyProfile' && pageName != 'PremiumSubscription' && pageName != 'MyProfile' ? (
            <div className={`"floor-filter" ${pageName == "leadPage" ? "d-none" :""}`}>
              <div className="d-flex">
                  <div className="comman_action_icon btn-group dropup dropup-center me-2 d-none">
                  <a href="#/"
                    className="h40w40  with-bg d-flex bg-base-header-color p-5px on-hover-active-toggle-img radius_3 "
                    type="button"
                  >
                    <NoteBlankIcon className="HW24 m-auto" />
                  </a>
                </div>
                <div className="">
                </div>
                <div className={`comman_action_icon btn-group dropup ${pageName=="LiteVersion" ? "d-none" : "d-none"}`}>
                  <a href="#/"
                    className="h40w40  with-bg d-flex bg-base-header-color p-5px on-hover-active-toggle-img radius_3"
                  >
                    <GearIcon className="HW18 m-auto" />
                  </a>
                </div>
                <div className={`comman_action_icon btn-group dropup ms-2 ${isLeadPage ? 'link-center-box-toggle' : 'd-none'}`}>
                  <a href="#/" className="h40w40  with-bg d-flex bg-base-header-color p-5px on-hover-active-toggle-img radius_3"
                    type="button"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="outside"
                    aria-expanded="false" 
                  >
                    <FunnelIcon className="HW16" />
                  </a>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="footer-contact-img d-none">
            <div className="d-flex">
              <a href="#/">
                <div
                  className="h50w50 comman-round-box with-bg d-flex rounded-circle bg-base-header-color p-5px box-shadow-2 on-hover-active-toggle-img "
                  type="button"
                  id="TaskListMySpace"
                  data-bs-toggle="dropdown"
                  aria-expanded="false" 
                >
                  <ChatIcon className="HW18 comman-bg-img m-auto rounded-circle active-img" />
                </div>
                <ChatSupportDropdown customerAdmins={customerAdmins} />
              </a>
            </div>
          </div>
          {hideInEmail ? (
            <div className="footer-link-wrapper">
              <ul className=" d-flex align-items-center justify-content-center first">
                <li className=" mid-footer-link">
                  <a href="#/" className="position-relative">
                    <div className="footer-mid-btn-animation">
                      <div>
                        <span className="footer-mid-circle circle-1"></span>
                      </div>
                      <div>
                        <span className="footer-mid-circle circle-2"></span>
                      </div>
                      <div>
                        <span className="footer-mid-circle circle-3"></span>
                      </div>
                      <div>
                        <span className="footer-mid-circle circle-4"></span>
                      </div>
                      <span className="hello_box">
                        <LogoSmallIcon className="path-white stroke-transparent HW24 " />
                      </span>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          ) : pageName === "MyEmail" ? <></>  : (
            <div className="footer-link-wrapper">
              { isEmpDash ? <></>
              :
              <ul className={`footer-nav-linksnew footeranimation Footerfix ${(globalView == "AichatView" || pageName == "ChatAi") && localStorage.getItem("activeGroupId") ? "with-bottom-position  " : "Footerfix"} footer-links  d-flex align-items-center justify-content-center ${pageName=="dashboardnew" ? "with-custom-width":""} ${['dashboard', 'CompanyProfile','PremiumSubscription','Proposals'].includes(pageName) ? "bg-transparent shadow-none" : ""} ${isContactDir ? "for_disabled" : ""}`}
              >
                  {!DrivePageTab.includes(location.pathname) ? !["homepage","my-calendar","my-clients", "equipments"].includes(leftPanelTab) && pageName != "dashboardnew" && pageName != "ProviderList" && footerIcon.map((view, index) => {
                    return (<>
                    {(index < 2 || index > 2 || ["my-request"].includes(leftPanelTab) ) ?
                      <li onClick={() => {
                        TaskModalClose();
                        showLeftPanelNew();
                        dispatch(SetSelectedTask());
                        localStorage.setItem("callTaskAPi",true);
                        if(["operatorListReporting","equipmentListReporting"].includes(pageName)){
                          dispatch(leftPanelFilters("","","hold_range_filter"))
                        }
                        if(view.name == "chat"){
                          TaskModalClose();
                          showLeftPanelNew();
                          dispatch(SetSelectedTask());
                          localStorage.setItem("currentView", "AichatView");
                          localStorage.setItem("callTaskAPi",false);
                          if (pageName == "Proposals" && localStorage.getItem("space_setup_pending") == 1) {
                            $("body").removeClass("with-left-penel");
                          }
                          dispatch(setSelectedGloablView("AichatView"));
                        }else if(view.name == "kanbanView"){
                          localStorage.setItem("currentView", "kanbanView");
                          setIsKanbanView(true);
                            dispatch(setSelectedGloablView("kanbanView"))
                            if (pageName == "leadPage") {
                              navigate("/leads");
                            }else if(pageName == "MyEmail" ){
                            }else{
                              navigate(view.path);
                            }
                        }else{
                          if(view.name == "calendar"){
                          localStorage.setItem("currentView", "calendarView");
                            dispatch(setSelectedGloablView("calendarView"));
                            if (pageName == "leadPage") {
                              navigate("/leads");
                            }else if(pageName == "MyEmail"){
                            }else{
                              navigate("/")
                            }
                          }else if(view.name == "taskList"){
                          localStorage.setItem("currentView", "liteVersionView");
                            if (pageName == "leadPage") {
                              navigate("/leads");
                            }else if(pageName == "MyEmail" ){
                            }else if(pageName == "MyEmail" ){
                            }else{
                              navigate("/");
                            }
                            dispatch(setSelectedGloablView( dateView == "dayView" ? "liteVersionView" : "listView"))
                            if((project_id == null || project_id == 0 || project_id == undefined || project_id == "null") && leftPanelTab != "my-tenants"){
                              hideLeftPanelNew();
                            }
                          setIsKanbanView(false);
                          }else if(view.name == "mySpace"){
                          localStorage.setItem("currentView", "spaceView");
                            if (pageName == "leadPage") {
                              navigate("/leads");
                            }else if(pageName == "MyEmail" ){
                            }else{
                              navigate("/");
                            }
                            dispatch(setSelectedGloablView("spaceView"))
                          }else{
                          localStorage.setItem("currentView", "liteVersionView");
                            navigate(view.path);
                          }
                        }
                        onPagechangeToggle();
                      }} className={`footernav on-hover-active-toggle-img position-relative ${view.uniq_key == FavView ? " fav-view " : ""} ${["dashboard","CompanyProfile","PremiumSubscription","MyProfile","Proposals"].includes(pageName) ? 'd-none' : ''} 
                     ${["Dashboard", "leadPage"].includes(pageName) && (view.view).includes(globalView) ? 'active' : ""}
                      `}
                      > 
                           <div className="absolute_star bg-pin z-index-5 cursor-pointer test" onClick={(e) => {
                              e.preventDefault()
                              if (FavView == view.uniq_key) {
                                localStorage.setItem("fav_dashboard", "")
                                setFavView("")
                              } else {
                                localStorage.setItem("fav_dashboard", view.uniq_key)
                                setFavView(view.uniq_key)
                              }
                              if(view.name == "kanbanView"){
                                setIsKanbanView( FavView != "kanbanView" ? true : false)
                              }
                              dispatch(favoriteDdashboard(localStorage.getItem("selectedOffice"), view.uniq_key))
                            }}>
                              {FavView == view.uniq_key ? <StarIcon className="HW14 color-green fill-white" /> : <StarIcon className="c-icons lt-green color-green" />}
                            </div> 
                          <a href="#/" className={`${commanoffcanvasIsOpen ? "flex-column" : ""}`} onClick={() => {
                            TaskModalClose();
                            showLeftPanelNew();
                            dispatch(SetSelectedTask());
                            dispatch(commanoffcanvasisOpen(false));
                            if (window.location.pathname == "/provider-reporting" || window.location.pathname == "/employee-reporting" || window.location.pathname == "/equipment-reporting") {
                              dispatch(leftPanelFilters("","","hold_range_filter"))
                            }
                          if(view.name == "kanbanView"){
                            setIsKanbanView(true);
                              dispatch(setSelectedGloablView("kanbanView"))
                              if (pageName == "leadPage") {
                                navigate("/leads");
                              }else if(pageName == "MyEmail" ){
                              }else{
                                navigate(view.path);
                              }
                          }else{
                            if(view.name == "calendar"){
                              dispatch(setSelectedGloablView("calendarView"));
                              dispatch(commanoffcanvasisOpen(false));
                              if (pageName == "leadPage") {
                                navigate("/leads");
                              }else if(pageName == "MyEmail" ){
                              }else{
                                navigate("/");
                              }
                            }else if(view.name == "taskList"){
                              if (pageName == "leadPage") {
                                navigate("/leads");
                              }else if(pageName == "MyEmail" ){
                              }else{
                                navigate("/");
                              }
                              dispatch(setSelectedGloablView( dateView == "dayView" ? "liteVersionView" : "listView"))
                              if(project_id == null || project_id == 0 || project_id == undefined || project_id == "null"){
                                hideLeftPanelNew();
                              }
                            setIsKanbanView(false);
                            }else if(view.name == "mySpace"){
                              if (pageName == "leadPage") {
                                navigate("/leads");
                              }else if(pageName == "MyEmail" ){
                              }else{
                                navigate("/");
                              }
                              dispatch(setSelectedGloablView("spaceView"))
                            }else{
                              navigate(view.path);
                            }
                          }
                          onPagechangeToggle();
                        }} >
                            <>
                            {view.name == "calendar" ? <CalendarBlankIcon className="HW20 mx-0"/>
                              : view.name == "taskList" ? <ListBulletsIcon className="HW20 mx-0"/> 
                              : view.name == "kanbanView" ? <Kanban className="HW20 mx-0"/> 
                              : view.name == "mySpace" ? <BuildingsIcon className="HW20 mx-0" />
                              : view.name == "chat" ? <ChatCircleIcons className="HW20 mx-0" />
                               :<></> }                                    
                            <span className={`c-font f-12 fw-semibold title-fonts hover-span text-capitalize ${commanoffcanvasIsOpen ? "" : "ps-10px"}`}>
                              {_l(view.title)}
                            </span>
                            </>
                          </a>
                      </li> : <>
                      {index == 2 && (leftPanelTab !== "my-agents" && leftPanelTab !== "my-tenants" && leftPanelTab !== "my-staff")?
                      <li className= {`ai-welcome-chat footernav on-hover-active-toggle-img position-relative ${globalView == "AichatView" ? "active" : ""} ${"AichatView" == FavView ? " fav-view " : ""} ${globalView == "AichatView" && aiFooterIconSwitch != false ? "mid-footer-link" :" footernav on-hover-active-toggle-img position-relative"}`}>
                 {globalView == "AichatView"?
                 <>
                   <>
                   <div className="absolute_star bg-pin z-index-5 cursor-pointer" onClick={()=>{
                          if (FavView == "AichatView") {
                            localStorage.setItem("fav_dashboard", "")
                            setFavView("");
                          } 
                          else {
                            setFavView("AichatView");
                            dispatch(setSelectedGloablView("AichatView"));;
                            localStorage.setItem("fav_dashboard","AichatView");
                            dispatch(favoriteDdashboard(localStorage.getItem("selectedOffice"), "AichatView"));
                          }
                    }}>
                        {FavView == "AichatView" ? <StarIcon className="HW14 color-green fill-white" /> : <StarIcon className="c-icons lt-green color-green" />}
                    </div>
                    <a href="#/" className={`${commanoffcanvasIsOpen ? "flex-column" : ""} ${pageName == "Proposals" ? localStorage.getItem("space_setup_pending") == 1 ? "" : "d-none" : ""}`}
                     onClick={() => {
                      TaskModalClose();
                      showLeftPanelNew();
                      dispatch(SetSelectedTask());
                      localStorage.setItem("currentView", "AichatView");
                      localStorage.setItem("callTaskAPi",false);
                      if (pageName == "Proposals" && localStorage.getItem("space_setup_pending") == 1) {
                        $("body").removeClass("with-left-penel");
                      }
                      dispatch(setSelectedGloablView("AichatView"));;
                       }}>
                        <ChatCircleIcons className="HW20 mx-0" />
                        <span className={`c-font f-12 title-fonts hover-span text-capitalize fw-semibold  ${commanoffcanvasIsOpen ? "" : "ps-10px"}`}>
                          {_l("l_chat_modal")}
                        </span>
                    </a>
                    </>
                   </> : <>
                  { pageName != "MyProfile" && pageName !="PremiumSubscription" ?
                                    <>
                                       <div className="absolute_star bg-pin z-index-5 cursor-pointer" onClick={()=>{
                                         if (FavView == "AichatView") {
                                          localStorage.setItem("fav_dashboard", "")
                                          setFavView("");
                                        }else{
                                          navigate("/");
                                          setFavView("AichatView");
                                          dispatch(setSelectedGloablView("AichatView"))
                                          localStorage.setItem("fav_dashboard","AichatView");
                                          dispatch(favoriteDdashboard(localStorage.getItem("selectedOffice"), "AichatView"));
                                        }
                                       }}>
                                       {FavView == "AichatView" ? <StarIcon className="HW14 color-green fill-white" /> : <StarIcon className="c-icons lt-green color-green" />}
                                       </div>
                                     <a href="#/" className={`${pageName == "Proposals" ? localStorage.getItem("space_setup_pending") == 1 ? "" : "d-none" : ""} ${commanoffcanvasIsOpen ? "flex-column" : ""}`} onClick={() => {
                                      TaskModalClose();
                                      showLeftPanelNew();
                                      dispatch(SetSelectedTask());
                                      localStorage.setItem("currentView","AichatView");
                                      localStorage.setItem("callTaskAPi",false);
                                      navigate("/");
                                      if (pageName == "Proposals" && localStorage.getItem("space_setup_pending") == 1) {
                                        $("body").removeClass("with-left-penel");
                                      }
                                      if(["operatorListReporting","equipmentListReporting"].includes(pageName)){
                                        dispatch(leftPanelFilters("","","hold_range_filter"))
                                      }else if(taskFilters && taskFilters.filter((key) => key.main_key != "date_range_filter").length > 1 ){
                                        dispatch(leftPanelFilters("","","all"))
                                      }
                                      dispatch(setSelectedGloablView("AichatView"));;
                                      setAiFooterIconSwitch(true)
                                    } }>
                                          <ChatCircleIcons className="HW18 mx-0" />
                                        <span className={`c-font f-12 fw-semibold text-capitalize title-fonts hover-span ${commanoffcanvasIsOpen ? "" : "ps-10px"}`}>
                                          {_l("l_chat_modal")}
                                        </span>
                                      </a></>  : <></>}
                  </>}
                </li> :<></>}
                      </>}
                    </>)
                  }) : <></>}
                  {/* dashboard new footer start*/}
                  {pageName == "dashboardnew" && footerIconForDashboard.map((view, index) => {
                    return (<>
                    {index < 2 || index > 2 ?
                      <li onClick={() => {
                        if(view.name == "mapView"){
                            dispatch(setDashBoardView("mapView"));
                          }else if(view.name == "spaceView"){
                            dispatch(setDashBoardView("spaceView"));
                          }else if(view.name == "calendar"){
                            dispatch(setDashBoardView("calendarView"));
                          }else if(view.name == "taskList"){
                            dispatch(setDashBoardView("taskListView"));
                          }
                      }} className={`footernav on-hover-active-toggle-img position-relative ${((dashBoradView == "spaceView") && view.name == "spaceView") || dashBoradView == "mapView" && view.name == "mapView" || (dashBoradView == "taskListView" && view.name == "taskList") || (dashBoradView == "calendarView" && view.name == "calendar" )? 'active' : ""}`}
                      > 
                          {((view.title == "spaceView" || view.title == "l_map") && (dashBoradView == "spaceView" || dashBoradView == "mapView")) ||  (view.title == "l_list" || view.title == "l_calendar") && (dashBoradView == "taskListView" || dashBoradView == "calendarView") ? <a href="#/" className=""  >
                            <>
                            {view.name == "spaceView" && (dashBoradView == "spaceView" || dashBoradView == "mapView") ? <BuildingsIcon className="HW20 mx-0"/>
                              : view.name == "mapView" && (dashBoradView == "spaceView" || dashBoradView == "mapView")  ? <MapTrifoldIcon className="HW20 mx-0"/> 
                              :view.name == "calendar" && (dashBoradView == "taskListView" || dashBoradView == "calendarView") ? <CalendarBlankIcon className="HW20 mx-0"/>
                              : view.name == "taskList" && (dashBoradView == "taskListView" || dashBoradView == "calendarView")  ? <ListBulletsIcon className="HW20 mx-0"/> :<></> }                                    
                            <span className="c-font f-12 ps-10px title-fonts hover-span fw-semibold text-capitalize">
                              {(view.title == "spaceView" || view.title == "l_map") && (dashBoradView == "spaceView" || dashBoradView == "mapView") ? _l(view.title) :(view.title == "l_list" || view.title == "l_calendar") && (dashBoradView == "taskListView" || dashBoradView == "calendarView") ? _l(view.title) : <></>}
                            </span>
                            </>
                          </a> : <></>}
                      </li> : <>
                      {pageName != "" && index == 2 ? <li className={`footernvaanimation on-hover-active-toggle-img`}>
                  {globalView == "AichatView" || pageName == "ChatAi" ? 
                  <a href="#/" className="position-relative py-0 footerglowmain" onClick={() => { if(globalView != "AichatView"){
                    dispatch(setSelectedGloablView("AichatView"));;
                    }}}>
                    <div className="footer-mid-btn-animation" >
                      <div>
                        <span className="footer-mid-circle circle-1"></span>
                      </div>
                      <div>
                        <span className="footer-mid-circle circle-2"></span>
                      </div>
                      <div>
                        <span className="footer-mid-circle circle-3"></span>
                      </div>
                      <div className=" transition_03s z-index-2">
                        <span className=" footer-mid-circle-logo-bg h40w40 m-auto rounded-5"></span>
                      </div>
                      <div>
                        <span className="footer-mid-circle circle-4"></span>
                      </div>
                      <span className="hello_box">
                      <LogoSmallIcon className="path-white stroke-transparent HW24 " /> 
                      </span>
                      <span className="logobgfooter" ></span>
                    </div>
                  </a> 
                  :
                  pageName != "MyProfile" && pageName !="PremiumSubscription" && pageName != "Proposals" ?
                  <a href="javascript:void(0)" className={`position-relative p-0 middle-footer-icon`} style={{cursor:"auto"}}>
                      <div className="footer-mid-btn-animation" onClick={()=>{
                        setAiChatInput(true);
                        dispatch(openChatAiInput(true));
                          }} 
                      >
                        <div>
                          <span className="footer-mid-circle circle-1"></span>
                        </div>
                        <div>
                          <span className="footer-mid-circle circle-2"></span>
                        </div>
                        <div>
                          <span className="footer-mid-circle circle-3"></span>
                        </div>
                        <div className=" transition_03s z-index-2">
                          <span className=" footer-mid-circle-logo-bg h40w40 m-auto rounded-5"></span>
                        </div>
                        <div>
                          <span className="footer-mid-circle circle-4"></span>
                        </div>
                        <span className={`hello_box `}>
                        <LogoSmallIcon className="path-white stroke-transparent HW24 " /> 
                        </span>
                    </div>
                   </a> 
                   : <></>}
                </li> :<></>}
                      </>}
                    </>)
                  })}
                  {/* dashboard new footer end*/}
              </ul>
              }
            </div>
          )
                }
          <CommanFooterPlus MainClass={` ${globalView == "AichatView" && localStorage.getItem("activeGroupId") ? "with-upside-pos" : ""}`} id="CommonFooterHandle" toggleId="CommonFooterHandleShow" pageName={pageName} projectId={projectId} />
        </div>
      </footer>
      {
        show ? (
          <CreateTaskRequestOffcanvas
            show={show}
            handleClose={(taskId) => {
              setShow(false);
              if (taskId) {
                dispatch(
                  getSelectedTask(
                    localStorage.getItem("selectedOffice"),
                    taskId,
                    1
                  )
                );
                setShowEditTaskModal(true)
              }
            }}
          />
        ) : (
          <></>
        )
      }
      {
        showCreateScheduleForCustomer ? (
          <></>
        ) : (
          <></>
        )
      }
      {
        showEditTaskModal ? <CommanOffCanvas
          show={showEditTaskModal}
          handleClose={() => {
            jquery(".task-list-accordian").removeClass("list-view-toggle");
            setShowEditTaskModal(false);
            setCommonModalData();
            setSelectedTaskId(0);
            setTask_type('')
          }}
          pinHandler={() => {
          }}
          data={commonModalData}
          docType={task_type}
          setSelectedId={setSelectedTaskId}
          selectedTaskId={selectedTaskId}
          handleParentComponentAction={(type, taskData) => {
            if (task_type == 'task') {
              if (type == 'attachmentCountUpdate') {
                return;
              } else if (type == 'handleSelectedTask') {
                handleSelectedTask(taskData.task_id, taskData.flag)
                return;
              } else if (type == 'convertedTaskAction') {
                handleSelectedTask(taskData.convertTaskId, taskData.isShow, taskData.isRequestTask, taskData.task_id)
                return;
              }
            } else if (task_type == 'request-task') {
              if (type == 'convertedTaskAction') {
                handleSelectedTask(taskData.convertTaskId, taskData.isShow, taskData.isRequestTask, taskData.task_id)
                return;
              }
            }
          }}
          isCreateBenchmark={isBenchmark}
          isEdit={benchmarkDetail && Object.keys(benchmarkDetail).length ? true : false}
        /> : <></>
      }
      {
        showVirtualSpace ? (
          <CreateVirtualSpace
            projectId={projectId}
            show={showVirtualSpace}
            handleClose={() => {
              setShowVirtualSpace(false)
            }}
          />
        ) : (
          <></>
        )
      }
      {
        showScheduleModal ? (
          <SelectProviderCategory
            show={showScheduleModal}
            handleClose={() => {
              handleSchedules();
            }}
            handleShow={() => {
              handleSchedules();
            }}
            categoryListing={categoryListing}
            projectId={project_id}
            isCreateBenchmark={false}
          />
        ) : (
          ""
        )
      }
      {
        showBenchmarkModal ? (
          <SelectProviderCategory
            show={showBenchmarkModal}
            handleClose={() => {
              handleBenchmark();
            }}
            handleShow={() => {
              handleBenchmark();
            }}
            categoryListing={categoryListing}
            projectId={project_id}
            isCreateBenchmark={true}
          />
        ) : (
          ""
        )
      }
      {
        NewScheduleFooter ? (
            <NewSchedule
              show={NewScheduleFooter}
              handleClose={(id) => {
                setNewScheduleFooter(false);
                dispatch(updateTypeOfSpaceList(floorDetails.type_of_space.map((x) => x.id), false));
                if(id)
                {
                  dispatch(getBenchmarkWeekSchedule(id));
                  dispatch(getBenchmarkDetail(id, client_id));
                  if(location.pathname == "/schedules")
                  {
                    if(isBenchmark)
                    {
                      setTask_type('benchmark')
                    }
                    else {
                      setTask_type('schedule')
                    }
                    setCommonModalData(benchmarkDetail);
                    setSelectedTaskId(id)
                    setShowEditTaskModal(true);
                  }
                  else {
                    navigate("/schedules", {state : {
                      openDetailModal: true
                    }});
                  }
                }
                else {
                  dispatch(createScheduleDetails({}));
                  dispatch(getBenchmarkWeekSchedule(0, "all"));
                }
              }}
              isCreateBenchmark={isBenchmark}
            />
        ) : (
          <></>
        )
      }
      {
        showTaskModal ? (
          <CreateTaskOffcanvas
            show={showTaskModal}
            handleClose={(taskId) => {
              setShowTaskModal(false);
              if (taskId) {
                dispatch(
                  getSelectedTask(
                    localStorage.getItem("selectedOffice"),
                    taskId
                  )
                );
                setShowEditTaskModal(true)
              }
            }}
          />
        ) : (
          <></>
        )
      }
      {
        showLeadModal ?
          <AddLeadModal
            show={showLeadModal}
            handleClose={() => {
              setShowLeadModal(false);
            }}
          />
          :
          <></>
      }
      {
        showToolsModal ?
          <AddNewDocument
            show={showToolsModal}
            handleClose={() => {
              setShowToolsModal(false);
            }}
          />
          :
          <></>
      }
    </ScheduleProvider >
    </>
  );
};
export default Footer;
