import React, { useEffect } from "react";
import NoToDoTask from "../../assets/icons/placeholder/NoToDoTask";
import NoInprogressTask from "../../assets/icons/placeholder/NoInprogressTask";
import NoDoneTask from "../../assets/icons/placeholder/NoDoneTask";
import EstimateplaceholderIcon from "../../assets/icons/placeholder/EstimateplaceholderIcon";
import SubscriptionplaceholderIcon from "../../assets/icons/placeholder/SubscriptionplaceholderIcon";
import InvoicesplaceholderIcon from "../../assets/icons/placeholder/InvoicesplaceholderIcon";
import CreditNoteplaceholderIcon from "../../assets/icons/placeholder/CreditNoteplaceholderIcon";
import OfficeNotFound from "../../assets/icons/placeholder/OfficeNotFound";
import NoOffice from "../../assets/icons/placeholder/NoOffice";
import NoSearch from "../../assets/icons/placeholder/NoSearch";
import NoComments from "../../assets/icons/placeholder/NoComments";
import NoImages from "../../assets/icons/placeholder/NoImages";
import NoChecklist from "../../assets/icons/placeholder/NoChecklist";
import { _l } from "../../hooks/utilities";
import NoBenchmark from "../../assets/icons/placeholder/NoBenchmark";
import NoChatAvilabl from "../../assets/icons/placeholder/NoChatAvilabl";
import NoUserFound from "../../assets/icons/placeholder/NoUserFound";
import ManageCompanies from "../../assets/icons/placeholder/ManageCompanies";
import ManageRoles from "../../assets/icons/placeholder/ManageRoles";
import ManageTeams from "../../assets/icons/placeholder/ManageTeams";
import ManageUse from "../../assets/icons/placeholder/ManageUse";
import NoLink from "../../assets/icons/placeholder/NoLink";
import NoProviderDetail from "../../assets/icons/placeholder/NoProviderDetail";
import NoUser from "../../assets/icons/placeholder/NoUser";
import AppointmentplaceholderIcon from "../../assets/icons/placeholder/AppointmentplaceholderIcon";
import CallsplaceholderIcon from "../../assets/icons/placeholder/CallsplaceholderIcon";
import EmailplaceholderIcon from "../../assets/icons/placeholder/EmailplaceholderIcon";
import NoNotes from "../../assets/icons/placeholder/NoNotes";
import NoViewsTrackedplaceholderIcon from "../../assets/icons/placeholder/NoViewsTrackedplaceholderIcon";
import NoTimeslots from "../../assets/icons/placeholder/NoTimeslots";
import NoSpace from "../../assets/icons/placeholder/NoSpace";
import NoPresentSubscription from "../../assets/icons/placeholder/NoPresentSubscription";
import NoPastSubscription from "../../assets/icons/placeholder/NoPastSubscription";
import NoFutureSubscription from "../../assets/icons/placeholder/NoFutureSubscription";
import NoSummary from "../../assets/icons/placeholder/NoSummary";
import NoActivityLog from "../../assets/icons/placeholder/NoActivityLog";
import WaitingForProposalplaceholderIcon from "../../assets/icons/placeholder/WaitingForProposalplaceholderIcon";
const CommanPlaceholder = ({
mainSpacing="",
placeholderTextClass="",
mainWidth="",
altImgText="",
imgWidth="",
border="",
isAbsolute=false,
placeholderText="",
imgType="",
placeholderBigText="",
onlyText=false,
isImageOpacity=""
}) => {
  if(isImageOpacity == ""){
    isImageOpacity = "opacity-25"
  }else{
    isImageOpacity = isImageOpacity
  }

  useEffect(() => {
    
    if (localStorage.getItem("currentLeftPanelTab") == "my-staff") {
      let el = document.getElementById("office-list");
      if (el) {
        el.classList.add("mtminus3")
      }
    }
  }, [])
  
  return (
    <>
      <div className={`comman-placeholder-wrapper ${ border ? "border-0" : ""}  ${ isAbsolute ? "position-absolute top-50 start-50 translate-middle" : ""} ${mainSpacing !="" ? mainSpacing :"pt-4"} ${mainWidth !="" ? mainWidth :"w-100"}`}>
        {
          onlyText ? (
            <div className="d-flex flex-column align-items-center m-auto">
            <span className="color-white-80 title-fonts">
                {placeholderText !="" ? _l(placeholderText) : _l("l_no_data")}
              </span>
              </div>
          ) : (
          <div className="d-flex flex-column align-items-center m-auto">
            <div
              alt={`${altImgText !="" ? altImgText : _l("l_no_data")}`}
              className={`${imgWidth !="" ? imgWidth : "big-image"}`}
            >
            {
              imgType == "request" || imgType=="todo-task" ? (
                <NoToDoTask className="opacity-25" />
              ) : imgType == "inprogress-task" ? (
                <NoInprogressTask className="opacity-25" />
              ) : imgType == "NoDoneTask-task" ? (
                <NoDoneTask className="opacity-25" />
              ) : imgType == "no-documents" ? (
                <EstimateplaceholderIcon className="opacity-25" />
              ) : imgType == "no-subscriptions" ? (
                <SubscriptionplaceholderIcon className="opacity-25" />
              ) : imgType == "no-invoices" ? (
                <InvoicesplaceholderIcon className="opacity-25" />
              ) : imgType == "no-credit-notes" ? (
                <CreditNoteplaceholderIcon className="opacity-25 big-image" />
              ) : imgType == "no-found-office" ? (
                <OfficeNotFound className="opacity-25" />
              ) : imgType == "no-office" ? (
                <NoOffice className="opacity-25" />
              ) : imgType == "no-comment" ? (
                <NoComments className="opacity-25" />
              ) : imgType == "no-images" ? (
                <NoImages className="opacity-25" />
              ) : imgType == "no-checklist" ? (
                <NoChecklist className="opacity-25" />
              ) : imgType == "no-benchmark" ? (
                <NoBenchmark className="opacity-25" />
              ) : imgType == "no-chat" ? (
                <NoChatAvilabl className="opacity-25" />
              ) : imgType == "no-userfound" ? (
                <NoUserFound className="opacity-25" />
              ) : imgType == "manage-companies" ? (
                <ManageCompanies className="opacity-25" />
              ) : imgType == "manage-roles" ? (
                <ManageRoles className="opacity-25" />
              ) : imgType == "manage-teams" ? (
                <ManageTeams className="opacity-25" />
              ) : imgType == "manage-use" ? (
                <ManageUse className="opacity-25" />
              ) : imgType == "no-link" ? (
                <NoLink className="opacity-25" />
              ) : imgType == "no-provider" ? (
                <NoProviderDetail className="opacity-25" />
              ) : imgType == "no-user" ? (
                <NoUser className="opacity-25" />
              ) : imgType == "appointme" ? (
                <AppointmentplaceholderIcon className="opacity-25 big-image" />
              ) : imgType == "calls" ? (
                <CallsplaceholderIcon className="opacity-25 big-image" />
              ) : imgType == "email" ? (
                <EmailplaceholderIcon className="opacity-25 big-image" />
              ) : imgType == "no-notes" ? (
                <NoNotes className="opacity-25" />
              ) : imgType == "no-tracked" ? (
                <NoViewsTrackedplaceholderIcon className="opacity-25 big-image" />
              ) : imgType == "no-timeslots" ? (
                <NoTimeslots className="opacity-25" />
              ) : imgType == "no-space" ? (
                <NoSpace className="opacity-25" />
              ) : imgType == "no-present-subscription" ? (
                <NoPresentSubscription className="opacity-25" />
              ) : imgType == "no-past-subscription" ? (
                <NoPastSubscription className="opacity-25" />
              ) : imgType == "no-future-subscription" ? (
                <NoFutureSubscription className="opacity-25" />
              ) : imgType == "no-summary" ? (
                <NoSummary className="opacity-25" />
              ) : imgType == "no-active-log" ? (
                <NoActivityLog className="opacity-25" />
              ) : imgType == "waiting-for-proposal" ? (
                <WaitingForProposalplaceholderIcon className="opacity-25" />
              ) :
               (<NoSearch className="opacity-25" />)
            }
            </div>
            {placeholderBigText ? (
              <div className="c-font f-20 fw-semibold pt-2 title-fonts">
              {placeholderBigText !="" ? _l(placeholderBigText) : _l("l_no_data")}
              </div>
              ) : (<></>)}
            <span className={`color-white-80 title-fonts ${placeholderTextClass !="" ? placeholderTextClass :""} `}>
              {placeholderText !="" ? _l(placeholderText) == "No google drive" ?  _l("l_no_documents") :_l(placeholderText) : _l("l_no_data")}
            </span>
          </div>
          )
        }
        </div>
    </>
  );
};
export default CommanPlaceholder;