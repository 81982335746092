import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import ProgressBar from 'react-bootstrap/ProgressBar';
import TaskTrackingListing from "../Offcanvas/TaskTrackingListing";
import ImageViewer from "./ImageViewer";
import CustomerServices from "../../services/customer-services";
import moment from "moment";
import { _l, getPreviewFromURL, showError, showSuccess } from "../../hooks/utilities";
import { mapStyle } from "../../assets/map/mapStyle";
import { GoogleMap, Marker } from "@react-google-maps/api";
import ActionDropdown from "../Dropdowns/ActionDropdown";
import { useSelector } from "react-redux";
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer';
import leaseServices from "../../services/lease-services";
import CheckCircleIcon from "../../assets/icons/CheckCircleIcon";
import CaretRightIcon from "../../assets/icons/CaretRightIcon";
import CaretLeftIcon from "../../assets/icons/CaretLeftIcon";
import FunnelIcon from "../../assets/icons/FunnelIcon";
import MapTrifoldIcon from "../../assets/icons/MapTrifoldIcon";

const ImageDetailOffcanvas = ({data, handleAction, selectedAttachment, setSelectedAttachment, CloseCommentModal ,isChat,docType,
  documents = [],
  getLeaseDocumentDetails = () => {},
}) => {

  const {leaseDocAction, leaseDocType, leaseDocuments } = useSelector((state) => state.customer);


  const [checklistItems, setChecklistItems] = useState([]);
  const [showPrevBtn, setShowPrevBtn] = useState(false);
  const [showNextBtn, setShowNextBtn] = useState(false);
  const [attachIndex, setAttachIndex] = useState(0);
  const [attachNext, setAttachNext] = useState(5);
  const [attachPrivous, setAttachPrivous] = useState(-1);
  const [allAttachments, setAllAttachments] = useState([]);
  const [selectedLatLang, setSelectedLatLang] = useState({});
  const [taskdate, setTaskdate] = useState("")
  const [fileContent1, setFileContent1] = useState("");
  const [fileContent2, setFileContent2] = useState("");
  const [isEditOrLog, setIsEditOrLog] = useState("edit");
  const [editFileContent, setEditFileContent] = useState("");
  const [fileContentTemp, setFileContentTemp] = useState("");
  const [fileLogs, setFileLogs] = useState([]);


  useEffect(() => {
    CustomerServices.getCheckList(data.taskId, data.projectId, false, true).then((res) => {
      if (res.status) {
        setChecklistItems(res.data);
      }
    })
    setAllAttachments(data.allAttachments);
  }, []);
  const containerStyle = {
    width: "100%",
    height: "100%",
  };
useEffect(() => {
if (data) {
  setSelectedLatLang({
    lat: +data.attachmentDetails.latitude ,
    lng: +data.attachmentDetails.longitude ,
  });
}
if (data && data.taskDetails ) { 
  let dateNew = data.taskDetails.startdate != null
  ? data.taskDetails.startdate
  : data.taskDetails.duedate && data.taskDetails.duedate != null
  ? data.taskDetails.duedate
  : data.taskDetails.dateadded && data.taskDetails.dateadded != null
  ? data.taskDetails.dateadded 
  :''
  setTaskdate(dateNew)
}
if (data && data.taskDetails.checklistItems) {
  setChecklistItems(data.taskDetails.checklistItems)
  
}
},[data])
  useEffect(() => {
    if(allAttachments && allAttachments.length > 0){
      var selectedImageIndex = allAttachments.findIndex(item => item.id == selectedAttachment.id);
      if(selectedImageIndex > -1){
        setAttachIndex(selectedImageIndex);
        setShowPrevBtn(allAttachments.length != 1 && selectedImageIndex > 0 ? true : false);
        setShowNextBtn(allAttachments.length != 1 && selectedImageIndex < allAttachments.length - 1 ? true : false);
      }else{
        setShowPrevBtn(false);
        setShowNextBtn(false);
      }
    }
  }, [selectedAttachment, allAttachments]);

  const changeAttach = (direction) => {
    if(direction == "prev"){
      setSelectedAttachment(allAttachments[attachIndex - 1]);
    }else{
      setSelectedAttachment(allAttachments[attachIndex + 1]);
    }
  }

  const newStyles = {
    variables: {
      light: {
        codeFoldGutterBackground: "#6F767E",
        codeFoldBackground: "#E2E4E5"
      }
    },
  };

  const getFileType = (url) => {
    if (url) {      
      const fileExtension = url.split(".").pop().toLowerCase();
      if (fileExtension === "pdf") {
        return "pdf";
      } else if (["jpg", "jpeg", "png"].includes(fileExtension)) {
        return "image";
      } else if (["txt"].includes(fileExtension)) {
        return "text";
      } else if (["doc","docx"].includes(fileExtension)) {
        return "doc";
      } else {
        return "other";
      }
    }
  };

  const fileType = getFileType(selectedAttachment.image_url);

  const handleSaveFile = async () =>{
    let attachmentURL = selectedAttachment.image_url
    let docID = leaseDocuments.find(item => item.image_url == attachmentURL);

    if ((docID && docID.id) || selectedAttachment.previous_document_id > 0) {
      const file = new Blob([editFileContent], { type: 'text/plain' }, {name : "asdasasd.txt"});
      let response = await leaseServices.editLeaseDocuments(data.projectId, selectedAttachment.lease_id,selectedAttachment.previous_document_id > 0 ? selectedAttachment.previous_document_id : docID.id ,file)
      if (response && response.status) {
        showSuccess("l_document_update")
        getLeaseDocumentDetails({project_id :data.projectId });
      } else {
        showError("l_something_went_wrong")
        
      }
    }
  }
  const handleCancleEdit = () => {
    setEditFileContent(fileContentTemp);
  }

  const fetchtxtFileData =(newFileUrl , oldFileurl) =>{
    fetch(newFileUrl)
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.text();
    })
    .then((text) => setFileContent1(text))
    .catch((error) =>
      console.error("Error fetching the text file:", error)
    );

fetch(oldFileurl)
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.text();
    })
    .then((text) => setFileContent2(text))
    .catch((error) =>
      console.error("Error fetching the text file:", error)
    );
  }

  const handleChangeLog = (docDetails, index) => {
    let tempDoc = leaseDocuments && leaseDocuments.length > 0 && leaseDocuments.filter((doc)=> doc.id == selectedAttachment.previous_document_id)
    if (tempDoc && tempDoc.length > 0) {
      let subDoc = tempDoc[0].sub_document
      subDoc = subDoc.slice().reverse()
      if (subDoc) {
        if (index == (subDoc.length - 1)) {
          let tempDoc = leaseDocuments && leaseDocuments.length > 0 &&  leaseDocuments.filter((doc)=> doc.id == selectedAttachment.previous_document_id)
          let url1 = tempDoc && tempDoc.length > 0 ? tempDoc[0].image_url : "" ;
          let url2 = subDoc[index].image_url
          fetchtxtFileData(url1, url2);
        } else{
          let url1 = subDoc[index + 1].image_url
          let url2 = subDoc[index].image_url

          fetchtxtFileData(url1, url2);
        }
        
      }
    }

  }


  useEffect(() => {
    if (leaseDocuments && leaseDocuments.length > 0) {
      let crrDoc = leaseDocuments.filter((doc)=> doc.id  == selectedAttachment.previous_document_id)
      if (crrDoc.length > 0) {
        setFileLogs(crrDoc[0].sub_document)
      }
    }
  }, [leaseDocuments])
  

  useEffect(() => {

    let tempDoc = leaseDocuments && leaseDocuments.length > 0 && leaseDocuments.filter((doc)=> doc.id == selectedAttachment.previous_document_id);
    let newFileUrl = selectedAttachment.image_url;
    let oldFileurl = tempDoc && tempDoc.length > 0 ? tempDoc[0].image_url : "";
    fetchtxtFileData(newFileUrl, oldFileurl);
  }, [selectedAttachment])

  useEffect(() => {
    if (selectedAttachment.image_url && fileType == "text") {
      fetch(selectedAttachment.image_url)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.text();
        })
        .then((text) => {
          setEditFileContent(text);
          setFileContentTemp(text);

        }
        )
        .catch((error) =>
          console.error("Error fetching the text file:", error)
        );
    }else{
      setEditFileContent("")
    }
    setFileLogs(selectedAttachment.sub_document)
  }, [selectedAttachment]); 

  

  useEffect(() => {
    if (leaseDocAction == "filelog" ) {
      handleChangeLog({},0);
      setIsEditOrLog(0);
    }  
  }, [])
  
  
  return (
    <>
    <div className="form-wrapper-main h-100 d-flex p-2">
            <div className="left-content-part w-300 p-0 h-100 comman-content-scroll-wrapper bg-transparent">
             {leaseDocAction == "filelog"  || leaseDocAction == "edit" ? 
             <>
              <div className="border radius_5 overflow-hiiden-auto flex-grow-1 d-flex flex-column">
              <div className="bg-base-header-color p-10-15 position-sticky top-0 z-2 border-bottom">Version history</div>

             {leaseDocAction == "edit" 
             ?

             <a href="#/" className={`bg-white-03 m-2 p-10-15 radius_3 ${isEditOrLog == "edit" ? "active_border" : ""}`}
              onClick={()=>{
                setIsEditOrLog("edit")
              }}>
                <div className="d-flex justify-content-between">
                  <div className="d-flex flex-column gap-2">
                    <div className="fw-semibold c-font f-12">Edit mode</div>
                    <div className="d-flex flex-column">
                      <div className="d-flex align-items-center gap-1">
                        <span className="c-dots c-7 in-progress-white rounded-circle"></span>
                        <span className="c-font f-10 fst-italic">test user 1</span>
                      </div>
                    </div>
                    
                  </div>
                  
                  <div></div>
                </div>
              </a> : <></>}
              {fileLogs && fileLogs.length > 0 ? 
              fileLogs.slice().reverse().map((log, index)=> {
                return(<>
                 <a href="#/" className={`bg-white-03 m-2 p-10-15 radius_3 ${isEditOrLog == index ? "active_border" : ""}`}
                 key={index}
              onClick={()=>{
                handleChangeLog(log , index);
                setIsEditOrLog(index)
              }}>
                <div className="d-flex justify-content-between">
                  <div className="d-flex flex-column gap-2">
                    <div className="fw-semibold c-font f-12">{moment(log.dateadded).format("YYYY-MM-DD HH:mm")}</div>
                    <div className="d-flex flex-column">
                     {index == 0 && <div className="c-font f-10">Current version</div>}
                      <div className="d-flex align-items-center gap-1">
                        <span className="c-dots c-7 in-progress-white rounded-circle"></span>
                        <span className="c-font f-10 fst-italic">test user 1</span>
                      </div>
                    </div>
                    
                  </div>
                  
                  <div></div>
                </div>
              </a>
                </>)
              }) 
              : <></>}
             </div>

             </> 
             : <>
             <div className="border radius_5 overflow-hiiden-web flex-grow-1 d-flex flex-column">
                <div className="px-3 py-2 bg-white-03">
                  <div className="d-flex">
                    <div className="h25w25 h-auto"></div>
                    <div className="color-white-60 c-font f-12 ps-2 w100minus40">
                      Task #{data.taskDetails.id}
                    </div>
                    <div className="dropstart ms-2 d-flex">
                      <a href="#/" className={`comman_action_icon mt-auto`}
                      type="button"
                      id="commentOptionDropdown"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                       
                       
                       
                       
                      >
                        <div className="d-flex align-items-center ">
                          <div className="action_icon h25w25 on-hover-active-toggle-img with_bg">
                            <FunnelIcon
                              className="HW14"
                            />
                          </div>
                        </div>
                      </a>
                      <ActionDropdown
                        actions={[
                          {
                            actionName: _l("l_all"),
                            actionIcon: "SelectionAll",
                            actionHandler: () => {}
                          },
                          {
                            actionName: _l("l_floor_filter"),
                            actionIcon: "StepIcon",
                            actionHandler: () => {}
                          }
                        ]}
                      />
                    </div>
                  </div>
                  <div className="title-font c-font f-14 text-truncate d-none">{data.taskDetails.name}</div>
                  <div className="d-flex">
                    <div className="c-list-icon">
                      <div className="h25w25 comman-round-box with-bg d-flex rounded-circle bg-white-05 bg-style-cover" style={{
                        backgroundImage: `url('${data && data.attachmentDetails && data.attachmentDetails.creator_image_url ?
                        data.attachmentDetails.creator_image_url : "https://myrpp.myr.ai/orb/assets/images/default-image-placeholder.svg"
                        }')` }}>

                      </div>
                    </div>
                    <div className="ps-2 w100minus40 title-fonts">
                      <div className="c-font f-14 text-truncate pt-1" 
                         
                           >{data.taskDetails.name ? data.taskDetails.name : "" }</div>
                      <div className="c-font f-12 color-white-80">{moment(taskdate).format("DD/MM/YYYY hh:mm a")}</div>
                      <div className="progress-slider flex-grow-1">
                        <div className="d-flex align-items-center">
                          <ProgressBar variant="success" now={data.taskDetails.checklist_progress> -1 ?
                            data.taskDetails.checklist_progress : 0} />
                            <span className="title-fonts ps-2">{data.taskDetails.checklist_progress > -1 ?
                              data.taskDetails.checklist_progress : 0}%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="p-2 flex-grow-1 d-flex flex-column overflow-auto">
                <div className="overflow-auto h-100">
                  <TaskTrackingListing 
                    checklistItems={checklistItems} 
                    selectedAttachment={selectedAttachment} 
                    setSelectedAttachment={setSelectedAttachment}
                  />
                  </div>
                  <a href="#/" className="btn btn-secondary w-100 mt-3">
                    <CheckCircleIcon className={`HW18 color-${data.taskDetails.status == 5 ? "green" : data.taskDetails.status == 4 ?  "blue" :  "white" }`} /> <span className={`color-${data.taskDetails.status == 5 ? "green" : data.taskDetails.status == 4 ?  "blue" :  "white" }`}>The task is {data.taskDetails.status == 5 ? _l('l_done') : data.taskDetails.status == 4 ?  _l('l_inprogress') :  _l('l_todo') }.</span>
                    </a>
                </div>
              </div>
             </>}
              {
                data.taskDetails.rel_type == "equipment" ?
                  <div className="c-font f-14 pt-1  border radius_5 mt-2 p-1 d-flex">
                    <div className="w-50">
                    <span className="ps-2">{_l("l_surface")}</span>
                    <span className="ps-2">{data.taskDetails.surface ? data.taskDetails.surface : ""}</span>
                    </div>
                    <div className="w-50">
                    <span className="ps-2">{_l("l_duration")}</span>
                    <span className="ps-2">{data.taskDetails.duration ? data.taskDetails.duration : ""}</span>
                    </div>
                  </div>
                  :
                  <></>
              }
              {
                leaseDocAction == "filelog" || leaseDocAction == "edit" ? 
                <>
                </> 
                : 
                <div className="border radius_5 mt-2">
                <div className="comman-list with-after-40 d-flex">
                  <div className="d-flex w-100">
                {
                  attachPrivous > -1 ?
                    <a href="#/" className="ms-1 bg-silder-btn h18w18 with_overlay d-flex  rounded-circle position-absolute top-50 start-0 translate-middle-y z-index-2"
                      onClick={() => {
                        setAttachNext(attachNext - 1)
                        setAttachPrivous(attachPrivous - 1)
                      }}
                    >
                      <CaretLeftIcon className="HW14 " />
                    </a>
                    : <></>
                }
                {
                  allAttachments.length > attachNext ?
                    <a href="#/" className="me-1 bg-silder-btn h18w18 with_overlay d-flex  rounded-circle position-absolute top-50 end-0 translate-middle-y z-index-2"
                      onClick={() => {
                        setAttachNext(attachNext + 1)
                        setAttachPrivous(attachPrivous + 1)
                      }}
                    >
                      <CaretRightIcon className="HW14 " />
                    </a>
                    : <></>
                }
                    <div className="d-flex m-auto ps-2 upload-image-preview">
                  {
                    allAttachments && allAttachments.length > 0 && allAttachments.filter((att ,key) => key < attachNext && key > attachPrivous).map((attachment) => {
                      const isPdf = attachment.file_type === "pdf";
                      let prev = {}
                      prev.url = getPreviewFromURL(attachment.image_url);
                      return <a href="#/" className={`comman-image-box h40w40 radius_3 upload-image me-2 ${attachment.id == selectedAttachment.id ? "active" : ""}`}  onClick={() => setSelectedAttachment(attachment)}>
                        {isPdf ? (
                          <div className="comman-image-box-bg h-100 w-100 radius_3 pdf-icon">
                            <div 
                              className="comman-image-box-bg bg-style-cover h-100 w-100 radius_3 d-flex" 
                            >
                              <prev.url size={40} weight="light" className="c-icons HW30"/>
                            </div>
                          </div>
                        ) : (
                          <div 
                            className="comman-image-box-bg bg-style-cover h-100 w-100 radius_3" 
                            style={{ backgroundImage: `url(${attachment.image_url})` }}
                          ></div>
                        )}
                      </a>
                    })
                  }
                    </div>
                  </div>
                </div>
                <div className={`comman-list with-after-40 d-none ${data.taskDetails.creator_name && data.taskDetails.creator_name != "" ? "" :"d-none"}`}>
                  <div className="d-flex">
                    <div className="c-list-icon">
                      <div className="h25w25 comman-round-box with-bg d-flex rounded-circle bg-white-05 bg-style-cover" style={{ backgroundImage: `url('${data.taskDetails.creator_profile_image ? data.taskDetails.creator_profile_image : ""}')` }}>

                      </div>
                    </div>
                    <div className="ps-2 w100minus40 title-fonts">
                      <span className="c-font f-14 text-truncate pt-1 pe-3">{data.taskDetails.creator_name ? data.taskDetails.creator_name : ""}</span>
                    </div>
                  </div>
                </div>
                <div className="comman-list with-after-40 pb-1 map-box-parent">
                <div className=" map-box radius_5" style={{height : "120px"}}>
                  {selectedLatLang.lat === 0 && selectedLatLang.lng === 0 ?
                    <>
                      <div className="align-items-center d-flex flex-column h-100 justify-content-center">
                        <MapTrifoldIcon className="HW60 comman-icons m-0 opacity-75" />
                        <div className="color-white-70">No map</div>
                      </div>
                    </>
                    :
                <GoogleMap
                      googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_SECRET}
                      options={{
                        zoom :"10",
                        zoomControl: true,
                        streetViewControl: false,
                        scrollwheel: false,
                        styles: mapStyle,
                      }}
                      mapContainerStyle={containerStyle}
                      center={selectedLatLang}
                      zoom={8}
                    >
                      <Marker position={selectedLatLang} />
                    </GoogleMap>
                  }
                    </div>
                </div>
              </div>}
            </div>
            <div className="right-content-part width-calc-300 ms-2 overflow-auto">
              {leaseDocType === "text" ? 
              leaseDocAction == "edit" && isEditOrLog == "edit" ? <>
              <div className="h-100 position-relative">
              <textarea
               type="text"
                value={editFileContent}
                className="f-black h-100"
                onChange={(e)=>{
                  setEditFileContent(e.target.value)
                }}
              />
                  <div className="d-flex align-items-center gap10 position-absolute docframebtn">
                <Button variant="danger" className="btn-sm" onClick={()=>{
                  handleCancleEdit()
                }}>Cancle</Button>
                  <Button variant="primary" className="btn-sm btn" onClick={()=>{
                    handleSaveFile()
                  }}>Save</Button>
                </div>
              </div>
              </>:
              <>
              <ReactDiffViewer
              oldValue={fileContent1}
              newValue={fileContent2}
              splitView={true}
              compareMethod={DiffMethod.WORDS}
              styles={newStyles}
              leftTitle="old File"
              rightTitle="New File"
            />
              </>
              : <>
              <ImageViewer 
                attachmentURL={selectedAttachment.image_url} 
                attachmentDate={selectedAttachment.dateadded} 
                changeAttach={changeAttach} 
                showPrevBtn={showPrevBtn} 
                showNextBtn={showNextBtn}
                handleAction={handleAction} 
                CloseCommentModal={CloseCommentModal}
                isChat={isChat}
                docType={docType}
              />
              </>}
            </div>
          </div>
    </>
  );
};

export default ImageDetailOffcanvas;
