import React from 'react'

const SubscriptionIcon = (props) => {
    const height = (props.height) ? props.height : "13";
	const width = (props.width) ? props.width : "16";
	const viewBox = (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW = (props.className) ? props.className : "";
    return (
        <>
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons ${CommanHW}`}>
    <g clip-path="url(#clip0_3933_141834)">
    <path d="M11.1044 18.9662H15.8279H18.19C18.7423 18.9662 19.19 18.5185 19.19 17.9662V14.9482V4.56641H1V17.6815C1 18.391 1.54783 18.9662 2.22347 18.9662H7.40524" stroke="currentColor" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M11 9.95833H16.5095M11 7.86168H16.5095M19.19 4.56649V2.7536C19.19 2.04401 18.6422 1.46875 17.9664 1.46875H2.2236C1.54784 1.46875 1 2.04401 1 2.7536V4.56649H19.19Z" stroke="currentColor" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M7.80859 15.5551H4.19145C3.80957 15.5551 3.5 15.2006 3.5 14.7632V8.56931C3.5 8.13191 3.80957 7.77734 4.19145 7.77734H7.80855C8.19043 7.77734 8.5 8.13191 8.5 8.56931V14.7632C8.50004 15.2006 8.19043 15.5551 7.80859 15.5551Z" stroke="currentColor" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M6.5 10.2011C6.5 10.2011 6.1459 9.83824 5.60008 10.0209C5.09891 10.1887 4.97441 10.9506 5.315 11.2474C5.51055 11.4179 5.8048 11.558 6.20141 11.7138C7.08898 12.0624 6.7609 13.4532 5.85801 13.4599M5.85801 13.4599C5.50566 13.4625 5.34094 13.4376 5.03145 13.2126M5.85801 13.4599V13.938M5.85801 9.5V9.97101" stroke="currentColor" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <rect x="11" y="12.2227" width="5.5" height="3.33333" rx="0.5" stroke="currentColor"/>
    </g>
    <defs>
    <clipPath id="clip0_3933_141834">
    <rect width="20" height="20" fill="white"/>
    </clipPath>
    </defs>
    </svg>
    
        </>
      )
}

export default SubscriptionIcon