import React from 'react'

const CreditNoteIcon = (props) => {
    const height = (props.height) ? props.height : "13";
	const width = (props.width) ? props.width : "16";
	const viewBox = (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW = (props.className) ? props.className : "";
  return (
    <>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons ${CommanHW}`}>
<g clip-path="url(#clip0_3936_141896)">
<path d="M1.75781 0.585995H13.5547C14.2018 0.585995 14.7265 1.11064 14.7265 1.75787V19.4141H2.92968V5.27348" stroke="currentColor" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M1.75781 0.585951C1.11062 0.585951 0.585938 1.1106 0.585938 1.75782V5.27344H2.92968V1.75782C2.92968 1.1106 2.40499 0.585951 1.75781 0.585951Z" stroke="currentColor" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9.8432 4.68751C9.64058 4.33724 9.26187 4.10158 8.82812 4.10158C8.1809 4.10158 7.65625 4.62622 7.65625 5.27345C7.65625 5.92067 8.1809 6.44532 8.82812 6.44532M8.82812 6.44532C8.47777 6.44532 9.24343 6.44532 8.82812 6.44532ZM8.82812 6.44532C9.47531 6.44532 9.99999 6.96997 9.99999 7.61719C9.99999 8.26442 9.47531 8.78906 8.82812 8.78906C8.39433 8.78906 8.01562 8.5534 7.81301 8.20313" stroke="currentColor" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M8.82812 9.96094V8.78907" stroke="currentColor" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M8.82812 4.10156V2.92969" stroke="currentColor" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M5.3125 12.3828H12.3437" stroke="currentColor" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M5.3125 14.7266H12.3437" stroke="currentColor" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M5.3125 17.0703H12.3437" stroke="currentColor" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M19.4141 2.92973V14.7266L18.2422 17.0703L17.0703 14.7266V2.92973H19.4141Z" stroke="currentColor" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M19.4141 13.5547H17.0703" stroke="currentColor" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_3936_141896">
<rect width="20" height="20" fill="white"/>
</clipPath>
</defs>
</svg>

    </>
  )
}

export default CreditNoteIcon