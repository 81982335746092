import moment from "moment";

interface customerSpaces {
  id: string;
  office_title: string;
  postal_address: string;
  project_id: string;
  status: string;
}

let favDashboard = "AichatView";
if (localStorage.getItem("fav_dashboard") == "calendar") {
  favDashboard = "calendarView"
} else if(localStorage.getItem("fav_dashboard") == "my_spaces"){
  favDashboard = "spaceView"
}else if (localStorage.getItem("fav_dashboard") == "kanban_view"){
  favDashboard = "kanbanView"
}else if(localStorage.getItem("fav_dashboard") == "my_tasks"){
  favDashboard = "liteVersionView"
}else if(localStorage.getItem("fav_dashboard") == "AichatView"){
  favDashboard = "AichatView"
}else if (localStorage.getItem("currentView")){ 
  favDashboard = ""
}else if(window.location.pathname == "/providerlist"){
  favDashboard = "listView";
}

const generateWeekArray = (startDate = moment()) => {
  const weekData = [];

  // Generate data for 7 days starting from the startDate
  for (let i = -3 ; i < 4; i++) {
    const date = moment(startDate).add(i, 'days').format('YYYY-MM-DD');
    weekData.push({
      date,
      todo_count: 0,
      inprogress_count: 0,
      completed_count: 0,
      total: 0,
      request_total: 0
    });
  }

  return weekData;
};

let calendarDatanew = generateWeekArray()

export class CustomerModel {
  spaces: customerSpaces[] = [];
  calendarData: any = calendarDatanew;
  weekCountData: any = calendarDatanew;
  taskList: any[] = [];
  UnreadCommentTaskList: any;
  topicData: any;
  selectedTask: any;
  selectedProject: number | string =
    localStorage.getItem("selectedOffice") || 0;
  defaultTopics: any[]=[];
  defaultTaskAssignees: any;
  clientTeamData: any;
  taskChecklist: any[] = [];
  taskFilters: any[] = [];
  taskCounts: any;
  taskSearchString: string = "";
  benchmarkDetail: any;
  benchmarkSchedule: any;
  taskStatusFilter: any[] = [];
  mentionList: any[] = [];
  activeTaskStatusFilters: any[] = [];
  contactPermissions: any[] = [];
  walletDetails: any[] = [];
  toastClose: any;
  isAppliedUnreadComment: any = false;
  taskUnreadCmtCntAll: number = 0;
  isPremiumEstimateAccess: string = "";
  isIBANModalOpen: string = "";
  createSchduleDetails: any;
  benchmarkList: any[] = [];
  filterBenchmarkCategory: number = 0;
  calendarsDefaultTopics: any[] = [];
  virtualSpaceList: any[] = [];
  hideLoader: boolean = false;
  space_work_score: any[] = [];
  space_logo_url:  string = "";
  image_logo_url:  string = "";
  user_placeholder:  string = "";
  userTeams: any[] = [];
  accessOfficeData: any[] = [];
  modelViewerPoint: boolean = false;
  commanoffcanvasIsOpen: boolean = false;
  calendarCanvasIsOpen: boolean = false;
  attachmentsForTask: any;
  themeColor: string = localStorage.getItem("selectedTheme") || "" ;
  globalSearchString: string = "";
  favDashboard : string = "";
  selectedUserForChat: any;
  isBackdrop: boolean = false;
  isAllChatUserlistShow: boolean = false;
  generalUnreadCount: number = 0;
  searchStringForAllChatUser: string = "";
  isDayGridMonth : boolean = false;
  isDayGridWeek  : boolean = false;
  isDayGridDay : boolean = true; 
  isTasklistView : boolean = false;
  isAiChat : boolean = false;
  createTaskReqInputIcons:boolean=false;
  daterangeDayDifference : number = 0;
  daterangeAppliedFilters : string = "today";
  globalView : any = favDashboard != "" ? favDashboard : localStorage.getItem("currentView");
  daterangeFilterApplied :boolean = false;
  calendarView : string = "this_week";
  benchmark_create : boolean = true;
  getSelectedFloorId : number = 0;
  pinDelayfilter : any[] = [];
  imageviewerisOpen : boolean = false;
  tasklistLoader : boolean = false;
  openChatAi : boolean = false;
  dateView : string = "dayView";
  lastChatSession : any[] = [];
  metricsDetails:object = {};
  filterfor3dPlan : any[] = [];
  scrollDate: any = new Date();
  dashBoradFilter : any[] = [];
  createTaskModalIsopen : boolean = false;
  dashBoradView : string = "spaceView";
  ChatAiDate : any = new Date();
  taskandMessages: any[] = [];
  lastTaskDate : any = new Date();
  chatcalendarData: any[] = [];
  displayThreeDays : boolean = true;
  weekCalNaviSide : string = "";
  calendarDateIndex : number = 0;
  chatTaskList : any[] = [];
  firstIdofMessage : any = "";
  preDataAvaliable : boolean = false;
  lastAppliedFilter : object = {};
  isCallFullscreen : boolean = false;
  callRecordingActive : boolean = false;
  callTask : object = {};
  callTaskDetails : any[] = [];
  callComments: any[] = [];
  callRecordingAction : string = "";
  displayMic : boolean = false;
  isEndCall : boolean = false;
  chatPlanTittle : object = {};
  selectedProposal : object = {};
  lastMessage: object = {};
  inviteeCompanyList: any[] = [];
  isJobProposalActive : boolean = false;
  isContactDirChat : boolean = false;
  IBANAccounts:any[] = [];
  editedScheduleData:any[] = [];
  showLoadingBox : boolean = false;
  selectedOperators : any[] = [];
  isMailReplay : boolean = false;
  providerList : any[] = [];
  riskList : any[] = [];
  providerListDataLoading : boolean = false;
  leftPanelTab : string = "homepage";
  agentList : any[] = [];
  clientList : any[] = [];
  filterProjectID : number = 0;
  providerWallet : number = localStorage.getItem("wallet_count") ? Number(localStorage.getItem("wallet_count")): 1 ;
  companyEmployeeData : any[] = [];
  tenantsRoleId : number = 1;
  tenantsEmployeeData : any[] = [];
  homepageTaskList : any[] = [];
  showExteenalFullscreen : boolean = false;
  loaderMessage : string = "";
  showCommentSckeleton : boolean = true;
  docBoolean: boolean = false;
  reFetchList: boolean = false;
  clientsTotalCounts : object = {};
}
